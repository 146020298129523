import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardSelector,
  fetchCurrentActivity,
  fetchDashboard,
  stopActivity,
} from "../../redux/features/dashboard/dashboardSlice";
import { AppDispatch } from "../../redux/store";
import { ActivityStop } from "./type";
import { OK } from "../../config/axois/apiStatusCode";
import { isEmpty } from "../../helper/helper";
import config from "../../config/config";
import { useLocation } from "react-router-dom";
import { fetchSummary } from "../../redux/features/summary/summarySlice";

const LiveTimeTracker = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const {
    currentActivities: { current_activity },
  } = useSelector(dashboardSelector);

  const [startTime] = useState(
    current_activity?.start_date_time
      ? moment(current_activity?.start_date_time)
      : moment()
  );
  const [timeDifference, setTimeDifference] = useState("00 : 00 : 00");

  useEffect(() => {
    const interval = setInterval(updateTimeDifference, 1000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(interval);
  }, []);

  const updateTimeDifference = () => {
    if (!isEmpty(current_activity?.start_date_time)) {
      const currentTime = moment();
      const duration = moment.duration(currentTime.diff(startTime));
      const hours = String(duration.hours()).padStart(2, "0");
      const minutes = String(duration.minutes()).padStart(2, "0");
      const seconds = String(duration.seconds()).padStart(2, "0");
      setTimeDifference(`${hours} : ${minutes} : ${seconds}`);
    }
  };

  const onStop = async () => {
    const body: ActivityStop = {
      latitude: "-",
      longitude: "-",
      end_date_time: moment().format(config.API_DATE_TIME_FORMATE),
      id: current_activity?.id ?? "",
    };

    const response = await dispatch(stopActivity(body));

    if ([200, 201].includes(response?.payload?.status)) {
      dispatch(fetchCurrentActivity());

      if (location.pathname.includes("/dashboard")) {
        dispatch(fetchDashboard());
      }

      if (location.pathname.includes("/summary")) {
        dispatch(fetchSummary({}));
      }
    }
  };

  return (
    <button
      className="btn btn-red d-inline-flex align-items-center me-20"
      type="button"
      onClick={() => {
        onStop();
      }}
    >
      <img
        src="../img/svg/icon-pause-filled-red.svg"
        alt="activity"
        title="activity"
        width={24}
        height={24}
        loading="eager"
        className="me-6"
      />
      <span>{timeDifference}</span>
    </button>
  );
};

export default LiveTimeTracker;
