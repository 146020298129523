import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showError } from "../../../components/alerts/alerts";
import { getRequest } from "../../../config/axois/apiRequest";
import { permission } from "../../../config/apiUrls/apiUrl";
import {
  getValueFromLocalStorage,
  isEmpty,
  setValueInLocalStorage,
} from "../../../helper/helper";
import key from "../../../config/key";

const parseValue = isEmpty(getValueFromLocalStorage(key.permission))
  ? ""
  : JSON.parse(getValueFromLocalStorage(key.permission) || "");

const initialState = {
  permissions: isEmpty(parseValue) ? [] : parseValue,
  isLoading: true,
};

export const fetchPermissions: any = createAsyncThunk(
  "permissions/fetchPermissions",
  async () => {
    try {
      const response = await getRequest(permission.permissionList);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPermissions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = action?.payload?.data?.data?.permissions ?? [];

        setValueInLocalStorage(
          key.permission,
          JSON.stringify(action?.payload?.data?.data?.permissions || "")
        );
      })
      .addCase(fetchPermissions.rejected, (state: any, action) => {
        state.isLoading = false;
        state.permission = [];
      });
  },
});

export const permissionSelector = (state: {
  permission: { permissions: string[]; isLoading: boolean };
}) => state.permission;

const permissionReducer = permissionSlice.reducer;

export default permissionReducer;
