import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../config/axois/apiRequest";
import { showError, showSuccess } from "../../../components/alerts/alerts";
import { activityApi } from "../../../config/apiUrls/apiUrl";
import { OK } from "../../../config/axois/apiStatusCode";
import { errorHandler } from "../../../helper/helper";

export interface Activity {
  id: string | number;
  name: string;
  is_location_required: boolean;
  is_active: boolean;
  deleted_at?: string | null;
}

interface ActivityState {
  activities: Activity[];
  details: Activity | null;
  loading: boolean;
  detailsLoading: boolean;
  error: string | null;
}

interface QueryParam {
  currentOffset: number;
  limit: number;
  is_active?: boolean;
  search?: string;
  ordering?: string;
  isDeleted?: boolean;
}

const initialState: ActivityState = {
  activities: [],
  details: null,
  loading: false,
  detailsLoading: false,
  error: null,
};

export const fetchActivities: any = createAsyncThunk(
  "activities/fetchActivities",
  async ({
    currentOffset,
    limit,
    is_active,
    search,
    ordering,
    isDeleted,
  }: QueryParam) => {
    try {
      let url = `${activityApi.getActivities}?limit=${limit}&offset=${currentOffset}`;

      if (is_active) {
        url += `&is_active=${is_active}`;
      }

      if (search) {
        url += `&search=${search}`;
      }

      if (ordering) {
        url += `&ordering=${ordering}`;
      }

      if (isDeleted) {
        url += `&with_trashed=true`;
      }

      const response = await getRequest(url, true);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const createActivity = createAsyncThunk(
  "activities/createActivity",
  async (newActivity: Omit<Activity, "id">) => {
    // Omitting "id" from newActivity
    try {
      const response = await postRequest(
        activityApi.createActivity,
        newActivity,
        true
      );

      return response;
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        errorHandler(error?.response?.data?.errors);
        return;
      } else {
        showError("Something Went Wrong");
      }

      return error;
    }
  }
);

export const updateActivity = createAsyncThunk(
  "activities/updateActivity",
  async (updatedActivity: Activity) => {
    try {
      const response = await patchRequest(
        activityApi.updateActivity + updatedActivity.id,
        updatedActivity,
        true
      );

      return response;
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        errorHandler(error?.response?.data?.errors);
        return;
      } else {
        showError("Something Went Wrong");
      }

      return error;
    }
  }
);

export const getActivityById = createAsyncThunk(
  "activities/getActivityById",
  async (clientId: any) => {
    try {
      const response = await getRequest(
        `${activityApi.getActivities}/${clientId}`,
        true
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

const activitySlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.activities = action?.payload?.data?.data ?? {};
      })
      .addCase(fetchActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch activities.";
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        if (action?.payload?.status === 201) {
          showSuccess("Activity Added Successfully");
        }
      })
      .addCase(updateActivity.fulfilled, (state, action) => {
        if ([200, 201].includes(action?.payload?.status)) {
          showSuccess("Activity Updated Successfully");
        }
      })
      .addCase(getActivityById.pending, (state) => {
        state.detailsLoading = true;
        state.error = null;
      })
      .addCase(getActivityById.fulfilled, (state, action) => {
        state.detailsLoading = false;
        state.details = action?.payload?.data?.data ?? null;
      })
      .addCase(getActivityById.rejected, (state, action) => {
        state.detailsLoading = false;
        state.error = action.error.message || "Failed to fetch activity";
      });
  },
});

// Selectors
export const activitiesSelectors = {
  activities: (state: RootState) => state.activities.activities,
  details: (state: RootState) => state.activities.details,
  loading: (state: RootState) => state.activities.loading,
  detailsLoading: (state: RootState) => state.activities.detailsLoading,
  error: (state: RootState) => state.activities.error,
};

const activityReducer = activitySlice.reducer;

export default activityReducer;
