import React from "react";

const DeleteText = () => {
  return (
    <>
      <span
        style={{
          color: "grey",
        }}
      >
        Removed
      </span>
    </>
  );
};

export default DeleteText;
