/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { isEmpty } from "../../helper/helper";

type Props = {
  className: string;
  color: string;
  title: string;
  description: string;
  titleDescription?: any;
  label?: string;
  labelWidth?: any;
};

const KukeStatisticsWidget: React.FC<Props> = ({
  className,
  color,
  title,
  description,
  titleDescription,
  label,
  labelWidth,
}) => {
  return (
    <div className={`card  ${className}`} style={{ backgroundColor: color }}>
      <div className="card-body">
        <a className={`card-title font-bold text-dark font-20 mb-0 d-block`}>
          {title}
        </a>
        {titleDescription}
        <div className="pt-1 mb-0">
          <span className="fw-500 text-dark font-14">{description}</span>
        </div>
      </div>
      {!isEmpty(label) && (
        <div
          className={`text-light ${
            labelWidth ? labelWidth : "w-60px"
          } position-absolute mt-3 ml-1 px-1 text-center fw-600 top-0 end-0`}
          style={{
            backgroundColor: "#6c757d",
            fontSize: "15px",
          }}
        >
          {label}
        </div>
      )}
    </div>
  );
};

export { KukeStatisticsWidget };
