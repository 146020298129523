import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  dashboardSelector,
  fetchCurrentActivity,
  onClockIn,
  onClockOut,
  stopActivity,
} from "../../redux/features/dashboard/dashboardSlice";
import { isEmpty } from "../../helper/helper";
import { Modal } from "react-bootstrap";
import { ActivityStop } from "./type";
import moment from "moment";
import config from "../../config/config";

const ClockInOut = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    currentActivities: { current_activity },
    isClockInLoading,
    isClockIn,
  } = useSelector(dashboardSelector);

  const [show, setShow] = useState(false);

  const hideModal = () => {
    setShow(false);
  };

  const showModal = () => {
    setShow(true);
  };

  const clockInOut = async () => {
    if (!isEmpty(isClockIn) && !isEmpty(current_activity?.id)) {
      showModal();
      return;
    }

    isEmpty(isClockIn)
      ? await dispatch(onClockIn())
      : await dispatch(onClockOut());
    dispatch(fetchCurrentActivity());
  };

  const clockOut = async () => {
    const body: ActivityStop = {
      latitude: "-",
      longitude: "-",
      end_date_time: moment().format(config.API_DATE_TIME_FORMATE),
      id: current_activity?.id ?? "",
    };

    await dispatch(stopActivity(body));
    await dispatch(onClockOut());
    dispatch(fetchCurrentActivity());
  };

  return (
    <>
      <button
        onClick={clockInOut}
        className="btn btn-sm d-inline-flex align-items-center me-lg-3 me-2"
        type="button"
        disabled={isClockInLoading}
      >
        <img
          src="../img/svg/icon-login-white.svg"
          alt="activity"
          title="activity"
          width={24}
          height={24}
          loading="eager"
          className="me-6"
        />
        <span className="d-lg-block d-none">
          {isClockInLoading
            ? "Please Wait"
            : isEmpty(isClockIn)
            ? "Clock-In"
            : "Clock-Out"}
        </span>
      </button>

      <Modal
        show={show}
        onHide={() => {
          hideModal();
        }}
        backdrop="static"
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attention</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            You have an ongoing activity session. If you clock out now, your
            current activity will be stopped. Are you sure you want to clock
            out?
          </p>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 d-flex">
            <button className="btn w-100 me-4" type="submit" onClick={clockOut}>
              Clock Out
            </button>

            <button
              className="btn w-100 cancel-button-background"
              type="submit"
              onClick={hideModal}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClockInOut;
