import * as yup from "yup";

const validationSchema = yup.object().shape({
  phone_no: yup
    .string()
    .trim()
    .required("Phone Number OR Email Is Required")
    .test(
      "is-phone-or-email",
      "Invalid Phone Number or Email",
      function (value) {
        if (!value) return false; // If no value, fail the validation
        // If first character is a number, validate as phone number, else validate as email
        return /^\d/.test(value)
          ? /^[0-9]{10}$/.test(value)
          : yup.string().email("Invalid Email").isValidSync(value);
      }
    ),
  password: yup.string().trim().required("Password Is Required"),
});

export { validationSchema };
