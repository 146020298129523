import React, { useEffect, useState } from "react";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import CustomLoader from "../../components/loader/loader";
import { isEmpty } from "../../helper/helper";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import SortingIndicator from "../../components/sorting/SortingIndicator";
import CustomPaginate from "../../components/pagination/CustomPaginate";
import config from "../../config/config";
import useDebounce from "../../components/hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import {
  adminDashboardSelector,
  fetchAllMaxMinEmployees,
} from "../../redux/features/adminDashboard/adminDashboardSlice";
import { AppDispatch } from "../../redux/store";
import { useLocation } from "react-router-dom";
import { heading } from "../../redux/features/header/headerSlice";

const MaxMinEmployees = () => {
  const [selected, setSelected] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [limit, setLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const searchDebounce = useDebounce(search, 500);

  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { allMaxMinHours, allMaxMinHoursLoading } = useSelector(
    adminDashboardSelector
  );

  const handleSearch = (e: React.SyntheticEvent) => {
    setCurrentOffset(0);
    setSelected(0);
    setSearch((e.target as HTMLInputElement).value);
  };

  const fetchEmployeeRecords = async () => {
    const response = await dispatch(
      fetchAllMaxMinEmployees({
        currentOffset,
        limit,
        search,
        ordering: location?.state?.adminDashboardOrdering
          ? location?.state?.adminDashboardOrdering
          : "",
      })
    );
    setCount(response?.payload?.data?.meta?.count ?? 0);
  };

  useEffect(() => {
    fetchEmployeeRecords();
  }, [currentOffset, selected, searchDebounce, location]);

  useEffect(() => {
    dispatch(heading("Employees Spent Hours"));
  }, []);

  return (
    <>
      <SideBarWrapper>
        <div className="card" style={{ marginBottom: "-40px" }}>
          <div className="card-header border-0 py-2 px-7 align-items-center">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div className="mb-res" style={{ marginTop: "15px" }}>
                <input
                  autoFocus
                  name="text"
                  type="text"
                  autoComplete="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  className="form-control form-search"
                  disabled={allMaxMinHoursLoading}
                />
              </div>
            </div>
          </div>
          {allMaxMinHoursLoading ? (
            <CustomLoader />
          ) : (
            <>
              {isEmpty(allMaxMinHours) ? (
                <NoRecordFound />
              ) : (
                <div className="card-body pt-0 pb-3">
                  <div className="table-responsive">
                    <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                      <thead>
                        <tr className="text-muted fw-500">
                          <SortingIndicator
                            field="Name"
                            onClick={() => {}}
                            column="full_name"
                            sortingColumn={""}
                            hideShorting={true}
                          />

                          <SortingIndicator
                            field="Duration"
                            onClick={() => {}}
                            column="Duration"
                            sortingColumn={""}
                            hideShorting={true}
                          />

                          <SortingIndicator
                            field="Total Cost"
                            onClick={() => {}}
                            column="total_amount"
                            sortingColumn={""}
                            hideShorting={true}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {allMaxMinHours &&
                          allMaxMinHours.map((item: any) => (
                            <tr key={item.id}>
                              <td>
                                {isEmpty(item.full_name) ? "-" : item.full_name}
                              </td>
                              <td>
                                {isEmpty(item.total_duration)
                                  ? "-"
                                  : item.total_duration}
                              </td>
                              <td>
                                {isEmpty(item.total_amount)
                                  ? "-"
                                  : item.total_amount}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {!isEmpty(allMaxMinHours) && !allMaxMinHoursLoading && (
          <CustomPaginate
            data={allMaxMinHours}
            selected={selected}
            setSelected={setSelected}
            setCurrentOffset={setCurrentOffset}
            totalRecords={count}
            limit={limit}
            setLimit={setLimit}
            isDisable={allMaxMinHoursLoading}
          />
        )}
      </SideBarWrapper>
    </>
  );
};

export default MaxMinEmployees;
