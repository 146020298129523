const ShowingCount = ({ data, selected, count, limit }: any) => {
  return (
    <>
      Showing {data.length === 0 ? 0 : selected * limit + 1} to{" "}
      {data.length === 0
        ? 0
        : data.length < limit
        ? count
        : (selected + 1) * limit}{" "}
      of {data.length === 0 ? 0 : count} entries
    </>
  );
};

export default ShowingCount;
