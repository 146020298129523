const dashboardApi = {
  clockIn: "employees/clock-in",
  clockOut: "employees/clock-out",
  fetchDashboard: "employees/dashboard",
  fetchCurrentActivity: "employees/current-activity",
  startActivity: "employees/activities/start",
  fetchSummary: "employees/summary",
  stopActivity: (id: string) => `employees/activities/${id}/complete`, // change this to :id
  updateActivity: (id: string) => `employees/activities/edit/${id}`,
  fetchSingleActivity: (id: string) => `employees/activities/${id}`,
};

const auth = {
  login: "users/login",
  getUser: "users",
  updateUsers: "users/update-profile",
};

const permission = {
  permissionList: "users/permissions",
};

const clientsApi = {
  getClients: "clients",
  createClient: "clients/create",
  updateClient: "clients/edit/",
};

const projectsApi = {
  getProjects: "projects",
  getProjectsDetails: "projects/detail",
  createProject: "projects/create",
  updateProject: "projects/edit/",
  updateMinutesOfMeetings: "/projects/:id/minutes-of-meeting",
};

const activityApi = {
  getActivities: "employees/activity-types",
  createActivity: "employees/activity-types/create",
  updateActivity: "employees/activity-types/edit/",
};

const vendorsApi = {
  getVendors: "vendors",
  createVendor: "vendors/create",
  updateVendor: "vendors/edit/",
};

const consultantApi = {
  getConsultants: "consultants",
  createConsultant: "consultants/create",
  updateConsultant: "consultants/edit/",
};

const contractorApi = {
  getContractors: "contractors",
  createContractor: "contractors/create",
  updateContractor: "contractors/edit/",
};

const employeesApi = {
  getEmployees: "employees",
  createEmployee: "employees/create",
  updateEmployee: "employees/edit/",
  deleteEmployee: "employees/activities/delete/:id",
};

const adminDashboardApi = {
  activeUsers: "users/admin-dashboard/yesterday-active-users",
  mostUsedActivity: "users/admin-dashboard/most-used-activity-types",
  projectSpentHours: "users/admin-dashboard/project-spent-hours",
  topEmpMaxHours: "users/admin-dashboard/top-employees-max-hours",
  topEmpMinHours: "users/admin-dashboard/top-employees-min-hours",
  allEmployeesMaxMinHours: "users/admin-dashboard/employees-hours",
  totals: "users/admin-dashboard/totals",
};

export {
  dashboardApi,
  auth,
  permission,
  clientsApi,
  employeesApi,
  projectsApi,
  activityApi,
  vendorsApi,
  consultantApi,
  adminDashboardApi,
  contractorApi,
};
