const activityNotFound = "Activities not found";
const clientNotFound = "Clients not found";
const employeeNotFound = "Employees not found";
const projectNotFound = "Projects not found";
const vendorNotFound = "Vendors not found";
const consultantNotFound = "Consultant not found";
const contractorNotFound = "Contractor not found";
const NotFoundMessage = "Not found";

export {
  activityNotFound,
  clientNotFound,
  employeeNotFound,
  projectNotFound,
  vendorNotFound,
  NotFoundMessage,
  consultantNotFound,
  contractorNotFound,
};
