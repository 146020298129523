import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState = {
  heading: "",
};

export const heading = createAsyncThunk(
  "header/dynamicHeading",
  async (value: string) => {
    return value;
  }
);

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(heading.fulfilled, (state, action) => {
      state.heading = action?.payload;
    });
  },
});

export const headerSelector = (state: RootState) => state.header;

const headerReducer = headerSlice.reducer;

export default headerReducer;
