// opt

import { getAuthToken } from "../../helper/helper";

export const requestHeaders = (authHeader: boolean, isMultiPart?: boolean) => {
  if (authHeader) {
    const authHeaders = {
      "Content-Type": isMultiPart ? "multipart/form-data" : "application/json",
      Authorization: getAuthToken(),
    };

    return { headers: authHeaders };
  }

  const headers = {
    "Content-Type": "application/json",
  };

  return { headers: headers };
};
