import DatePicker from "react-date-picker";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../components/loader/loader";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import { OK } from "../../config/axois/apiStatusCode";
import config from "../../config/config";
import { fetchAsyncDropdown, isDeleted, isEmpty } from "../../helper/helper";
import {
  dashboardSelector,
  fetchCurrentActivity,
  handleModal,
  setId,
  startActivity,
  stopActivity,
} from "../../redux/features/dashboard/dashboardSlice";
import {
  fetchSummary,
  summarySelector,
} from "../../redux/features/summary/summarySlice";
import { AppDispatch } from "../../redux/store";
import { ActivitiesType } from "../dashboard/dashboardType";
import { ActivityStop, ActivityType } from "../dashboard/type";
import {
  activityApi,
  employeesApi,
  projectsApi,
} from "../../config/apiUrls/apiUrl";
import { AsyncPaginate } from "react-select-async-paginate";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import { loginSelector } from "../../redux/features/auth/loginSlice";
import CustomPaginate from "../../components/pagination/CustomPaginate";
import { userSelector } from "../../redux/features/auth/userSlice";
import DeleteIcon from "../../components/delete/deleteIcon";
import { deleteModalSelector } from "../../redux/features/deleteModal/deleteModalSlice";
import { heading } from "../../redux/features/header/headerSlice";
import DeleteText from "../../components/delete/deleteText";

const Summary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, summary } = useSelector(summarySelector);
  const { type } = useSelector(loginSelector);
  const { isClockIn, isStartActivityLoading, isStopActivityLoading } =
    useSelector(dashboardSelector);
  const { user } = useSelector(userSelector);

  const { reCallApi } = useSelector(deleteModalSelector);

  const isAdmin = type === "admin";

  const [selected, setSelected] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [limit, setLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [count, setCount] = useState<number>(0);
  const [projectsOffset, setProjectsOffset] = useState("");
  const [projectsList, setProjectsList] = useState([]);
  const [projectsSearch, setProjectsSearch] = useState("");
  const [activityOffset, setActivityOffset] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [activitySearch, setActivitySearch] = useState("");
  const [activity, setActivity] = useState<any>({
    label: "All Activity Types",
    value: "",
  });

  const [employeeOffset, setEmployeeOffset] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeSearch, setEmployeeSearch] = useState("");
  const [employee, setEmployee] = useState<any>({
    label: "All Employee",
    value: "",
  });

  const [project, setProject] = useState<any>({
    label: "All Project",
    value: "",
  });
  const [fromMonth, setFromMonth] = useState<any>(moment());

  const {
    currentActivities: { current_activity },
  } = useSelector(dashboardSelector);

  const startStopActivity = async (
    isCurrentActivity: boolean,
    item: ActivitiesType
  ) => {
    if (isCurrentActivity) {
      // stop activity logic
      const body: ActivityStop = {
        latitude: "-",
        longitude: "-",
        end_date_time: moment().format(config.API_DATE_TIME_FORMATE),
        id: current_activity?.id ?? "",
      };

      const response = await dispatch(stopActivity(body));

      if ([200, 201].includes(response?.payload?.status)) {
        await dispatch(fetchCurrentActivity());
        setCurrentOffset(0);
        setSelected(0);
      }
      return;
    }

    // start activity logic
    const body: ActivityType = {
      latitude: "-",
      longitude: "-",
      project_id: item.project_id,
      activity_type_id: item.activity_type_id,
      start_date_time: moment().format(config.API_DATE_TIME_FORMATE),
      end_date_time: null,
      description: item.description,
    };

    const response = await dispatch(startActivity(body));

    if ([200, 201].includes(response?.payload?.status)) {
      await dispatch(fetchCurrentActivity());
      fetchSummaryRecords();
      setCurrentOffset(0);
      setSelected(0);
    }
  };

  const fetchProjects = (search: any) => {
    return fetchAsyncDropdown(
      projectsOffset,
      setProjectsOffset,
      projectsList,
      setProjectsList,
      `${projectsApi.getProjects}?`,
      search,
      setProjectsSearch,
      false,
      true,
      "All Projects"
    );
  };

  const fetchActivity = (search: any) => {
    return fetchAsyncDropdown(
      activityOffset,
      setActivityOffset,
      activityList,
      setActivityList,
      `${activityApi.getActivities}?`,
      search,
      setActivitySearch,
      false,
      true,
      "All Activity Types"
    );
  };

  const fetchEmployee = (search: any) => {
    return fetchAsyncDropdown(
      employeeOffset,
      setEmployeeOffset,
      employeeList,
      setEmployeeList,
      `${employeesApi.getEmployees}?`,
      search,
      setEmployeeSearch,
      "full_name",
      true,
      "All Employee"
    );
  };

  useEffect(() => {
    fetchSummaryRecords();
  }, [project, activity, fromMonth, employee, limit, currentOffset, user]);

  useEffect(() => {
    if (reCallApi) fetchSummaryRecords();
  }, [reCallApi]);

  const fetchSummaryRecords = async () => {
    const response = await dispatch(
      fetchSummary({
        month: moment(fromMonth).format("M"),
        year: moment(fromMonth).year().toString(),
        activity: activity?.value,
        project: project?.value,
        employee: isAdmin ? employee?.value : user?.id,
        limit: limit,
        offset: currentOffset,
        isDeleted: true,
      })
    );

    setCount(response?.payload?.data?.meta?.count ?? 0);
  };

  const openModal = (id: string, isCurrentActivity: boolean) => {
    if (!isCurrentActivity) {
      dispatch(handleModal(true));
      dispatch(setId(id));
    }
  };

  useEffect(() => {
    dispatch(heading("Summary"));
  }, []);

  return (
    <SideBarWrapper>
      <section
        style={{
          marginBottom: "-40px",
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-wrap align-items-center mb-30">
                <AsyncPaginate
                  loadOptions={fetchProjects}
                  onChange={(e: any) => {
                    setProject(e);
                  }}
                  isSearchable={true}
                  placeholder="All Projects"
                  value={project}
                  className="react-select-container me-2 mb-lg-0 mb-2"
                  classNamePrefix="react-select"
                  isDisabled={isLoading}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#0c78a4",
                    },
                  })}
                />

                <AsyncPaginate
                  loadOptions={fetchActivity}
                  onChange={(e: any) => {
                    setActivity(e);
                  }}
                  isSearchable={true}
                  placeholder="All Activity Types"
                  value={activity}
                  isDisabled={isLoading}
                  className="react-select-container me-2 mb-lg-0 mb-2"
                  classNamePrefix="react-select"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#0c78a4",
                    },
                  })}
                />

                {isAdmin && (
                  <AsyncPaginate
                    loadOptions={fetchEmployee}
                    onChange={(e: any) => {
                      setEmployee(e);
                    }}
                    isSearchable={true}
                    placeholder="All Employee"
                    value={employee}
                    isDisabled={isLoading}
                    className="react-select-container me-2 mb-lg-0 mb-2"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#0c78a4",
                      },
                    })}
                  />
                )}

                <DatePicker
                  value={fromMonth}
                  className={
                    "calendar summary-react-date-picker  p-1 me-3 w-auto bg-white border mb-lg-0 mb-2"
                  }
                  clearIcon={null}
                  calendarIcon={null}
                  disabled={isLoading}
                  defaultView={"month"}
                  format={"MM | yyyy"}
                  maxDate={moment().toDate()}
                  onClickMonth={(e: any) => {
                    setFromMonth(e);
                  }}
                  monthAriaLabel={"Month"}
                  monthPlaceholder="MM"
                  yearAriaLabel={"Year"}
                  yearPlaceholder={"YYYY"}
                  view={"year"}
                />
              </div>
            </div>

            {isLoading ? (
              <CustomLoader />
            ) : (
              <>
                {summary.length === 0 ? (
                  <NoRecordFound message="No Summary Found" />
                ) : (
                  summary &&
                  summary.length > 0 &&
                  summary.map(
                    ({ days, end_date, start_date, weekly_total_duration }) => {
                      return (
                        <div className="">
                          <div className="d-flex flex-wrap align-items-center justify-content-between py-13">
                            <p className="fs-md mb-lg-0 mb-2">
                              {isEmpty(start_date)
                                ? "-"
                                : moment(start_date).format(
                                    "DD MMMM YYYY"
                                  )}{" "}
                              To{" "}
                              {isEmpty(end_date)
                                ? "-"
                                : moment(end_date).format("DD MMMM YYYY")}
                            </p>
                            <p className="fs-md mb-lg-0 mb-2">
                              Week Total :{" "}
                              {isEmpty(weekly_total_duration)
                                ? "-"
                                : weekly_total_duration}{" "}
                            </p>
                          </div>
                          {days &&
                            days.length > 0 &&
                            days.map(
                              ({
                                date,
                                total_duration,
                                activities,
                                day_total_amount,
                              }) => {
                                return (
                                  <>
                                    <div className="mb-3">
                                      <div className="d-flex flex-wrap align-items-center justify-content-between total-time-tracking">
                                        <p className="fs-md">
                                          {isEmpty(date)
                                            ? "-"
                                            : moment(date).format(
                                                "DD MMMM YYYY"
                                              )}
                                        </p>
                                        <p className="fs-md">
                                          {isAdmin && (
                                            <span className="me-3">
                                              Total Cost:{" "}
                                              {isEmpty(day_total_amount)
                                                ? "-"
                                                : day_total_amount}
                                            </span>
                                          )}
                                          Total :{" "}
                                          {isEmpty(total_duration)
                                            ? "-"
                                            : total_duration}
                                        </p>
                                      </div>

                                      <div className="table-responsive dashboard-table">
                                        <table className="table table-hover table-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-2">
                                          <thead>
                                            <tr className="text-muted">
                                              {isAdmin && <th>Employee</th>}
                                              <th>Project</th>
                                              <th>Activity Type</th>
                                              <th>Description</th>
                                              <th>Start Time</th>
                                              <th>End Time</th>
                                              <th>Total</th>
                                              {isAdmin && <th>Cost</th>}
                                              {!isEmpty(isClockIn) && (
                                                <th>Actions</th>
                                              )}
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {activities &&
                                              activities.length > 0 &&
                                              activities.map(
                                                (
                                                  item: ActivitiesType,
                                                  index
                                                ) => {
                                                  const isCurrentActivity =
                                                    current_activity?.id ===
                                                    item.id;

                                                  return (
                                                    <tr>
                                                      {isAdmin && (
                                                        <td
                                                          className={`${
                                                            isCurrentActivity
                                                              ? "fw-bold"
                                                              : ""
                                                          }`}
                                                          style={{
                                                            color: !isEmpty(
                                                              item.deleted_at
                                                            )
                                                              ? "#aba9a6"
                                                              : "",
                                                          }}
                                                          onClick={() =>
                                                            isEmpty(
                                                              item.deleted_at
                                                            ) &&
                                                            openModal(
                                                              item.id,
                                                              isCurrentActivity
                                                            )
                                                          }
                                                          role={
                                                            !isCurrentActivity
                                                              ? "button"
                                                              : ""
                                                          }
                                                        >
                                                          {isEmpty(
                                                            item.employee_name
                                                          )
                                                            ? "-"
                                                            : item.employee_name}

                                                          {!isEmpty(
                                                            item.deleted_at
                                                          )
                                                            ? " - "
                                                            : ""}

                                                          {!isEmpty(
                                                            item.deleted_at
                                                          ) ? (
                                                            <DeleteText />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </td>
                                                      )}
                                                      <td
                                                        onClick={() =>
                                                          isEmpty(
                                                            item.deleted_at
                                                          ) &&
                                                          openModal(
                                                            item.id,
                                                            isCurrentActivity
                                                          )
                                                        }
                                                        role={
                                                          !isCurrentActivity
                                                            ? "button"
                                                            : ""
                                                        }
                                                        className={`${
                                                          isCurrentActivity
                                                            ? "fw-bold"
                                                            : ""
                                                        }`}
                                                        style={{
                                                          color: !isEmpty(
                                                            item.deleted_at
                                                          )
                                                            ? "#aba9a6"
                                                            : "",
                                                        }}
                                                      >
                                                        {isEmpty(
                                                          item.project_name
                                                        )
                                                          ? "-"
                                                          : item.project_name}

                                                        {!isAdmin && (
                                                          <>
                                                            {!isEmpty(
                                                              item.deleted_at
                                                            )
                                                              ? " - "
                                                              : ""}

                                                            {!isEmpty(
                                                              item.deleted_at
                                                            ) ? (
                                                              <DeleteText />
                                                            ) : (
                                                              ""
                                                            )}
                                                          </>
                                                        )}
                                                      </td>
                                                      <td
                                                        onClick={() =>
                                                          isEmpty(
                                                            item.deleted_at
                                                          ) &&
                                                          openModal(
                                                            item.id,
                                                            isCurrentActivity
                                                          )
                                                        }
                                                        role={
                                                          !isCurrentActivity
                                                            ? "button"
                                                            : ""
                                                        }
                                                        className={`${
                                                          isCurrentActivity
                                                            ? "fw-bold"
                                                            : ""
                                                        }`}
                                                        style={{
                                                          color: !isEmpty(
                                                            item.deleted_at
                                                          )
                                                            ? "#aba9a6"
                                                            : "",
                                                        }}
                                                      >
                                                        {isEmpty(
                                                          item.activity_type
                                                        )
                                                          ? "-"
                                                          : item.activity_type}
                                                      </td>
                                                      <td
                                                        onClick={() =>
                                                          isEmpty(
                                                            item.deleted_at
                                                          ) &&
                                                          openModal(
                                                            item.id,
                                                            isCurrentActivity
                                                          )
                                                        }
                                                        role={
                                                          !isCurrentActivity
                                                            ? "button"
                                                            : ""
                                                        }
                                                        className={`${
                                                          isCurrentActivity
                                                            ? "fw-bold"
                                                            : ""
                                                        }`}
                                                        style={{
                                                          color: !isEmpty(
                                                            item.deleted_at
                                                          )
                                                            ? "#aba9a6"
                                                            : "",
                                                        }}
                                                      >
                                                        {isEmpty(
                                                          item.description
                                                        )
                                                          ? "-"
                                                          : item.description}
                                                      </td>
                                                      <td
                                                        onClick={() =>
                                                          isEmpty(
                                                            item.deleted_at
                                                          ) &&
                                                          openModal(
                                                            item.id,
                                                            isCurrentActivity
                                                          )
                                                        }
                                                        role={
                                                          !isCurrentActivity
                                                            ? "button"
                                                            : ""
                                                        }
                                                        className={`${
                                                          isCurrentActivity
                                                            ? "fw-bold text-center"
                                                            : "text-center"
                                                        }`}
                                                        style={{
                                                          color: !isEmpty(
                                                            item.deleted_at
                                                          )
                                                            ? "#aba9a6"
                                                            : "",
                                                        }}
                                                      >
                                                        {isEmpty(
                                                          item.start_date_time
                                                        )
                                                          ? "-"
                                                          : moment(
                                                              item.start_date_time
                                                            ).format("LT")}
                                                      </td>
                                                      <td
                                                        onClick={() =>
                                                          isEmpty(
                                                            item.deleted_at
                                                          ) &&
                                                          openModal(
                                                            item.id,
                                                            isCurrentActivity
                                                          )
                                                        }
                                                        role={
                                                          !isCurrentActivity
                                                            ? "button"
                                                            : ""
                                                        }
                                                        className={`${
                                                          isCurrentActivity
                                                            ? "fw-bold text-center"
                                                            : "text-center"
                                                        }`}
                                                        style={{
                                                          color: !isEmpty(
                                                            item.deleted_at
                                                          )
                                                            ? "#aba9a6"
                                                            : "",
                                                        }}
                                                      >
                                                        {isEmpty(
                                                          item.end_date_time
                                                        )
                                                          ? "-"
                                                          : moment(
                                                              item.end_date_time
                                                            ).format("LT")}
                                                      </td>
                                                      <td
                                                        onClick={() =>
                                                          isEmpty(
                                                            item.deleted_at
                                                          ) &&
                                                          openModal(
                                                            item.id,
                                                            isCurrentActivity
                                                          )
                                                        }
                                                        role={
                                                          !isCurrentActivity
                                                            ? "button"
                                                            : ""
                                                        }
                                                        className={`${
                                                          isCurrentActivity
                                                            ? "fw-bold text-center"
                                                            : "text-center"
                                                        }`}
                                                        style={{
                                                          color: !isEmpty(
                                                            item.deleted_at
                                                          )
                                                            ? "#aba9a6"
                                                            : "",
                                                        }}
                                                      >
                                                        {isEmpty(
                                                          item.total_duration
                                                        )
                                                          ? "-"
                                                          : item.total_duration}
                                                      </td>
                                                      {isAdmin && (
                                                        <td
                                                          onClick={() =>
                                                            isEmpty(
                                                              item.deleted_at
                                                            ) &&
                                                            openModal(
                                                              item.id,
                                                              isCurrentActivity
                                                            )
                                                          }
                                                          role={
                                                            !isCurrentActivity
                                                              ? "button"
                                                              : ""
                                                          }
                                                          className={`${
                                                            isCurrentActivity
                                                              ? "fw-bold text-center"
                                                              : "text-center"
                                                          }`}
                                                          style={{
                                                            color: !isEmpty(
                                                              item.deleted_at
                                                            )
                                                              ? "#aba9a6"
                                                              : "",
                                                          }}
                                                        >
                                                          {isEmpty(
                                                            item.total_amount
                                                          )
                                                            ? "-"
                                                            : item.total_amount}
                                                        </td>
                                                      )}
                                                      {!isEmpty(isClockIn) && (
                                                        <td>
                                                          <div className="text-center">
                                                            {isEmpty(
                                                              item.deleted_at
                                                            ) ? (
                                                              <>
                                                                <DeleteIcon
                                                                  url={employeesApi.deleteEmployee.replace(
                                                                    ":id",
                                                                    item.id
                                                                  )}
                                                                />
                                                                {item.is_resumable_activity && (
                                                                  <img
                                                                    role="button"
                                                                    onClick={() => {
                                                                      let loading =
                                                                        isStartActivityLoading ||
                                                                        isStopActivityLoading ||
                                                                        isLoading;

                                                                      !loading &&
                                                                        startStopActivity(
                                                                          isCurrentActivity,
                                                                          item
                                                                        );
                                                                    }}
                                                                    src={`../img/svg/${
                                                                      isCurrentActivity
                                                                        ? "icon-pause-filled-red.svg"
                                                                        : "Play-filled.svg"
                                                                    }`}
                                                                    alt="play"
                                                                    title={
                                                                      isCurrentActivity
                                                                        ? "Stop Activity"
                                                                        : "Start Activity"
                                                                    }
                                                                    width={24}
                                                                    height={24}
                                                                    loading="eager"
                                                                    className=""
                                                                  />
                                                                )}
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </div>
                                                        </td>
                                                      )}
                                                    </tr>
                                                  );
                                                }
                                              )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                        </div>
                      );
                    }
                  )
                )}
              </>
            )}
          </div>
        </div>
      </section>

      <CustomPaginate
        data={summary}
        selected={selected}
        setSelected={setSelected}
        setCurrentOffset={setCurrentOffset}
        totalRecords={count}
        limit={limit}
        setLimit={setLimit}
        isDisable={isLoading}
        isLimitDropDown={true}
        hideEntries={true}
      />
    </SideBarWrapper>
  );
};

export default Summary;
