import React from "react";
import { Modal } from "react-bootstrap";
import {
  clearStorage,
  getValueFromLocalStorage,
  isEmpty,
} from "../../helper/helper";
import { ActivityStop } from "../../pages/dashboard/type";
import moment from "moment";
import config from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardSelector,
  onClockOut,
  stopActivity,
} from "../../redux/features/dashboard/dashboardSlice";
import { AppDispatch } from "../../redux/store";
import key from "../../config/key";

interface LogoutModalProps {
  show: boolean;
  onHide: () => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ show, onHide }) => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    currentActivities: { current_activity },
    isClockIn,
  } = useSelector(dashboardSelector);

  const logout = async () => {
    const type = getValueFromLocalStorage(key.type);

    if (type === "employee" && !isEmpty(isClockIn)) {
      const body: ActivityStop = {
        latitude: "-",
        longitude: "-",
        end_date_time: moment().format(config.API_DATE_TIME_FORMATE),
        id: current_activity?.id ?? "",
      };

      await dispatch(stopActivity(body));
      await dispatch(onClockOut());
    }

    onHide();
    clearStorage();
    window.location.href = "/";
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Logging Out</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to Logout ?</p>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 d-flex justify-content-evenly">
          <button
            className="btn cancel-button-background"
            type="button"
            onClick={onHide}
          >
            Cancel
          </button>
          <button className="btn me-4" type="button" onClick={logout}>
            Logout
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutModal;
