import "./App.css";
import DeleteModal from "./components/delete/deleteModal";
import AppRoute from "./routing/appRoute";

function App() {
  return (
    <>
      <DeleteModal />
      <AppRoute />
    </>
  );
}

export default App;
