import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import ErrorMessage from "../../components/errorMessage/errorMessage";
import useDebounce from "../../components/hooks/useDebounce";
import CustomLoader from "../../components/loader/loader";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import CustomPaginate from "../../components/pagination/CustomPaginate";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import SortingIndicator from "../../components/sorting/SortingIndicator";
import { clientsApi } from "../../config/apiUrls/apiUrl";
import config from "../../config/config";
import key from "../../config/key";
import {
  fetchAsyncDropdown,
  getValueFromLocalStorage,
  isDeleted,
  isEmpty,
} from "../../helper/helper";
import { projectNotFound } from "../../messages/NotFoundMessages";
import { loginSelector } from "../../redux/features/auth/loginSlice";
import { selectClientsLoading } from "../../redux/features/clients/clientSlice";
import { unAssignProjects } from "../../redux/features/employee/employeeSlice";
import {
  Project,
  createProject,
  fetchProjects,
  getProjectById,
  projectsSelectors,
  updateProject,
} from "../../redux/features/projects/projectSlice";
import { AppDispatch } from "../../redux/store";
import { validationSchema } from "./schema";
import { ProjectType } from "./type";
import DeleteIcon from "../../components/delete/deleteIcon";
import { deleteModalSelector } from "../../redux/features/deleteModal/deleteModalSlice";
import { heading } from "../../redux/features/header/headerSlice";

interface Props {
  isDetailsPage?: boolean;
  isEmployeeDetailPage?: boolean;
}

const ProjectsAdmin = ({ isDetailsPage, isEmployeeDetailPage }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { reCallApi } = useSelector(deleteModalSelector);

  const projects = useSelector(projectsSelectors.projects);
  const details = useSelector(projectsSelectors.details);
  const loading = useSelector(projectsSelectors.loading);
  const detailsLoading = useSelector(projectsSelectors.detailsLoading);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShowModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<any>(null);
  const clientsLoading = useSelector(selectClientsLoading);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selected, setSelected] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [limit, setLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [count, setCount] = useState<number>(0);
  const [clientOffset, setClientOffset] = useState("");
  const [clientList, setClientList] = useState([]);
  const [clientSearch, setClientSearch] = useState("");
  const [search, setSearch] = useState<string>("");
  const [ordering, setOrdering] = useState<string>("name");
  const searchDebounce = useDebounce(search, 500);
  const [unassignModal, setUnAssignModal] = useState(false);
  const [proId, setProId] = useState(0);

  const { type } = useSelector(loginSelector);

  const isAdmin = type === "admin";

  const openModal = (clientId: any) => {
    setShowModal(true);
    setSelectedProjectId(clientId);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedProjectId(null);
    setSelectedClient(null);
    setClientOffset("");
    setClientList([]);
    setClientSearch("");
    reset();
  };

  const navigate = useNavigate();

  const handleNavigation = (projectId: number) => {
    navigate("/project-details", { state: { projectId } });
  };

  const handleSearch = (e: React.SyntheticEvent) => {
    setCurrentOffset(0);
    setSelected(0);
    setSearch((e.target as HTMLInputElement).value);
  };

  const handleSort = (value: string) => {
    setOrdering(ordering === value ? `-${value}` : value);
    setCurrentOffset(0);
    setSelected(0);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ProjectType>({
    resolver: yupResolver(validationSchema) as any,
  });

  const onSubmit = async (data: ProjectType) => {
    const newProject = {
      name: data.name,
      client: selectedClient?.value || 0,
      address: isEmpty(data.address) ? null : data.address,
      note: null,
      budget: data.budget,
      description: isEmpty(data.description) ? null : data.description,
    };

    if (isEmpty(selectedProjectId)) {
      setSubmitting(true);

      const response = await dispatch(createProject(newProject));

      if ([200, 201].includes(response?.payload?.status)) {
        await fetchProjectRecords();
        closeModal();
      }

      setSubmitting(false);
    } else {
      setSubmitting(true);

      const response = await dispatch(
        updateProject({
          id: selectedProjectId,
          name: data.name,
          client: selectedClient?.value || 0,
          address: isEmpty(data.address) ? null : data.address,
          note: null,
          budget: data.budget,
          description: isEmpty(data.description) ? null : data.description,
        })
      );

      if ([200, 201].includes(response?.payload?.status)) {
        await fetchProjectRecords();
        closeModal();
      }

      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchProjectRecords();
  }, [currentOffset, selected, searchDebounce, ordering, location]);

  useEffect(() => {
    if (reCallApi) fetchProjectRecords();
  }, [reCallApi]);

  const fetchProjectRecords = async () => {
    const loginEmployeeId = getValueFromLocalStorage(key.userId);
    const type = getValueFromLocalStorage(key.type);

    const response = await dispatch(
      fetchProjects({
        currentOffset,
        limit,
        employeeId:"",
        clientId: isEmpty(location?.state?.clientId)
          ? ""
          : location?.state?.clientId,
        vendorId: isEmpty(location?.state?.vendorId)
          ? ""
          : location?.state?.vendorId,
        consultantId: isEmpty(location?.state?.consultantId)
          ? ""
          : location?.state?.consultantId,
        contractorId: isEmpty(location?.state?.contractorId)
          ? ""
          : location?.state?.contractorId,
        search,
        ordering,
        isDeleted: !isDetailsPage ? true : false,
      })
    );
    setCount(response?.payload?.data?.meta?.count ?? 0);
  };

  useEffect(() => {
    if (!isEmpty(selectedProjectId)) {
      dispatch(getProjectById(selectedProjectId));
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (!isEmpty(selectedProjectId) && details) {
      setValue("address", details?.address ?? null);
      setValue("name", details?.name ?? "");
      setValue("client", details?.client ?? "");
      setSelectedClient({
        value: details?.client ?? "",
        label: details?.client_name ?? "",
      });
      setValue("budget", details?.budget ?? "");
      setValue("note", details?.note ?? "");
      setValue("description", details?.description ?? "");
    }
  }, [details]);

  const fetchClientsRecords = (search: any) => {
    return fetchAsyncDropdown(
      clientOffset,
      setClientOffset,
      clientList,
      setClientList,
      `${clientsApi.getClients}?`,
      search,
      setClientSearch,
      false,
      false
    );
  };

  const unAssignProject = async () => {
    if (!isEmpty(location?.state?.employeeId)) {
      setSubmitting(true);

      const empId = location?.state?.employeeId;
      const projectIds = [proId];

      const response = await dispatch(
        unAssignProjects({
          id: empId,
          project_id: projectIds,
        })
      );

      if ([200, 201].includes(response?.payload?.status)) {
        setUnAssignModal(false);
        await fetchProjectRecords();
      }

      setSubmitting(false);
    }
  };

  const openConfirmUnassign = (proId: number) => {
    setUnAssignModal(true);
    setProId(proId);
  };

  const closeConfirmUnassign = () => {
    setUnAssignModal(false);
    setProId(0);
  };

  useEffect(() => {
    !isDetailsPage && dispatch(heading("Projects"));
  }, [isDetailsPage]);

  return (
    <SideBarWrapper removeChildren={isDetailsPage}>
      <div className="card" style={{ marginBottom: "-40px" }}>
        {isDetailsPage ? (
          <div className="d-flex flex-wrap align-items-center justify-content-between total-time-tracking">
            <p className="fs-md fw-bold">Projects</p>
            <input
              autoFocus
              name="text"
              type="text"
              autoComplete="text"
              placeholder="Search Here..."
              value={search}
              onChange={(e) => handleSearch(e)}
              className="form-control form-search"
              disabled={loading}
            />
          </div>
        ) : (
          <div className="card-header border-0 py-2 px-7 align-items-center">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div className="mb-res" style={{ marginTop: "15px" }}>
                <input
                  autoFocus
                  name="text"
                  type="text"
                  autoComplete="text"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  className="form-control form-search"
                  disabled={loading}
                />
              </div>
              <div
                className="ms-lg-auto"
                style={{
                  float: "right",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div className="card-toolbar d-flex my-0 mt-1 mb-1">
                  {true && (
                    <button
                      type="button"
                      className="btn"
                      onClick={() => openModal(null)}
                      disabled={loading}
                    >
                      Add Project
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            {isEmpty(projects) ? (
              <NoRecordFound message={projectNotFound} />
            ) : (
              <div className="card-body pt-0 pb-3">
                <div className="table-responsive">
                  <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                    <thead>
                      <tr className="text-muted fw-500">
                        <SortingIndicator
                          field="Name"
                          onClick={handleSort}
                          column="name"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Client Name"
                          onClick={handleSort}
                          column="client_name"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Total Vendors"
                          onClick={handleSort}
                          column="total_vendors"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Spent Hours"
                          onClick={handleSort}
                          column="total_working_duration"
                          sortingColumn={ordering}
                        />
                        {!isDetailsPage && (
                          <th className="w-10px" style={{ textAlign: "right" }}>
                            Action
                          </th>
                        )}
                        {isEmployeeDetailPage && (
                          <th className="w-10px" style={{ textAlign: "right" }}>
                            Action
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {projects &&
                        projects.map((project: any) => (
                          <tr
                            key={project.id}
                            onClick={() => handleNavigation(Number(project.id))}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <td>
                              {isEmpty(project.name) ? "-" : project.name}
                            </td>
                            <td>
                              {isEmpty(project.client_name)
                                ? "-"
                                : project.client_name}
                            </td>
                            <td>
                              {isEmpty(project.total_vendors)
                                ? "-"
                                : project.total_vendors}
                            </td>
                            <td>
                              {isEmpty(project.total_working_duration)
                                ? "-"
                                : project.total_working_duration}
                            </td>
                            {!isDetailsPage && (
                              <td
                                className="w-10px"
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  {isDeleted(
                                    project.deleted_at,
                                    <>
                                      <div
                                        style={{ paddingRight: "10px" }}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-pencil-fill"
                                          viewBox="0 0 16 16"
                                          onClick={() => openModal(project.id)}
                                        >
                                          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                        </svg>
                                      </div>

                                      {
                                        isAdmin &&
                                        <div onClick={(e) => e.stopPropagation()}>
                                          <DeleteIcon
                                            url={`/projects/delete/${project.id}`}
                                            tag="project"
                                          />
                                        </div>
                                      }
                                    </>
                                  )}
                                </div>
                              </td>
                            )}
                            {isEmployeeDetailPage && (
                              <td
                                className="w-10px"
                                style={{ textAlign: "right" }}
                              >
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <img
                                    src="../img/svg/ic-unassign.svg"
                                    alt="dashboard"
                                    title="dashboard"
                                    width={24}
                                    height={24}
                                    loading="eager"
                                    className="me-2"
                                    style={{ marginBottom: "10px" }}
                                    onClick={() =>
                                      openConfirmUnassign(Number(project.id))
                                    }
                                  />
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!isEmpty(projects) && !loading && (
        <CustomPaginate
          data={projects}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          setLimit={setLimit}
          isDisable={loading}
        />
      )}

      <Modal
        show={show}
        onHide={() => {
          closeModal();
        }}
        backdrop="static"
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEmpty(selectedProjectId) ? "Add" : "Update"} Project
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {detailsLoading || clientsLoading ? (
              <CustomLoader />
            ) : (
              <>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    placeholder="Enter Name"
                    disabled={submitting}
                    type="text"
                    {...register("name")}
                    isInvalid={!!errors.name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="client">
                  <Form.Label style={{ marginTop: "15px" }}>Client</Form.Label>
                  <span className="text-danger">*</span>
                  <div
                    style={{
                      color: "#111111",
                      boxShadow: "none",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px",
                      borderRadius: "0px",
                    }}
                  >
                    <AsyncPaginate
                      loadOptions={fetchClientsRecords}
                      onChange={(e: any) => {
                        setValue("client", e);
                        setSelectedClient(e);
                      }}
                      isSearchable={true}
                      value={selectedClient}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: "#0c78a4",
                        },
                      })}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          borderRadius: "0%",
                          border: errors.client
                            ? "1px solid #dc3545"
                            : "1px solid #ced4da",
                        }),
                      }}
                    />
                    {errors?.client && (
                      <span>
                        <ErrorMessage message={errors.client?.message ?? ""} />
                      </span>
                    )}
                  </div>
                </Form.Group>

                <Form.Group controlId="address">
                  <Form.Label style={{ marginTop: "15px" }}>
                    Project Address
                  </Form.Label>

                  <Form.Control
                    placeholder="Enter Address"
                    disabled={submitting}
                    type="text"
                    {...register("address")}
                    // isInvalid={!!errors.address?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="budget">
                  <Form.Label style={{ marginTop: "15px" }}>Budget</Form.Label>
                  <Form.Control
                    placeholder="Enter Budget"
                    disabled={submitting}
                    type="text"
                    {...register("budget")}
                    isInvalid={!!errors.budget?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.budget?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="description">
                  <Form.Label style={{ marginTop: "15px" }}>
                    Description
                  </Form.Label>
                  <Form.Control
                    as={"textarea"}
                    rows={6}
                    style={{
                      height: 100,
                    }}
                    placeholder="Enter Description"
                    disabled={submitting}
                    type="text"
                    {...register("description")}
                  />
                </Form.Group>
              </>
            )}
          </Modal.Body>
          {!detailsLoading && (
            <Modal.Footer>
              <div className="w-100 d-flex justify-content-center">
                <button
                  type="button"
                  onClick={() => closeModal()}
                  disabled={submitting}
                  className="cancel-button-background me-4 btn"
                >
                  Cancel
                </button>

                <button className="btn" type="submit" disabled={submitting}>
                  {submitting
                    ? "Please Wait"
                    : isEmpty(selectedProjectId)
                    ? "Submit"
                    : "Update"}
                </button>
              </div>
            </Modal.Footer>
          )}
        </Form>
      </Modal>

      <Modal
        show={unassignModal}
        onHide={closeConfirmUnassign}
        backdrop="static"
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Unassign?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Are you sure you want to unassign this project from this employee?
          </p>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 d-flex justify-content-evenly">
            <button
              className="btn cancel-button-background"
              type="button"
              onClick={closeConfirmUnassign}
              disabled={submitting}
            >
              Cancel
            </button>
            <button
              className="btn me-4"
              type="button"
              onClick={unAssignProject}
              disabled={submitting}
            >
              Unassign
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </SideBarWrapper>
  );
};

export default ProjectsAdmin;
