import React, { useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import CustomLoader from "../../components/loader/loader";
import { VendorType } from "./type";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./schema";
import { isEmpty } from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  clearConsultant,
  consultantsSelectors,
  getConsultantById,
} from "../../redux/features/consultant/consultantSlice";

interface ConsultantModalProps {
  show: boolean;
  submitting: boolean;
  closeModal: () => void;
  selectedId: any;
  submitForm: SubmitHandler<VendorType>;
}

const ConsultantModal: React.FC<ConsultantModalProps> = ({
  show,
  submitting,
  closeModal,
  selectedId,
  submitForm,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { details, detailsLoading } = useSelector(consultantsSelectors);

  useEffect(() => {
    if (isEmpty(selectedId)) {
      reset();
    } else if (!isEmpty(selectedId) && details) {
      setValue("name", details?.name ?? "");
      setValue("type", details?.type ?? "");
      setValue("phone_no", details?.phone_no ?? "");
      setValue("email", details?.email ?? "");
      setValue("note", details?.note ?? "");
    }
  }, [selectedId, details]);

  useEffect(() => {
    if (!isEmpty(selectedId)) {
      dispatch(getConsultantById(selectedId));
    }
  }, [selectedId]);

  useEffect(() => {
    if (!show) {
      reset();
    }
  }, [show]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm<VendorType>({
    resolver: yupResolver(validationSchema),
  });

  const handleModalClose = () => {
    reset();
    closeModal();
    dispatch(clearConsultant(true));
  };

  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      backdrop="static"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{selectedId ? "Update" : "Add"} Consultant</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(submitForm)}>
        <Modal.Body>
          {detailsLoading ? (
            <CustomLoader />
          ) : (
            <>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  placeholder="Enter Name"
                  disabled={submitting}
                  type="text"
                  {...register("name")}
                  isInvalid={!!errors.name?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message ?? ""}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="type">
                <Form.Label style={{ marginTop: "15px" }}>Type</Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  placeholder="Enter Type"
                  disabled={submitting}
                  type="text"
                  {...register("type")}
                  isInvalid={!!errors.type?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.type?.message ?? ""}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="phone_no">
                <Form.Label style={{ marginTop: "15px" }}>
                  Phone Number
                </Form.Label>
                <span className="text-danger">*</span>
                <Form.Control
                  placeholder="Enter Phone Number"
                  disabled={submitting}
                  type="text"
                  {...register("phone_no")}
                  isInvalid={!!errors.phone_no?.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone_no?.message ?? ""}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="email">
                  <Form.Label style={{ marginTop: "15px" }}>Email</Form.Label>
                  <Form.Control
                    placeholder="Enter Email"
                    disabled={submitting}
                    type="text"
                    {...register("email")}
                    isInvalid={!!errors.email?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

              <Form.Group controlId="note">
                  <Form.Label style={{ marginTop: "15px" }}>
                    Note
                  </Form.Label>
                  <Form.Control
                    as={"textarea"}
                    rows={6}
                    style={{
                      height: 100,
                    }}
                    placeholder="Enter Note"
                    disabled={submitting}
                    type="text"
                    {...register("note")}
                  />
                </Form.Group>
            </>
          )}
        </Modal.Body>
        {!detailsLoading && (
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-center">
              <button
                type="button"
                onClick={() => handleModalClose()}
                disabled={submitting}
                className="cancel-button-background me-4 btn"
              >
                Cancel
              </button>

              <button className="btn" type="submit" disabled={submitting}>
                {submitting
                  ? "Please Wait"
                  : isEmpty(selectedId)
                  ? "Submit"
                  : "Update"}
              </button>
            </div>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
};

export default ConsultantModal;
