const loginSuccessful = "Login Successful";
const clockInSuccessful = "Clock In Successful";
const clockOutSuccessful = "Clock Out Successful";
const ActivityStarted = "Activity Is Added";
const ActivityStopped = "Activity Is Stopped";
const ActivityUpdated = "Activity Is Updated";

export {
  loginSuccessful,
  clockInSuccessful,
  ActivityStarted,
  clockOutSuccessful,
  ActivityStopped,
  ActivityUpdated,
};
