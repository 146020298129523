import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showError } from "../../components/alerts/alerts";
import EmailComponent from "../../components/email-and-phone/EmailComponent";
import PhoneComponent from "../../components/email-and-phone/PhoneComponent";
import useDebounce from "../../components/hooks/useDebounce";
import CustomLoader from "../../components/loader/loader";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import CustomPaginate from "../../components/pagination/CustomPaginate";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import SortingIndicator from "../../components/sorting/SortingIndicator";
import config from "../../config/config";
import { isDeleted, isEmpty } from "../../helper/helper";
import { employeeNotFound } from "../../messages/NotFoundMessages";
import {
  assignProjects,
  createEmployee,
  employeeSelectors,
  fetchEmployees,
  getEmployeeById,
  updateEmployee,
} from "../../redux/features/employee/employeeSlice";
import { projectsSelectors } from "../../redux/features/projects/projectSlice";
import { AppDispatch } from "../../redux/store";
import AssignProjectsModal from "./AssignProjectsModal";
import { validationSchema } from "./schema";
import { EmployeeType } from "./type";
import DeleteIcon from "../../components/delete/deleteIcon";
import { deleteModalSelector } from "../../redux/features/deleteModal/deleteModalSlice";
import { heading } from "../../redux/features/header/headerSlice";

const Employees = () => {
  const dispatch = useDispatch<AppDispatch>();
  const employees = useSelector(employeeSelectors.employees);
  const details = useSelector(employeeSelectors.details);
  const loading = useSelector(employeeSelectors.loading);
  const detailsLoading = useSelector(employeeSelectors.detailsLoading);
  const projectsLoading = useSelector(projectsSelectors.loading);
  const navigate = useNavigate();
  const location = useLocation();
  const { reCallApi } = useSelector(deleteModalSelector);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShowModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<any>(null);
  const [selected, setSelected] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [limit, setLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [count, setCount] = useState<number>(0);
  const [showPassword, setShowPassword] = useState(false);
  const [search, setSearch] = useState<string>("");
  const searchDebounce = useDebounce(search, 500);
  const [ordering, setOrdering] = useState<string>("full_name");
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<any[]>([]);
  const [user, setUser] = useState<string>("");

  const handleNavigation = (employeeId: number) => {
    if (location?.state?.isComingFromAdminDashboard) {
      return;
    }
    navigate("/employees-details", { state: { employeeId } });
  };

  const openModal = (empId: any) => {
    setShowModal(true);
    setSelectedEmployeeId(empId);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEmployeeId(null);
    reset();
  };

  const handleSearch = (e: React.SyntheticEvent) => {
    setCurrentOffset(0);
    setSelected(0);
    setSearch((e.target as HTMLInputElement).value);
  };

  const handleSort = (value: string) => {
    setOrdering(ordering === value ? `-${value}` : value);
    setCurrentOffset(0);
    setSelected(0);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<EmployeeType>({
    resolver: yupResolver(validationSchema) as any,
  });

  const onSubmit = async (data: EmployeeType) => {
    const newEmployee = {
      first_name: data.first_name,
      last_name: data.last_name,
      phone_no: data.phone_no,
      email: data.email,
      password: isEmpty(data.password) ? null : data.password,
      pay_per_hour: isEmpty(data.pay_per_hour) ? null : data.pay_per_hour,
      is_active: data.is_active ? true : false,
    };

    if (isEmpty(selectedEmployeeId)) {
      setSubmitting(true);

      const response = await dispatch(createEmployee(newEmployee));
      if ([200, 201].includes(response?.payload?.status)) {
        await fetchEmployeeRecords();
        closeModal();
      }

      setSubmitting(false);
    } else {
      setSubmitting(true);

      const response = await dispatch(
        updateEmployee({
          id: selectedEmployeeId,
          ...newEmployee,
        })
      );

      if ([200, 201].includes(response?.payload?.status)) {
        await fetchEmployeeRecords();
        closeModal();
      }

      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchEmployeeRecords();
  }, [currentOffset, selected, searchDebounce, ordering, user, location]);

  const fetchEmployeeRecords = async () => {
    const response = await dispatch(
      fetchEmployees({
        currentOffset,
        limit,
        search,
        ordering: location?.state?.adminDashboardOrdering
          ? location?.state?.adminDashboardOrdering
          : ordering,
        is_active: user,
        isDeleted: location?.state?.isComingFromAdminDashboard ? false : true,
      })
    );
    setCount(response?.payload?.data?.meta?.count ?? 0);
  };

  useEffect(() => {
    if (!isEmpty(selectedEmployeeId)) {
      dispatch(getEmployeeById(selectedEmployeeId));
    }
  }, [selectedEmployeeId]);

  useEffect(() => {
    if (reCallApi) fetchEmployeeRecords();
  }, [reCallApi]);

  useEffect(() => {
    if (!isEmpty(selectedEmployeeId) && details) {
      setValue("first_name", details?.first_name ?? "");
      setValue("last_name", details?.last_name ?? "");
      setValue("email", details?.email ?? "");
      setValue("phone_no", details?.phone_no ?? "");
      setValue("password", details?.password ?? "");
      setValue("pay_per_hour", details?.pay_per_hour ?? "");
      setValue("is_active", details?.is_active ?? "");
    }
  }, [details]);

  const openAssignProjectsModal = (empId: number) => {
    setAssignModal(true);
    setSelectedEmployeeId(empId);
  };

  const closeAssignProjectsModal = () => {
    setAssignModal(false);
    setSelectedProjectsIds([]);
    setSelectedEmployeeId(null);
    reset();
  };

  const assignEmployeeProject = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);

    if (isEmpty(selectedProjectsIds)) {
      showError("Please select at least one project");
      setSubmitting(false);
    } else {
      const allProjectIds = selectedProjectsIds.map((data) => data.value);

      await dispatch(
        assignProjects({
          id: selectedEmployeeId,
          project_id: allProjectIds,
        })
      );
      closeAssignProjectsModal();

      setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(heading("Employees"));
  }, []);

  return (
    <SideBarWrapper>
      <div className="card" style={{ marginBottom: "-40px" }}>
        <div className="card-header border-0 py-2 px-7 align-items-center">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div className="mb-res" style={{ marginTop: "15px" }}>
              <input
                autoFocus
                name="text"
                type="text"
                autoComplete="text"
                placeholder="Search"
                value={search}
                onChange={(e) => handleSearch(e)}
                className="form-control form-search"
                disabled={loading}
              />
            </div>
            <div
              className="ms-lg-auto"
              style={{
                float: "right",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="card-toolbar d-flex my-0 mt-1 mb-1">
                {location?.state?.isComingFromAdminDashboard ? null : (
                  <select
                    className="form-select me-3 border"
                    style={{
                      width: 160,
                      height: 38,
                    }}
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                  >
                    <option value="">All Users</option>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                )}

                {location?.state?.isComingFromAdminDashboard ? null : (
                  <button
                    type="button"
                    className="btn"
                    onClick={() => openModal(null)}
                    disabled={loading}
                  >
                    Add Employee
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            {isEmpty(employees) ? (
              <NoRecordFound message={employeeNotFound} />
            ) : (
              <div className="card-body pt-0 pb-3">
                <div className="table-responsive">
                  <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                    <thead>
                      <tr className="text-muted fw-500">
                        <SortingIndicator
                          field="Name"
                          onClick={handleSort}
                          column="full_name"
                          sortingColumn={ordering}
                          hideShorting={
                            location?.state?.isComingFromAdminDashboard
                          }
                        />

                        <SortingIndicator
                          field="Email"
                          onClick={handleSort}
                          column="email"
                          sortingColumn={ordering}
                          hideShorting={
                            location?.state?.isComingFromAdminDashboard
                          }
                        />

                        <SortingIndicator
                          field="Phone Number"
                          onClick={handleSort}
                          column="phone_no"
                          sortingColumn={ordering}
                          hideShorting={
                            location?.state?.isComingFromAdminDashboard
                          }
                        />

                        <SortingIndicator
                          field="Pay Per Hour"
                          onClick={handleSort}
                          column="pay_per_hour"
                          sortingColumn={ordering}
                          hideShorting={
                            location?.state?.isComingFromAdminDashboard
                          }
                        />

                        {location?.state?.isComingFromAdminDashboard ? null : (
                          <th
                            className="w-10px"
                            style={{
                              display: "flex",
                              justifyContent: "right",
                            }}
                          >
                            Actions
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {employees &&
                        employees.map((employee) => (
                          <tr
                            style={{
                              cursor: "pointer",
                            }}
                            key={employee.id}
                          >
                            <td
                              onClick={() =>
                                handleNavigation(Number(employee.id))
                              }
                            >
                              {isEmpty(employee.full_name)
                                ? "-"
                                : employee.full_name}
                            </td>
                            <td>
                              <EmailComponent email={employee.email} />
                            </td>
                            <td>
                              <PhoneComponent phone={employee.phone_no} />
                            </td>
                            <td
                              onClick={() =>
                                handleNavigation(Number(employee.id))
                              }
                            >
                              {isEmpty(employee.pay_per_hour)
                                ? "-"
                                : employee.pay_per_hour}
                            </td>

                            {location?.state
                              ?.isComingFromAdminDashboard ? null : (
                              <td className="w-10px">
                                <div
                                  style={{
                                    cursor: "pointer",
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  {isDeleted(
                                    employee.deleted_at,
                                    <>
                                      <div
                                        style={{ paddingRight: "10px" }}
                                        onClick={() => openModal(employee.id)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-pencil-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                        </svg>
                                      </div>
                                      {employee.is_active && (
                                        <div
                                          style={{ paddingRight: "10px" }}
                                          onClick={() =>
                                            openAssignProjectsModal(
                                              Number(employee.id)
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-person-plus-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                            <path
                                              fill-rule="evenodd"
                                              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"
                                            />
                                          </svg>
                                        </div>
                                      )}

                                      <div>
                                        <DeleteIcon
                                          url={`/employees/delete/${employee.id}`}
                                          tag="employee"
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!isEmpty(employees) && !loading && (
        <CustomPaginate
          data={employees}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          setLimit={setLimit}
          isDisable={loading}
        />
      )}

      <Modal
        show={show}
        onHide={() => {
          closeModal();
        }}
        backdrop="static"
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEmpty(selectedEmployeeId) ? "Add" : "Update"} Employee
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {detailsLoading ? (
              <CustomLoader />
            ) : (
              <>
                <Form.Group controlId="first_name">
                  <Form.Label>First Name</Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    placeholder="Enter First Name"
                    disabled={submitting}
                    type="text"
                    {...register("first_name")}
                    isInvalid={!!errors.first_name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="last_name">
                  <Form.Label style={{ marginTop: "15px" }}>
                    Last Name
                  </Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    placeholder="Enter Last Name"
                    disabled={submitting}
                    type="text"
                    {...register("last_name")}
                    isInvalid={!!errors.last_name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="phone_no">
                  <Form.Label style={{ marginTop: "15px" }}>
                    Phone Number
                  </Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    placeholder="Enter Phone Number"
                    disabled={submitting}
                    type="text"
                    {...register("phone_no")}
                    isInvalid={!!errors.phone_no?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone_no?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label style={{ marginTop: "15px" }}>Email</Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    placeholder="Enter Email"
                    disabled={submitting}
                    type="text"
                    {...register("email")}
                    isInvalid={!!errors.email?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="password" className="pw-hide">
                  <Form.Label style={{ marginTop: "15px" }}>
                    Password
                  </Form.Label>
                  <div className="pw-hide">
                    <Form.Control
                      placeholder="Enter Password"
                      disabled={submitting}
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                      isInvalid={!!errors.password?.message}
                      className="pw-hide"
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className={`${
                        showPassword ? "show-password" : "hide-password"
                      } img-eye`}
                    ></span>
                  </div>

                  <Form.Control.Feedback type="invalid">
                    {errors.password?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="pay_per_hour">
                  <Form.Label style={{ marginTop: "15px" }}>
                    Pay Per Hour
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Pay Per Hour"
                    disabled={submitting}
                    type="text"
                    {...register("pay_per_hour")}
                    isInvalid={!!errors.pay_per_hour?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.pay_per_hour?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="is_active">
                  <Form.Check
                    style={{
                      marginTop: "20px",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "22px",
                      color: "#6b6b6b",
                    }}
                    type="checkbox"
                    label={"Is Active?"}
                    defaultChecked={true}
                    {...register("is_active")}
                  />
                </Form.Group>
              </>
            )}
          </Modal.Body>
          {!detailsLoading && (
            <Modal.Footer>
              <div className="w-100 d-flex justify-content-center">
                <button
                  type="button"
                  onClick={() => closeModal()}
                  disabled={submitting}
                  className="cancel-button-background me-4 btn"
                >
                  Cancel
                </button>

                <button className="btn" type="submit" disabled={submitting}>
                  {submitting
                    ? "Please Wait"
                    : isEmpty(selectedEmployeeId)
                    ? "Submit"
                    : "Update"}
                </button>
              </div>
            </Modal.Footer>
          )}
        </Form>
      </Modal>

      <AssignProjectsModal
        show={assignModal}
        onHide={closeAssignProjectsModal}
        selectedProjectsIds={selectedProjectsIds}
        setSelectedProjectsIds={setSelectedProjectsIds}
        submitting={submitting}
        assignEmployeeProject={assignEmployeeProject}
        employeeId={selectedEmployeeId}
      />
    </SideBarWrapper>
  );
};

export default Employees;
