import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const initialState = {
  url: "",
  isModal: false,
  header: "",
  tag: "",
  reCallApi: false,
};

export const handleDeleteModal = createAsyncThunk(
  "deleteModal/handleDeleteModal",
  async (value: any) => {
    return value;
  }
);

export const reCallApi = createAsyncThunk(
  "deleteModal/reCallApi",
  async (value: any) => {
    return value;
  }
);

const deleteModalSlice = createSlice({
  name: "deleteModal",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(handleDeleteModal.fulfilled, (state, action) => {
      state.url = action?.payload?.url;
      state.isModal = action?.payload?.isModal;
      state.tag = action?.payload?.tag;
    });
    builder.addCase(reCallApi.fulfilled, (state, action) => {
      state.reCallApi = action?.payload;
    });
  },
});

export const deleteModalSelector = (state: RootState) => state.deleteModal;

const deleteModalReducer = deleteModalSlice.reducer;

export default deleteModalReducer;
