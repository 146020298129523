import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../../components/alerts/alerts";
import { postRequest } from "../../../config/axois/apiRequest";
import { auth } from "../../../config/apiUrls/apiUrl";
import { OK } from "../../../config/axois/apiStatusCode";
import {
  getValueFromLocalStorage,
  setValueInLocalStorage,
} from "../../../helper/helper";
import config from "../../../config/config";
import { loginSuccessful } from "../../../messages/successMessage";
import key from "../../../config/key";
import { RootState } from "../../store";

const initialState = {
  response: {},
  token: getValueFromLocalStorage(config.LOCAL_STORAGE_TOKEN_KEY) || "",
  type: getValueFromLocalStorage(key.type) || "",
  isLoading: false,
};

export const onLogin: any = createAsyncThunk("login/onLogin", async (body) => {
  try {
    const response = await postRequest(auth.login, body, false);

    return response;
  } catch (error: any) {
    if (error?.response?.data?.message) {
      showError(error?.response?.data?.message);
    } else {
      showError("Something Went Wrong!");
    }

    return error;
  }
});

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(onLogin.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(onLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action?.payload?.data?.data ?? {};

        if ([200, 201].includes(action?.payload?.status)) {
          // store token in local storage
          setValueInLocalStorage(
            config.LOCAL_STORAGE_TOKEN_KEY,
            action?.payload?.data?.data?.access ?? ""
          );

          // store token in redux state
          state.token = action?.payload?.data?.data?.access ?? "";

          // store role name (TYPE) in local storage
          state.type = action?.payload?.data?.data?.role_name ?? "";
          setValueInLocalStorage(
            key.type,
            action?.payload?.data?.data?.role_name ?? ""
          );

          setValueInLocalStorage(
            key.userId,
            action?.payload?.data?.data?.id ?? ""
          );

          showSuccess(loginSuccessful);
        }
      })
      .addCase(onLogin.rejected, (state: any, action) => {
        state.isLoading = false;
        state.response = {};
      });
  },
});

export const loginSelector = (state: RootState) => state.login;

const loginReducer = loginSlice.reducer;

export default loginReducer;
