import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { clearStorage, isEmpty } from "../helper/helper";
import ActivityPage from "../pages/activityTypes/activity";
import AdminDashboard from "../pages/adminDashboard/adminDashboard";
import Clients from "../pages/clients/clients";
import Dashboard from "../pages/dashboard/dashboard";
import EmployeeDetails from "../pages/employees/employee-details";
import Employees from "../pages/employees/employees";
import Login from "../pages/login/login";
import Profile from "../pages/profile/profile";
import ProjectsAdmin from "../pages/project-admin/project-admin";
import ProjectDetails from "../pages/project/project-details";
import Summary from "../pages/summary/summarytable";
import Vendors from "../pages/vendors/Vendors";
import { loginSelector } from "../redux/features/auth/loginSlice";
import { getUserProfile } from "../redux/features/auth/userSlice";
import { fetchCurrentActivity } from "../redux/features/dashboard/dashboardSlice";
import { fetchPermissions } from "../redux/features/permissions/fetchPermissions";
import { AppDispatch } from "../redux/store";
import ClientDetails from "../pages/clients/client-details";
import VendorDetails from "../pages/vendors/vendor-details";
import MaxMinEmployees from "../pages/adminDashboard/maxMinEmployees";
import Consultant from "../pages/consultant/consultant";
import ConsultantDetails from "../pages/consultant/consultantDetails";
import Contractor from "../pages/contractor/contractor";
import ContractorDetails from "../pages/contractor/contractorDetails";

const AppRoute = () => {
  const { token, type } = useSelector(loginSelector);

  const dispatch = useDispatch<AppDispatch>();

  const isAdmin = type === "admin";

  useEffect(() => {
    if (!isEmpty(token)) {
      dispatch(fetchPermissions());
      dispatch(getUserProfile());
      !isAdmin && dispatch(fetchCurrentActivity());
    }
  }, [token]);

  return (
    <>
      <Routes>
        {token ? (
          <>
            {isAdmin ? (
              <>
                <Route path="/dashboard" element={<AdminDashboard />} />
                <Route path="/employees-hours" element={<MaxMinEmployees />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/clients-details" element={<ClientDetails />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/activity-types" element={<ActivityPage />} />
                <Route path="/consultants" element={<Consultant />} />
                <Route
                  path="/consultant-details"
                  element={<ConsultantDetails />}
                />
                <Route path="/contractors" element={<Contractor />} />
                <Route
                  path="/contractor-details"
                  element={<ContractorDetails />}
                />
                <Route path="/vendors" element={<Vendors />} />
                <Route path="/vendors-details" element={<VendorDetails />} />
                <Route
                  path="/employees-details"
                  element={<EmployeeDetails />}
                />
                <Route path="/summary" element={<Summary />} />
                <Route path="/project" element={<ProjectsAdmin />} />
                <Route path="/project-details" element={<ProjectDetails />} />
                <Route path="/profile" element={<Profile />} />
              </>
            ) : (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/clients-details" element={<ClientDetails />} />
                <Route path="/activity-types" element={<ActivityPage />} />
                <Route path="/summary" element={<Summary />} />
                <Route path="/project" element={<ProjectsAdmin />} />
                <Route path="/project-details" element={<ProjectDetails />} />
                <Route path="/profile" element={<Profile />} />
              </>
            )}

            <Route path="*" element={<Navigate to={"/dashboard"} />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to={"/login"} />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default AppRoute;
