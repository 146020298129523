import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AsyncPaginate } from "react-select-async-paginate";
import ErrorMessage from "../../components/errorMessage/errorMessage";
import { activityApi, projectsApi } from "../../config/apiUrls/apiUrl";
import { fetchAsyncDropdown, isEmpty } from "../../helper/helper";
import { activitiesValidationSchema } from "./schema";
import { ActivitiesValidationSchema, ActivityType } from "./type";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  dashboardSelector,
  fetchCurrentActivity,
  fetchDashboard,
  fetchSingleActivity,
  handleModal,
  setId,
  startActivity,
  updateSingleActivity,
} from "../../redux/features/dashboard/dashboardSlice";
import { OK } from "../../config/axois/apiStatusCode";
import LiveTimeTracker from "./liveTimeTracker";
import config from "../../config/config";
import dayjs from "dayjs";
import CustomLoader from "../../components/loader/loader";
import { useLocation } from "react-router-dom";
import { userSelector } from "../../redux/features/auth/userSlice";
import { reCallApi } from "../../redux/features/deleteModal/deleteModalSlice";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Timer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { user } = useSelector(userSelector);

  const {
    isStartActivityLoading,
    currentActivities: { current_activity },
    isModalShow,
    id,
    singleActivity,
    isSingleActivityLoading,
  } = useSelector(dashboardSelector);

  const [show, setShow] = useState<boolean>(false);
  const [projectsOffset, setProjectsOffset] = useState("");
  const [projectsList, setProjectsList] = useState([]);
  const [projectsSearch, setProjectsSearch] = useState("");
  const [activityOffset, setActivityOffset] = useState("");
  const [activityList, setActivityList] = useState([]);
  const [activitySearch, setActivitySearch] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState: { errors },
  } = useForm<ActivitiesValidationSchema>({
    resolver: yupResolver(activitiesValidationSchema),
    defaultValues: {
      project_id: { label: "Select" },
      activity_type_id: { label: "Select" },
      description: "",
      start_date_time: dayjs().toString(),
    },
  });

  const pickerRef = useRef<any>(null); // Ref to manage the picker

  const { activity_type_id, project_id, start_date_time, end_date_time } =
    watch();

  const showModal = () => {
    setShow(true);

    if (isEmpty(id)) {
      setValue("start_date_time", dayjs().toString());
    }
  };

  const hideModal = () => {
    setShow(false);
    reset();
    setProjectsOffset("");
    setProjectsList([]);
    setActivityOffset("");
    setProjectsSearch("");
    setActivityList([]);
    setActivitySearch("");
    dispatch(handleModal(false));
    dispatch(setId(""));
  };

  const onSubmit = async (data: ActivitiesValidationSchema) => {
    const body: ActivityType = {
      latitude: "-",
      longitude: "-",
      project_id: data.project_id.value,
      activity_type_id: data.activity_type_id.value,
      start_date_time: moment(data.start_date_time).format(
        config.API_DATE_TIME_FORMATE
      ),
      end_date_time: isEmpty(data.end_date_time)
        ? null
        : moment(data.end_date_time).format(config.API_DATE_TIME_FORMATE),
      description: isEmpty(data.description) ? null : data.description,
    };

    if (isEmpty(id)) {
      const response = await dispatch(startActivity(body));

      if ([200, 201].includes(response?.payload?.status)) {
        hideModal();
        await dispatch(fetchCurrentActivity());
        dispatch(fetchDashboard());
      }
    } else {
      const param = {
        id: id,
        body: {
          project: data.project_id.value,
          activity_type: data.activity_type_id.value,
          start_date_time: data.start_date_time,
          end_date_time: isEmpty(data.end_date_time)
            ? null
            : data.end_date_time,
          description: isEmpty(data.description) ? null : data.description,
        },
      };

      const response = await dispatch(updateSingleActivity(param));

      if ([200, 201].includes(response?.payload?.status)) {
        hideModal();
        await dispatch(fetchCurrentActivity());

        if (location.pathname.includes("/dashboard")) {
          dispatch(fetchDashboard());
        }

        if (location.pathname.includes("/summary")) {
          dispatch(reCallApi(true));

          setTimeout(() => {
            dispatch(reCallApi(false));
          }, 100);
        }
      }
    }
  };

  const fetchProjects = (search: any) => {
    return fetchAsyncDropdown(
      projectsOffset,
      setProjectsOffset,
      projectsList,
      setProjectsList,
      `${projectsApi.getProjects}?employee_id=${user?.id}`,
      search,
      setProjectsSearch,
      false,
      false
    );
  };

  const fetchActivity = (search: any) => {
    return fetchAsyncDropdown(
      activityOffset,
      setActivityOffset,
      activityList,
      setActivityList,
      `${activityApi.getActivities}?is_active=true`,
      search,
      setActivitySearch,
      false,
      false
    );
  };

  const updateActivity = () => {
    if (!isEmpty(singleActivity.id)) {
      !isEmpty(singleActivity.activity_type) &&
        setValue("activity_type_id", {
          label: singleActivity.activity_type_name,
          value: singleActivity.activity_type,
        });

      isEmpty(singleActivity.description)
        ? setValue("description", "")
        : setValue("description", singleActivity.description);

      !isEmpty(singleActivity.project) &&
        setValue("project_id", {
          label: singleActivity.project_name,
          value: singleActivity.project,
        });

      !isEmpty(singleActivity.start_date_time) &&
        setValue(
          "start_date_time",
          dayjs(
            moment(singleActivity.start_date_time).format(
              config.API_DATE_TIME_FORMATE
            )
          ).format(config.API_DATE_TIME_FORMATE)
        );

      !isEmpty(singleActivity.end_date_time) &&
        setValue(
          "end_date_time",
          dayjs(
            moment(singleActivity.end_date_time).format(
              config.API_DATE_TIME_FORMATE
            )
          ).format(config.API_DATE_TIME_FORMATE)
        );
    }
  };

  useEffect(() => {
    setShow(isModalShow);
  }, [isModalShow]);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchSingleActivity(id));
    }
  }, [id]);

  useEffect(() => {
    !isEmpty(id) && !isEmpty(singleActivity?.id) ? updateActivity() : reset();
  }, [singleActivity, id]);

  return (
    <>
      {!location.pathname.includes("/summary") && (
        <>
          {!isEmpty(current_activity) ? (
            <LiveTimeTracker />
          ) : (
            <button
              className="btn d-inline-flex align-items-center me-20"
              type="button"
              onClick={showModal}
            >
              <img
                src="../img/svg/play-filled-white.svg"
                alt="activity"
                title="activity"
                width={24}
                height={24}
                loading="eager"
                className="me-6"
              />
              <span>Start</span>
            </button>
          )}
        </>
      )}

      <Modal
        show={show}
        onHide={() => {
          hideModal();
        }}
        backdrop="static"
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEmpty(id) ? "Start" : "Update"} Activity</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isSingleActivityLoading ? (
              <CustomLoader />
            ) : (
              <>
                <div className="mb-3">
                  <label htmlFor="">
                    Project<span className="text-danger">*</span>
                  </label>
                  <AsyncPaginate
                    loadOptions={fetchProjects}
                    onChange={(e: any) => {
                      setValue("project_id", e);
                      trigger("project_id");
                    }}
                    isSearchable={true}
                    placeholder="All Projects"
                    value={project_id}
                    className="react-select-container mt-2"
                    classNamePrefix="react-select"
                    isDisabled={isStartActivityLoading}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#0c78a4",
                      },
                    })}
                  />
                  {errors?.project_id && (
                    <ErrorMessage message={errors.project_id?.message ?? ""} />
                  )}
                </div>

                <div className="mb-3">
                  <label htmlFor="">
                    Activity Type<span className="text-danger">*</span>
                  </label>
                  <AsyncPaginate
                    loadOptions={fetchActivity}
                    onChange={(e: any) => {
                      setValue("activity_type_id", e);
                      trigger("activity_type_id");
                    }}
                    isSearchable={true}
                    placeholder="All Activity"
                    value={activity_type_id}
                    isDisabled={isStartActivityLoading}
                    className="react-select-container mt-2"
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#0c78a4",
                      },
                    })}
                  />
                  {errors?.activity_type_id && (
                    <ErrorMessage
                      message={errors.activity_type_id?.message ?? ""}
                    />
                  )}
                </div>

                <div className="mb-3 d-flex">
                  <div className="me-4">
                    <label htmlFor="">Start Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          onChange={(e) => {
                            setValue(
                              "start_date_time",
                              moment(e?.toDate()).format(
                                config.API_DATE_TIME_FORMATE
                              )
                            );

                            trigger("start_date_time");
                            trigger("end_date_time");
                          }}
                          disabled={isStartActivityLoading}
                          value={
                            isEmpty(start_date_time)
                              ? dayjs()
                              : dayjs(start_date_time)
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    {errors?.start_date_time && (
                      <ErrorMessage
                        message={errors.start_date_time?.message ?? ""}
                      />
                    )}
                  </div>

                  <div className="">
                    <label htmlFor="">End Time</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          onChange={(e) => {
                            setValue(
                              "end_date_time",
                              moment(e?.toDate()).format(
                                config.API_DATE_TIME_FORMATE
                              )
                            );

                            trigger("start_date_time");
                            trigger("end_date_time");
                          }}
                          disabled={isStartActivityLoading}
                          value={
                            isEmpty(end_date_time) ? null : dayjs(end_date_time)
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    {errors?.end_date_time && (
                      <ErrorMessage
                        message={errors.end_date_time?.message ?? ""}
                      />
                    )}
                  </div>
                </div>

                {!isEmpty(id) && (
                  <div className="mb-3">
                    <label htmlFor="">Select Date</label> <br />
                    <DatePicker
                      selected={dayjs(start_date_time).toDate()}
                      onChange={(date) => {
                        const newDate = moment(date).format("YYYY-MM-DD");
                        const startTime =
                          moment(start_date_time).format("HH:mm:ss");
                        const endTime =
                          moment(end_date_time).format("HH:mm:ss");

                        setValue("start_date_time", `${newDate} ${startTime}`);
                        setValue("end_date_time", `${newDate} ${endTime}`);

                        trigger("start_date_time");
                        trigger("end_date_time");
                      }}
                      dateFormat="dd-MM-yyyy"
                      className={
                        "calendar custom-react-date-picker p-3 me-3 bg-white border bg-gray"
                      }
                      disabled={isStartActivityLoading}
                      maxDate={moment().toDate()}
                    />
                  </div>
                )}

                <div>
                  <label htmlFor="">
                    Description<span className="text-danger">*</span>
                  </label>
                  <textarea
                    style={{
                      borderColor: "#e6e6e6",
                    }}
                    rows={4}
                    className="mt-2 w-100"
                    {...register("description")}
                    disabled={isStartActivityLoading}
                  ></textarea>

                  {errors?.description && (
                    <ErrorMessage message={errors.description?.message ?? ""} />
                  )}
                </div>
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <div className="w-100">
              <button
                className="btn w-100"
                type="submit"
                disabled={isStartActivityLoading || isSingleActivityLoading}
              >
                {isStartActivityLoading
                  ? "Please Wait"
                  : isEmpty(id)
                  ? "Start Activity"
                  : "Update Activity"}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Timer;
