import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import key from "../../config/key";
import { isEmpty } from "../../helper/helper";
import ClockInOut from "../../pages/dashboard/clockInOut";
import Timer from "../../pages/dashboard/timer";
import { loginSelector } from "../../redux/features/auth/loginSlice";
import { userSelector } from "../../redux/features/auth/userSlice";
import { dashboardSelector } from "../../redux/features/dashboard/dashboardSlice";
import { headerSelector } from "../../redux/features/header/headerSlice";
import LogoutModal from "../logout/LogoutModal";

interface Props {
  children: React.ReactNode;
  removeChildren?: boolean;
}

const UserProfileMenu = ({ onUpdateProfile, onLogout }: any) => {
  return (
    <div className="user-profile-menu">
      <button onClick={onUpdateProfile}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-person-fill-gear"
          viewBox="0 0 18 18"
        >
          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4m9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382zM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
        </svg>
        &nbsp; Update Profile
      </button>
      <button onClick={onLogout}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          className="bi bi-box-arrow-right"
          viewBox="0 0 18 18"
        >
          <path
            fill-rule="evenodd"
            d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
          />
          <path
            fill-rule="evenodd"
            d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
          />
        </svg>
        &nbsp; Logout
      </button>
    </div>
  );
};

const SideBarWrapper = ({ children, removeChildren }: Props) => {
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);

  const handleUpdateProfile = () => {
    navigate("/profile");
  };

  const handleLogout = () => {
    setShowLogoutMenu(true);
  };

  const handleImageClick = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { isClockIn, isCurrentActivityLoading } =
    useSelector(dashboardSelector);
  const { type } = useSelector(loginSelector);
  const { user } = useSelector(userSelector);
  const { heading } = useSelector(headerSelector);

  const location = useLocation();

  const isAdmin = type === "admin";

  return (
    <>
      {removeChildren ? (
        children
      ) : (
        <>
          {/* topbar */}
          <main className="main-wrapper body-wrapper">
            <div className="topbar px-lg-30 position-fixed top-0 end-0 start-0 bg-white border-bottom d-flex flex-wrap align-items-center">
              <div className="position-relative d-flex w-100 align-items-center ">
                <div
                  id="navbarToggler"
                  className={
                    show
                      ? "navbar-toggler cIdeaTribes d-lg-none"
                      : "navbar-toggler d-lg-none"
                  }
                  onClick={() => {
                    setShow(!show);
                  }}
                >
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </div>
                <div className="d-flex flex-1 align-items-center ms-3 ms-lg-0">
                  <p className="fw-500">{heading}</p>
                </div>
                <div className="d-flex flex-wrap align-items-center font-semibold ms-auto">
                  {(location.pathname.includes("/dashboard") ||
                    location.pathname.includes("/summary")) &&
                    type !== "admin" &&
                    !isCurrentActivityLoading && (
                      <>
                        {!isEmpty(isClockIn) && <Timer />}
                        {location.pathname.includes("/dashboard") && (
                          <ClockInOut />
                        )}
                      </>
                    )}

                  <p className="me-3 d-none d-lg-block fw-500">
                    {isEmpty(user?.first_name) ? "" : user?.first_name}{" "}
                    {isEmpty(user?.last_name) ? "" : user?.last_name}
                  </p>

                  <img
                    src={
                      isEmpty(user?.profile_pic)
                        ? "../img/img-profile.webp"
                        : user?.profile_pic
                    }
                    alt="kuke"
                    title="kuke"
                    width={44}
                    height={44}
                    loading="eager"
                    style={{ borderRadius: "50%" }}
                    onClick={handleImageClick}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = key.defaultUserImage;
                    }}
                  />

                  {showMenu && (
                    <div ref={menuRef}>
                      <UserProfileMenu
                        onUpdateProfile={handleUpdateProfile}
                        onLogout={handleLogout}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* sidebar */}
            <div
              id="mySidebar"
              className={show ? "sidebar navShow" : "sidebar navHide"}
            >
              <div className="brand-logo ">
                <a className="full-logo" href="/">
                  <img
                    src="../img/logo-white-kuke.webp"
                    alt="kuke"
                    title="kuke"
                    width={107}
                    height={65}
                    loading="eager"
                  />
                </a>
              </div>
              <div className="menu d-flex flex-column">
                <ul className="ps-0 list-unstyled">
                  <li className="active">
                    <Link to={"/dashboard"}>
                      <div className="d-flex align-items-center">
                        <img
                          src="../img/svg/icon-dashboard-white.svg"
                          alt="dashboard"
                          title="dashboard"
                          width={24}
                          height={24}
                          loading="eager"
                          className="me-2"
                        />
                        <span
                          className={`nav-text ${
                            ["Dashboard"].includes(heading)
                              ? "fw-bold text-info"
                              : "text-white"
                          }`}
                        >
                          Dashboard
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to={"/summary"}>
                      <div className="d-flex align-items-center">
                        <img
                          src="../img/svg/icon-summary-white.svg"
                          alt="dashboard"
                          title="dashboard"
                          width={24}
                          height={24}
                          loading="eager"
                          className="me-2"
                        />
                        <span
                          className={`nav-text ${
                            ["Summary"].includes(heading)
                              ? "fw-bold text-info"
                              : "text-white"
                          }`}
                        >
                          Summary
                        </span>
                      </div>
                    </Link>
                  </li>

                    <li>
                      <Link to={"/clients"}>
                        <div className="d-flex align-items-center">
                          <img
                            src="../img/svg/icon-summary-white.svg"
                            alt="dashboard"
                            title="dashboard"
                            width={24}
                            height={24}
                            loading="eager"
                            className="me-2"
                          />
                          <span
                            className={`nav-text ${
                              ["Clients", "Client Details"].includes(heading)
                                ? "fw-bold text-info"
                                : "text-white"
                            }`}
                          >
                            Clients
                          </span>
                        </div>
                      </Link>
                    </li>

                  <li>
                    <Link to={"/project"}>
                      <div className="d-flex align-items-center">
                        <img
                          src="../img/svg/icon-project-white.svg"
                          alt="dashboard"
                          title="dashboard"
                          width={24}
                          height={24}
                          loading="eager"
                          className="me-2"
                        />
                        <span
                          className={`nav-text ${
                            ["Projects", "Project Details"].includes(heading)
                              ? "fw-bold text-info"
                              : "text-white"
                          }`}
                        >
                          Projects
                        </span>
                      </div>
                    </Link>
                  </li>

                  {isAdmin && (
                    <li>
                      <Link to={"/consultants"}>
                        <div className="d-flex align-items-center">
                          <img
                            src="../img/svg/icon-project-white.svg"
                            alt="dashboard"
                            title="dashboard"
                            width={24}
                            height={24}
                            loading="eager"
                            className="me-2"
                          />
                          <span
                            className={`nav-text ${
                              ["Consultants", "Consultant Details"].includes(
                                heading
                              )
                                ? "fw-bold text-info"
                                : "text-white"
                            }`}
                          >
                            Consultants
                          </span>
                        </div>
                      </Link>
                    </li>
                  )}

                  {isAdmin && (
                    <li>
                      <Link to={"/contractors"}>
                        <div className="d-flex align-items-center">
                          <img
                            src="../img/svg/icon-project-white.svg"
                            alt="dashboard"
                            title="dashboard"
                            width={24}
                            height={24}
                            loading="eager"
                            className="me-2"
                          />
                          <span
                            className={`nav-text ${
                              ["Contractors", "Contractor Details"].includes(
                                heading
                              )
                                ? "fw-bold text-info"
                                : "text-white"
                            }`}
                          >
                            Contractors
                          </span>
                        </div>
                      </Link>
                    </li>
                  )}

                  {isAdmin && (
                    <li>
                      <Link to={"/vendors"}>
                        <div className="d-flex align-items-center">
                          <img
                            src="../img/svg/icon-project-white.svg"
                            alt="dashboard"
                            title="dashboard"
                            width={24}
                            height={24}
                            loading="eager"
                            className="me-2"
                          />
                          <span
                            className={`nav-text ${
                              ["Vendors", "Vendor Details"].includes(heading)
                                ? "fw-bold text-info"
                                : "text-white"
                            }`}
                          >
                            Vendors
                          </span>
                        </div>
                      </Link>
                    </li>
                  )}

                  {isAdmin && (
                    <li>
                      <Link to={"/employees"}>
                        <div className="d-flex align-items-center">
                          <img
                            src="../img/svg/icon-project-white.svg"
                            alt="dashboard"
                            title="dashboard"
                            width={24}
                            height={24}
                            loading="eager"
                            className="me-2"
                          />
                          <span
                            className={`nav-text ${
                              ["Employees", "Employee Details"].includes(
                                heading
                              )
                                ? "fw-bold text-info"
                                : "text-white"
                            }`}
                          >
                            Employees
                          </span>
                        </div>
                      </Link>
                    </li>
                  )}

                    <li>
                      <Link to={"/activity-types"}>
                        <div className="d-flex align-items-center">
                          <img
                            src="../img/svg/icon-project-white.svg"
                            alt="dashboard"
                            title="dashboard"
                            width={24}
                            height={24}
                            loading="eager"
                            className="me-2"
                          />
                          <span
                            className={`nav-text ${
                              ["Activity Types"].includes(heading)
                                ? "fw-bold text-info"
                                : "text-white"
                            }`}
                          >
                            Activity Types
                          </span>
                        </div>
                      </Link>
                  </li>

                  <li>
                    <Link to={"/profile"}>
                      <div className="d-flex align-items-center">
                        <img
                          src="../img/svg/icon-user-white.svg"
                          alt="dashboard"
                          title="dashboard"
                          width={24}
                          height={24}
                          loading="eager"
                          className="me-2"
                        />
                        <span
                          className={`nav-text ${
                            ["Profile"].includes(heading)
                              ? "fw-bold text-info"
                              : "text-white"
                          }`}
                        >
                          Profile
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
                <div className="d-flex justify-content-start align-items-end ms-3 logout-bottom-position">
                  <div
                    onClick={() => {
                      setShowLogoutMenu(true);
                    }}
                    role="button"
                    className="d-flex align-items-center "
                  >
                    <img
                      src="../img/svg/icon-login-white.svg"
                      alt="dashboard"
                      title="dashboard"
                      width={24}
                      height={24}
                      loading="eager"
                      className="me-2"
                    />
                    <span
                      className={`nav-text ${
                        [""].includes(heading)
                          ? "fw-bold text-info"
                          : "text-white"
                      }`}
                    >
                      Logout
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setShow(false);
              }}
              className={show ? "overlay show" : "overlay"}
            ></div>

            {children}
          </main>
        </>
      )}
      <LogoutModal
        show={showLogoutMenu}
        onHide={() => {
          setShowLogoutMenu(false);
        }}
      />
    </>
  );
};

export default SideBarWrapper;
