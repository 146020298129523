import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError } from "../../../components/alerts/alerts";
import { dashboardApi } from "../../../config/apiUrls/apiUrl";
import { getRequest } from "../../../config/axois/apiRequest";
import { RootState } from "../../store";
import { isEmpty } from "../../../helper/helper";

const initialState = {
  isLoading: true,
  summary: [
    {
      start_date: "",
      end_date: "",
      weekly_total_duration: "",
      days: [
        {
          date: "",
          total_duration: "",
          day_total_amount: "",
          activities: [],
        },
      ],
    },
  ],
};

interface FetchType {
  project?: string | null;
  activity?: string | null;
  year?: string | null;
  month?: string | null;
  employee?: string | null;
  limit?: string;
  offset?: number;
  isDeleted?: boolean;
}

export const fetchSummary = createAsyncThunk(
  "summary/fetchSummary",
  async ({
    project,
    activity,
    month,
    year,
    employee,
    limit,
    offset,
    isDeleted,
  }: FetchType) => {
    try {
      let url = `${dashboardApi.fetchSummary}?project_id=${
        isEmpty(project) ? "" : project
      }&activity_type_id=${isEmpty(activity) ? "" : activity}`;

      if (!isEmpty(year)) {
        url += `&year=${year}`;
      }

      if (!isEmpty(month)) {
        url += `&month=${month}`;
      }

      if (!isEmpty(employee)) {
        url += `&user_id=${employee}`;
      }

      if (!isEmpty(limit)) {
        url += `&limit=${limit}`;
      }

      if (!isEmpty(offset)) {
        url += `&offset=${offset}`;
      }

      if (!isEmpty(isDeleted)) {
        url += `&with_trashed=true`;
      }

      const response = await getRequest(url);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      // fetch summary cases
      .addCase(fetchSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.summary = action?.payload?.data?.data ?? {};
      })
      .addCase(fetchSummary.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const summarySelector = (state: RootState) => state.summary;

const summaryReducer = summarySlice.reducer;

export default summaryReducer;
