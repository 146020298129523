import React from "react";

const NoActivity = () => {
  return (
    <>
      <section className="dashboard-clock">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center dashboard-clockin">
            <div className="col-md-6 text-center">
              <img
                src="../img/svg/icon-no-file-grey.svg"
                alt="activity"
                title="activity"
                width={96}
                height={96}
                loading="eager"
                className="mb-10"
              />
              <p className="mb-20 fs-mdlg fw-600">
                You haven't started any activity yet!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NoActivity;
