import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { login } from "./type";
import { validationSchema } from "./scheme";
import { isEmpty } from "../../helper/helper";

import ErrorMessage from "../../components/errorMessage/errorMessage";
import { loginSelector, onLogin } from "../../redux/features/auth/loginSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, isLoading } = useSelector(loginSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<login>({
    resolver: yupResolver(validationSchema),
  });

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (data: login) => {
    dispatch(onLogin(data));
  };

  useEffect(() => {
    if (!isEmpty(token)) {
      navigate("/dashboard");
    }
  }, [token]);

  return (
    <>
      <body>
        <section className="login p-0">
          <div className="container-fluid">
            <div className="row logfrm d-flex align-items-center justify-content-center ">
              <div className="col-md-8 col-lg-6 col-xl-6">
                <div className="text-center mb-30">
                  <a className="full-logo" href="/">
                    <img
                      src="../img/svg/logo-kuke-dark.svg"
                      alt="kuke"
                      title="kuke"
                      width={200}
                      height={121}
                      loading="eager"
                    />
                  </a>
                </div>

                <div className="login-column mx-auto">
                  <form className="row" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group col-md-12">
                      <p className="fs-mdlg fw-600">
                        Login to Manage Your Timesheet
                      </p>
                    </div>
                    <div className="form-group col-md-12 mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Phone Number OR Email"
                        {...register("phone_no")}
                      />

                      {errors?.phone_no && (
                        <ErrorMessage
                          message={errors.phone_no?.message ?? ""}
                        />
                      )}
                    </div>
                    <div className="form-group col-md-12 pw-hide mb-4">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        {...register("password")}
                        placeholder="Password"
                      />
                      <span
                        onClick={() => setShowPassword(!showPassword)}
                        className={`${
                          showPassword ? "show-password" : "hide-password"
                        } img-eye`}
                      ></span>
                      {errors?.password && (
                        <ErrorMessage
                          message={errors.password?.message ?? ""}
                        />
                      )}
                    </div>

                    <div className="d-grid">
                      <button
                        className="btn"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Please Wait" : "Login"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </>
  );
};

export default Login;
