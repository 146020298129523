import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomLoader from "../../components/loader/loader";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import { isEmpty } from "../../helper/helper";
import { AppDispatch } from "../../redux/store";
import ProjectsAdmin from "../project-admin/project-admin";
import {
  getVendorById,
  vendorsSelectors,
} from "../../redux/features/vendor/vendorSlice";
import { heading } from "../../redux/features/header/headerSlice";

const VendorDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const details = useSelector(vendorsSelectors.details);
  const detailsLoading = useSelector(vendorsSelectors.detailsLoading);

  const { vendorId } = location.state;

  useEffect(() => {
    if (!isEmpty(vendorId)) {
      dispatch(getVendorById(vendorId));
    }
  }, [vendorId]);

  useEffect(() => {
    dispatch(heading("Vendor Details"));
  }, []);

  return (
    <>
      <SideBarWrapper>
        {detailsLoading ? (
          <CustomLoader />
        ) : (
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  {/* Project Information */}
                  <div className="mb-30">
                    <div className="total-time-tracking">
                      <p className="fs-md fw-bold">Vendor Details</p>
                    </div>
                    <div className="row mx-0 flex-wrap fs-md bg-white time-tracking align-items-start">
                      <div className="col-md-2 pt-2 fw-500 ps-2">
                        <p>Vendor Name</p>
                      </div>
                      <div className="col-md-10 pt-2 ps-2">
                        {isEmpty(details?.name) ? "-" : details?.name}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2">
                        <p>Email</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.email) ? "-" : details?.email}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2">
                        <p>Phone No.</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.phone_no) ? "-" : details?.phone_no}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2">
                        <p>Type</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.type) ? "-" : details?.type}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2 pb-2">
                        <p>Note</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.note) ? "-" : details?.note}
                      </div>
                    </div>
                  </div>
                  {/* vendor list */}

                  <ProjectsAdmin isDetailsPage={true} />
                </div>
              </div>
            </div>
          </section>
        )}
      </SideBarWrapper>
    </>
  );
};

export default VendorDetails;
