import { requestHeaders } from "./header";
import instance from "./instance";

const postRequest = async (
  url: any,
  formData: any,
  authHeader: boolean = true,
  isMultiPart: boolean = false
) => {
  // direct return response

  const response = await instance.post(
    url,
    formData,
    requestHeaders(authHeader, isMultiPart)
  );

  return response;
};

const getRequest = async (url: any, authHeader: boolean = true) => {
  const response = await instance.get(url, requestHeaders(authHeader));

  return response;
};

const putRequest = async (
  url: any,
  formData: any,
  authHeader: boolean = true,
  isMultiPart: boolean = false
) => {
  const response = await instance.put(
    url,
    formData,
    requestHeaders(authHeader, isMultiPart)
  );

  return response;
};

const patchRequest = async (
  url: any,
  formData: any,
  authHeader: boolean = true,
  isMultiPart: boolean = false
) => {
  const response = await instance.patch(
    url,
    formData,
    requestHeaders(authHeader, isMultiPart)
  );

  return response;
};

const deleteRequest = async (url: any, authHeader: boolean = true) => {
  const response = await instance.delete(url, requestHeaders(authHeader));

  return response;
};

export { postRequest, getRequest, putRequest, patchRequest, deleteRequest };
