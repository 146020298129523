import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Client,
  createClient,
  fetchClients,
  getClientById,
  selectClients,
  selectClientsLoading,
  selectDetails,
  selectDetailsLoading,
  updateClient,
} from "../../redux/features/clients/clientSlice";
import { isDeleted, isEmpty } from "../../helper/helper";
import { useForm } from "react-hook-form";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import { AppDispatch } from "../../redux/store";
import CustomLoader from "../../components/loader/loader";
import CustomPaginate from "../../components/pagination/CustomPaginate";
import config from "../../config/config";
import { ClientType } from "./type";
import { validationSchema } from "./schema";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import { clientNotFound } from "../../messages/NotFoundMessages";
import useDebounce from "../../components/hooks/useDebounce";
import SortingIndicator from "../../components/sorting/SortingIndicator";
import { useNavigate } from "react-router-dom";
import PhoneComponent from "../../components/email-and-phone/PhoneComponent";
import {
  deleteModalSelector,
  handleDeleteModal,
} from "../../redux/features/deleteModal/deleteModalSlice";
import DeleteIcon from "../../components/delete/deleteIcon";
import { heading } from "../../redux/features/header/headerSlice";
import { loginSelector } from "../../redux/features/auth/loginSlice";

const Clients = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { reCallApi } = useSelector(deleteModalSelector);

  const clients = useSelector(selectClients);
  const details = useSelector(selectDetails);
  const loading = useSelector(selectClientsLoading);
  const detailsLoading = useSelector(selectDetailsLoading);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShowModal] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState<any>(null);
  const [selected, setSelected] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [limit, setLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [ordering, setOrdering] = useState<string>("name");
  const searchDebounce = useDebounce(search, 500);
  const { type } = useSelector(loginSelector);

  const isAdmin = type === "admin";

  const openModal = (clientId: any) => {
    setShowModal(true);
    setSelectedClientId(clientId);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedClientId(null);
    reset();
  };

  const handleSearch = (e: React.SyntheticEvent) => {
    setCurrentOffset(0);
    setSelected(0);
    setSearch((e.target as HTMLInputElement).value);
  };

  const handleSort = (value: string) => {
    setOrdering(ordering === value ? `-${value}` : value);
    setCurrentOffset(0);
    setSelected(0);
  };

  const handleNavigation = (clientId: number) => {
    navigate("/clients-details", { state: { clientId } });
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ClientType>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: ClientType) => {
    const newClient: Omit<Client, "id"> = {
      name: data.name,
      phone_no: data.phone_no,
      address: isEmpty(data.address) ? null : data.address,
    };

    if (isEmpty(selectedClientId)) {
      setSubmitting(true);

      await dispatch(createClient(newClient));
      await fetchClientRecords();
      closeModal();
      setSubmitting(false);
    } else {
      setSubmitting(true);

      await dispatch(
        updateClient({
          id: selectedClientId,
          ...newClient,
        })
      );
      await fetchClientRecords();
      closeModal();
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchClientRecords();
  }, [currentOffset, selected, searchDebounce, ordering]);

  useEffect(() => {
    if (reCallApi) fetchClientRecords();
  }, [reCallApi]);

  const fetchClientRecords = async () => {
    const response = await dispatch(
      fetchClients({ currentOffset, limit, search, ordering, isDeleted: true })
    );

    setCount(response?.payload?.data?.meta?.count ?? 0);
  };

  useEffect(() => {
    if (!isEmpty(selectedClientId)) {
      dispatch(getClientById(selectedClientId));
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (!isEmpty(selectedClientId) && details) {
      setValue("address", details?.address ?? null);
      setValue("name", details?.name ?? "");
      setValue("phone_no", details?.phone_no ?? "");
    }
  }, [details]);

  useEffect(() => {
    dispatch(heading("Clients"));
  }, []);

  return (
    <SideBarWrapper>
      <div className="card" style={{ marginBottom: "-40px" }}>
        <div className="card-header border-0 py-2 px-7 align-items-center">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div className="mb-res" style={{ marginTop: "15px" }}>
              <input
                autoFocus
                name="text"
                type="text"
                autoComplete="text"
                placeholder="Search"
                value={search}
                onChange={(e) => handleSearch(e)}
                className="form-control form-search"
                disabled={loading}
              />
            </div>
            <div
              className="ms-lg-auto"
              style={{
                float: "right",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="card-toolbar d-flex my-0 mt-1 mb-1">
                <button
                  type="button"
                  className="btn"
                  onClick={() => openModal(null)}
                  disabled={loading}
                >
                  Add Client
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            {isEmpty(clients) ? (
              <NoRecordFound message={clientNotFound} />
            ) : (
              <div className="card-body pt-0 pb-3">
                <div className="table-responsive">
                  <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                    <thead>
                      <tr className="text-muted fw-500">
                        <SortingIndicator
                          field="Name"
                          onClick={handleSort}
                          column="name"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Phone Number"
                          onClick={handleSort}
                          column="phone_no"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Address"
                          onClick={handleSort}
                          column="address"
                          sortingColumn={ordering}
                        />
                        <th
                          className="w-10px"
                          style={{ display: "flex", justifyContent: "right" }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients &&
                        clients.map((client) => (
                          <tr
                            style={{
                              cursor: "pointer",
                            }}
                            key={client.id}
                          >
                            <td
                              onClick={() =>
                                handleNavigation(Number(client.id))
                              }
                            >
                              {isEmpty(client.name) ? "-" : client.name}
                            </td>
                            <td>
                              <PhoneComponent phone={client.phone_no} />
                            </td>
                            <td
                              onClick={() =>
                                handleNavigation(Number(client.id))
                              }
                            >
                              {isEmpty(client.address) ? "-" : client.address}
                            </td>
                            <td className="w-10px">
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "right",
                                }}
                              >
                                {isDeleted(
                                  client.deleted_at,
                                  <>
                                    <div
                                      style={{ paddingRight: "10px" }}
                                      onClick={() => openModal(client.id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                      </svg>
                                    </div>

                                    {
                                      isAdmin &&
                                      <div>
                                        <DeleteIcon
                                          url={`/clients/delete/${client.id}`}
                                          tag="client"
                                        />
                                      </div>
                                    }
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!isEmpty(clients) && !loading && (
        <CustomPaginate
          data={clients}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          setLimit={setLimit}
          isDisable={loading}
        />
      )}

      <Modal
        show={show}
        onHide={() => {
          closeModal();
        }}
        backdrop="static"
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEmpty(selectedClientId) ? "Add" : "Update"} Client
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {detailsLoading ? (
              <CustomLoader />
            ) : (
              <>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    placeholder="Enter Name"
                    disabled={submitting}
                    type="text"
                    {...register("name")}
                    isInvalid={!!errors.name?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="phone_no">
                  <Form.Label style={{ marginTop: "15px" }}>
                    Phone Number
                  </Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    placeholder="Enter Phone Number"
                    disabled={submitting}
                    type="text"
                    {...register("phone_no")}
                    isInvalid={!!errors.phone_no?.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone_no?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="address">
                  <Form.Label style={{ marginTop: "15px" }}>Address</Form.Label>

                  <Form.Control
                    maxLength={200}
                    placeholder="Enter Address"
                    disabled={submitting}
                    {...register("address")}
                    type="text"
                    // isInvalid={!!errors.address?.message}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.address?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          {!detailsLoading && (
            <Modal.Footer>
              <div className="w-100 d-flex justify-content-center">
                <button
                  type="button"
                  onClick={() => closeModal()}
                  disabled={submitting}
                  className="cancel-button-background me-4 btn"
                >
                  Cancel
                </button>

                <button className="btn" type="submit" disabled={submitting}>
                  {submitting
                    ? "Please Wait"
                    : isEmpty(selectedClientId)
                    ? "Submit"
                    : "Update"}
                </button>
              </div>
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    </SideBarWrapper>
  );
};

export default Clients;
