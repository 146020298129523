import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../../components/loader/loader";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import CustomPaginate from "../../components/pagination/CustomPaginate";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import config from "../../config/config";
import { isDeleted, isEmpty } from "../../helper/helper";
import { vendorNotFound } from "../../messages/NotFoundMessages";
import {
  Vendor,
  createVendor,
  fetchVendors,
  updateVendor,
  vendorsSelectors,
} from "../../redux/features/vendor/vendorSlice";
import { AppDispatch } from "../../redux/store";
import VendorModal from "./VendorModal";
import { VendorType } from "./type";
import useDebounce from "../../components/hooks/useDebounce";
import SortingIndicator from "../../components/sorting/SortingIndicator";
import PhoneComponent from "../../components/email-and-phone/PhoneComponent";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../components/delete/deleteIcon";
import { deleteModalSelector } from "../../redux/features/deleteModal/deleteModalSlice";
import { heading } from "../../redux/features/header/headerSlice";

const Vendors = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { reCallApi } = useSelector(deleteModalSelector);

  const vendors = useSelector(vendorsSelectors.vendors);
  const loading = useSelector(vendorsSelectors.loading);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShowModal] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState<any>(null);
  const [selected, setSelected] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [limit, setLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [ordering, setOrdering] = useState<string>("name");
  const searchDebounce = useDebounce(search, 500);

  const openModal = (clientId: any) => {
    setSelectedVendorId(clientId);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedVendorId(null);
  };

  const handleSearch = (e: React.SyntheticEvent) => {
    setCurrentOffset(0);
    setSelected(0);
    setSearch((e.target as HTMLInputElement).value);
  };

  const handleSort = (value: string) => {
    setOrdering(ordering === value ? `-${value}` : value);
    setCurrentOffset(0);
    setSelected(0);
  };

  const handleNavigation = (vendorId: number) => {
    navigate("/vendors-details", { state: { vendorId } });
  };

  const onSubmit = async (data: VendorType) => {
    if (isEmpty(selectedVendorId)) {
      setSubmitting(true);

      const newVendor: Omit<Vendor, "id"> = {
        name: data.name,
        phone_no: data.phone_no,
        type: data.type,
        email: data.email,
        note: data.note
      };

      const response = await dispatch(createVendor(newVendor));

      if ([200, 201].includes(response?.payload?.status)) {
        await fetchVendorsRecords();
        closeModal();
        setSelectedVendorId(null);
      }

      setSubmitting(false);
    } else {
      setSubmitting(true);

      const response = await dispatch(
        updateVendor({
          id: selectedVendorId,
          ...data,
        })
      );

      if ([200, 201].includes(response?.payload?.status)) {
        await fetchVendorsRecords();
        closeModal();
        setSelectedVendorId(null);
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchVendorsRecords();
  }, [currentOffset, selected, searchDebounce, ordering]);

  useEffect(() => {
    if (reCallApi) fetchVendorsRecords();
  }, [reCallApi]);

  useEffect(() => {
    dispatch(heading("Vendors"));
  }, []);

  const fetchVendorsRecords = async () => {
    const response = await dispatch(
      fetchVendors({ currentOffset, limit, search, ordering, isDeleted: true })
    );
    setCount(response?.payload?.data?.meta?.count ?? 0);
  };

  return (
    <SideBarWrapper>
      <div className="card" style={{ marginBottom: "-40px" }}>
        <div className="card-header border-0 py-2 px-7 align-items-center">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div className="mb-res" style={{ marginTop: "15px" }}>
              <input
                autoFocus
                name="text"
                type="text"
                autoComplete="text"
                placeholder="Search"
                value={search}
                onChange={(e) => handleSearch(e)}
                className="form-control form-search"
                disabled={loading}
              />
            </div>
            <div
              className="ms-lg-auto"
              style={{
                float: "right",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="card-toolbar d-flex my-0 mt-1 mb-1">
                <button
                  type="button"
                  className="btn"
                  onClick={() => openModal(null)}
                  disabled={loading}
                >
                  Add Vendor
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            {isEmpty(vendors) ? (
              <NoRecordFound message={vendorNotFound} />
            ) : (
              <div className="card-body pt-0 pb-3">
                <div className="table-responsive">
                  <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                    <thead>
                      <tr className="text-muted fw-500">
                        <SortingIndicator
                          field="Name"
                          onClick={handleSort}
                          column="name"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Type"
                          onClick={handleSort}
                          column="type"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Email"
                          onClick={handleSort}
                          column="email"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Phone Number"
                          onClick={handleSort}
                          column="phone_no"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Created By"
                          onClick={handleSort}
                          column="created_by"
                          sortingColumn={ordering}
                        />
                        <th className="w-10px" style={{ textAlign: "right" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {vendors &&
                        vendors.map((vendor) => (
                          <tr
                            key={vendor.id}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <td
                              onClick={() =>
                                handleNavigation(Number(vendor.id))
                              }
                            >
                              {isEmpty(vendor.name) ? "-" : vendor.name}
                            </td>
                            <td
                              onClick={() =>
                                handleNavigation(Number(vendor.id))
                              }
                            >
                              {isEmpty(vendor.type) ? "-" : vendor.type}
                            </td>
                            <td
                              onClick={() =>
                                handleNavigation(Number(vendor.id))
                              }
                            >
                              {isEmpty(vendor.email) ? "-" : vendor.email}
                            </td>
                            <td>
                              <PhoneComponent phone={vendor.phone_no} />
                            </td>
                            <td
                              onClick={() =>
                                handleNavigation(Number(vendor.id))
                              }
                            >
                              {isEmpty(vendor.created_by)
                                ? "-"
                                : vendor.created_by}
                            </td>
                            <td
                              className="w-10px"
                              style={{ textAlign: "right", cursor: "pointer" }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "right",
                                }}
                              >
                                {isDeleted(
                                  vendor.deleted_at,
                                  <>
                                    <div
                                      style={{ paddingRight: "10px" }}
                                      onClick={() => openModal(vendor.id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                      </svg>
                                    </div>

                                    <div>
                                      <DeleteIcon
                                        url={`/vendors/delete/${vendor.id}`}
                                        tag="vendor"
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {!isEmpty(vendors) && !loading && (
        <CustomPaginate
          data={vendors}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          setLimit={setLimit}
          isDisable={loading}
        />
      )}

      <VendorModal
        show={show}
        closeModal={closeModal}
        selectedId={selectedVendorId}
        submitForm={onSubmit}
        submitting={submitting}
      />
    </SideBarWrapper>
  );
};

export default Vendors;
