import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import ClockIn from "./clockIn";
import Activity from "./activitytable";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSelector } from "../../redux/features/dashboard/dashboardSlice";
import { isEmpty } from "../../helper/helper";
import CustomLoader from "../../components/loader/loader";
import { useEffect } from "react";
import { heading } from "../../redux/features/header/headerSlice";
import { AppDispatch } from "../../redux/store";

const Dashboard = () => {
  const { isClockIn, isCurrentActivityLoading } =
    useSelector(dashboardSelector);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(heading("Dashboard"));
  }, []);

  return (
    <>
      <SideBarWrapper>
        {!isEmpty(isClockIn) && isCurrentActivityLoading ? (
          <CustomLoader />
        ) : (
          <Activity />
        )}
      </SideBarWrapper>
    </>
  );
};

export default Dashboard;
