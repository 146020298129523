import React from "react";

interface SortingIndicatorProps {
  column: string;
  sortingColumn: string;
  onClick: any;
  field: string;
  style?: any;
  hideShorting?: boolean;
}

const SortingIndicator = ({
  column,
  sortingColumn,
  onClick,
  field,
  style,
  hideShorting,
}: SortingIndicatorProps) => {
  return (
    <>
      {hideShorting ? (
        <th>{field}</th>
      ) : (
        <th
          style={{ cursor: "pointer", ...style }}
          onClick={() => onClick(column)}
        >
          {field}

          <span>{column === sortingColumn ? "↑" : "↓"}</span>
        </th>
      )}
    </>
  );
};

export default SortingIndicator;
