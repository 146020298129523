import { NotFoundMessage } from "../../messages/NotFoundMessages";

interface NoRecordFoundProps {
  message?: string;
}

const NoRecordFound: React.FC<NoRecordFoundProps> = ({
  message = NotFoundMessage,
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          margin: "10px",
        }}
      >
        <img
          src="/no_records_found.webp"
          height={100}
          width={100}
          alt="No Record Found"
        />
      </div>

      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          margin: "10px",
        }}
      >
        {message}
      </p>
    </div>
  );
};

export default NoRecordFound;
