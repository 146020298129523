import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteModalSelector,
  handleDeleteModal,
  reCallApi,
} from "../../redux/features/deleteModal/deleteModalSlice";
import { AppDispatch } from "../../redux/store";
import { deleteRequest } from "../../config/axois/apiRequest";
import { showError, showSuccess } from "../alerts/alerts";
import { useEffect, useState } from "react";
import { isEmpty } from "../../helper/helper";

const DeleteModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { isModal, url, tag } = useSelector(deleteModalSelector);

  const [loading, setLoading] = useState(false);

  const onHide = () => {
    dispatch(
      handleDeleteModal({
        url: "",
        isModal: false,
      })
    );
  };

  const onDelete = async () => {
    try {
      setLoading(true);

      const response = await deleteRequest(url);

      if ([200, 201].includes(response?.status)) {
        onHide();

        showSuccess("Delete Successfully.");

        dispatch(reCallApi(true));

        setTimeout(() => {
          dispatch(reCallApi(false));
        }, 100);

        setLoading(false);
      }
    } catch (error) {
      showError("Something Went Wrong");
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={isModal}
        onHide={onHide}
        backdrop="static"
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="py-3">
            Are you sure you want to delete {tag ? `this ${tag}?` : "?"}{" "}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 d-flex justify-content-evenly">
            <button
              className="btn cancel-button-background"
              type="button"
              onClick={onHide}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              className="btn"
              type="button"
              onClick={onDelete}
              disabled={loading}
            >
              {loading ? "Please Wait" : "Delete"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
