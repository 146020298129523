import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomLoader from "../../components/loader/loader";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import { isEmpty } from "../../helper/helper";
import {
  assignProjects,
  employeeSelectors,
  getEmployeeById,
} from "../../redux/features/employee/employeeSlice";
import { AppDispatch } from "../../redux/store";
import ProjectsAdmin from "../project-admin/project-admin";
import AssignProjectsModal from "./AssignProjectsModal";
import { showError } from "../../components/alerts/alerts";
import { heading } from "../../redux/features/header/headerSlice";

const EmployeeDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const details = useSelector(employeeSelectors.details);
  const detailsLoading = useSelector(employeeSelectors.detailsLoading);

  const { employeeId } = location.state;
  const [assignModal, setAssignModal] = useState(false);
  const [selectedProjectsIds, setSelectedProjectsIds] = useState<any[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(employeeId)) {
      dispatch(getEmployeeById(employeeId));
    }
  }, [employeeId]);

  const closeAssignProjectsModal = () => {
    setAssignModal(false);
    setSelectedProjectsIds([]);
  };

  const assignEmployeeProject = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);

    if (isEmpty(selectedProjectsIds)) {
      showError("Please select at least one project");
      setSubmitting(false);
    } else {
      const allProjectIds = selectedProjectsIds.map((data) => data.value);

      await dispatch(
        assignProjects({
          id: employeeId,
          project_id: allProjectIds,
        })
      );

      closeAssignProjectsModal();
      setSubmitting(false);
      dispatch(getEmployeeById(employeeId));
    }
  };

  useEffect(() => {
    dispatch(heading("Employee Details"));
  }, []);

  return (
    <>
      <SideBarWrapper>
        {detailsLoading ? (
          <CustomLoader />
        ) : (
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  {/* Project Information */}
                  <div className="mb-30">
                    <div className="total-time-tracking">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p className="fs-md fw-bold">Employee Details</p>
                        <button
                          className="me-4 btn fw-bold"
                          type="submit"
                          onClick={() => setAssignModal(true)}
                        >
                          <span>Assign Projects</span>
                        </button>
                      </div>
                    </div>
                    <div className="row mx-0 flex-wrap fs-md bg-white time-tracking align-items-start">
                      <div className="col-md-2 pt-2 fw-500 ps-2">
                        <p>Employee Name</p>
                      </div>
                      <div className="col-md-10 pt-2 ps-2">
                        {isEmpty(details?.first_name)
                          ? ""
                          : details?.first_name}{" "}
                        {isEmpty(details?.last_name) ? "" : details?.last_name}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2">
                        <p>Email</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.email) ? "-" : details?.email}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2">
                        <p>Phone Number</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.phone_no) ? "-" : details?.phone_no}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2 pb-2">
                        <p>Pay Per Hour</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.pay_per_hour)
                          ? "-"
                          : details?.pay_per_hour}
                      </div>
                    </div>
                  </div>
                  {/* vendor list */}

                  <ProjectsAdmin
                    isDetailsPage={true}
                    isEmployeeDetailPage={true}
                  />
                  <AssignProjectsModal
                    show={assignModal}
                    onHide={closeAssignProjectsModal}
                    selectedProjectsIds={selectedProjectsIds}
                    setSelectedProjectsIds={setSelectedProjectsIds}
                    submitting={submitting}
                    assignEmployeeProject={assignEmployeeProject}
                    employeeId={employeeId}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
      </SideBarWrapper>
    </>
  );
};

export default EmployeeDetails;
