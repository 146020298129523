import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Name is required")
    .max(30, "Name must be at most 30 characters long")
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Digits are not allowed"),
  phone_no: yup
    .string()
    .trim()
    .required("Phone Number is required")
    .test("is-phone", "Invalid Phone Number", function (value) {
      if (!value) return false; // If no value, fail the validation
      // Validate value as phone number
      return /^[0-9]{10}$/.test(value);
    }),
  // address: yup.string().trim().required("Address is required"),
});

export { validationSchema };
