import * as yup from "yup";

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .max(30, "First name must be at most 30 characters long")
    .matches(
      /^[A-Za-z]+$/,
      "First name must only contain alphabetic characters with no spaces"
    ),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .max(30, "Last name must be at most 30 characters long")
    .matches(
      /^[A-Za-z]+$/,
      "Last name must only contain alphabetic characters with no spaces"
    ),
  phone_no: yup
    .string()
    .trim()
    .required("Phone Number is required")
    .test("is-phone", "Invalid Phone Number", function (value) {
      if (!value) return false; // If no value, fail the validation
      // Validate value as phone number
      return /^[0-9]{10}$/.test(value);
    }),
  email: yup
    .string()
    .trim()
    .email("Invalid Email Format")
    .required("Email Is Required"),
  pay_per_hour: yup
    .mixed()
    .test(
      "valid-number-or-null",
      "Pay per hour must be a valid number or null",
      (value: any) => {
        if (value === null || value === undefined || value === "") return true; // Allow null, undefined, or empty string
        if (isNaN(Number(value))) return false; // Reject if not a number
        if (!Number.isInteger(Number(value))) return false; // Reject if not an integer
        return true;
      }
    )
    .test(
      "max-digits",
      "Pay per hour must be at most 10 digits long",
      (value: any) => {
        if (value === null || value === undefined || value === "") return true; // Allow null, undefined, or empty string
        const stringValue = String(value);
        return stringValue.length <= 10;
      }
    ),
});

export { validationSchema };
