import * as yup from "yup";

const validationSchema = yup.object().shape({
  name: yup.string().trim().required("Name is required"),
  client: yup.mixed().test("is-required", "Client is required", (value) => {
    return value !== null && value !== undefined && value !== 0;
  }),
  budget: yup
    .mixed()
    .test(
      "valid-number-or-null",
      "Budget must be a valid number or null",
      (value: any) => {
        if (value === null || value === undefined || value === "") return true; // Allow null, undefined, or empty string
        if (isNaN(value)) return false; // Reject if not a number
        return true;
      }
    )
    .test("max-digits", "Budget must be at most 15 digits long", (value) => {
      if (value === null || value === undefined || value === "") return true; // Allow null, undefined, or empty string
      const stringValue = String(value).replace(".", ""); // Remove decimal point if exists
      return stringValue.length <= 15;
    }),
  // address: yup.string().trim().required("Address is required"),
});

export { validationSchema };
