import React, { useEffect } from "react";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import { KukeStatisticsWidget } from "../../components/staticWidget/KukeStatisticsWidget";
import { useDispatch, useSelector } from "react-redux";
import {
  adminDashboardSelector,
  fetchAdminDashboardTotal,
  fetchMostActiveUsers,
  fetchMostUsedActivity,
  fetchProjectsSpentHours,
  fetchTopEmpMaxHours,
  fetchTopEmpMinHours,
} from "../../redux/features/adminDashboard/adminDashboardSlice";
import { AppDispatch } from "../../redux/store";
import { isEmpty } from "../../helper/helper";
import CustomLoader from "../../components/loader/loader";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import { useNavigate } from "react-router-dom";
import { heading } from "../../redux/features/header/headerSlice";

const AdminDashboard = () => {
  const {
    activeUsers,
    activeUsersLoading,
    mostUsedActivity,
    mostUsedActivityLoading,
    projectSpentHours,
    projectSpentHoursLoading,
    topEmpMaxHours,
    topEmpMaxHoursLoading,
    topEmpMinHours,
    topEmpMinHoursLoading,
    adminDashboardTotal,
    adminDashboardTotalLoading,
  } = useSelector(adminDashboardSelector);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAdminDashboardTotal());
    dispatch(fetchProjectsSpentHours());
    dispatch(fetchMostUsedActivity());
    dispatch(fetchMostActiveUsers());
    dispatch(fetchTopEmpMaxHours());
    dispatch(fetchTopEmpMinHours());
    dispatch(heading("Dashboard"));
  }, []);

  return (
    <SideBarWrapper>
      <div
        style={
          {
            // marginRight: "20px",
            // marginLeft: "20px",
          }
        }
      >
        <div className="row g-10 g-xl-20">
          <div className="col-sm-4 col-md-4 col-lg col-xl mb-res-20">
            <KukeStatisticsWidget
              className="card-xl-stretch mb-xl-4 rounded-2"
              color="#E1F8DC"
              title={
                isEmpty(adminDashboardTotal?.total_employees)
                  ? "0"
                  : adminDashboardTotal?.total_employees.toString() ?? "0"
              }
              description={`Total Employees`}
              label="Lifetime"
            />
          </div>
          <div className="col-sm-4 col-md-4 col-lg col-xl mb-res-20">
            <KukeStatisticsWidget
              className="card-xl-stretch mb-xl-4 rounded-2"
              color="#FEF8DD"
              title={
                isEmpty(adminDashboardTotal?.total_projects)
                  ? "0"
                  : adminDashboardTotal?.total_projects.toString() ?? "0"
              }
              description={`Total Projects`}
              label="Lifetime"
            />
          </div>
          <div className="col-sm-4 col-md-4 col-lg col-xl mb-res-20">
            <KukeStatisticsWidget
              className="card-xl-stretch mb-xl-4 rounded-2"
              color="#CAF1DE"
              title={
                isEmpty(adminDashboardTotal?.total_vendors)
                  ? "0"
                  : adminDashboardTotal?.total_vendors.toString() ?? "0"
              }
              description={`Total Vendors`}
              label="Lifetime"
            />
          </div>
        </div>
        <div className="row g-10 g-xl-20">
          <div className="col-sm-4 col-md-4 col-lg col-xl mb-res-20">
            <KukeStatisticsWidget
              className="card-xl-stretch mb-xl-4 rounded-2"
              color="#FFE7C7"
              title={
                isEmpty(adminDashboardTotal?.total_activity_types)
                  ? "0"
                  : adminDashboardTotal?.total_activity_types.toString() ?? "0"
              }
              description={`Total Activity Types`}
              label="Lifetime"
            />
          </div>
          <div className="col-sm-4 col-md-4 col-lg col-xl mb-res-20">
            <KukeStatisticsWidget
              className="card-xl-stretch mb-xl-4 rounded-2"
              color="#9AC8CD"
              title={
                isEmpty(adminDashboardTotal?.total_consultants)
                  ? "0"
                  : adminDashboardTotal?.total_consultants.toString() ?? "0"
              }
              description={`Total Consultants`}
              label="Lifetime"
            />
          </div>
          <div className="col-sm-4 col-md-4 col-lg col-xl mb-res-20">
            <KukeStatisticsWidget
              className="card-xl-stretch mb-xl-4 rounded-2"
              color="#FFE7C7"
              title={
                isEmpty(adminDashboardTotal?.total_contractor)
                  ? "0"
                  : adminDashboardTotal?.total_contractor.toString() ?? "0"
              }
              description={`Total Contractors`}
              label="Lifetime"
            />
          </div>
        </div>
      </div>
      <div
        className="card w-100"
        style={{
          // marginLeft: "10px",
          // marginRight: "20px",
          marginBottom: "20px",
          padding: "20px",
        }}
      >
        <h5 style={{ margin: "15px" }}>Yesterday's Activity</h5>
        {activeUsersLoading ? (
          <CustomLoader />
        ) : (
          <>
            {isEmpty(activeUsers) ? (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <NoRecordFound />
              </div>
            ) : (
              <div
                className="card-body pt-0 pb-3"
                style={{ maxHeight: "500px", overflowY: "auto" }}
              >
                <div className="table-responsive">
                  <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                    <thead>
                      <tr className="text-muted fw-500">
                        <th style={{ cursor: "pointer" }}>Name</th>
                        <th style={{ cursor: "pointer" }}>
                          Total Working Duration
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeUsers &&
                        activeUsers.map((data) => (
                          <tr key={data.id}>
                            <td>
                              {isEmpty(data.full_name) ? "" : data.full_name}
                            </td>
                            <td>
                              {isEmpty(data.total_working_duration)
                                ? ""
                                : data.total_working_duration}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="card w-100"
          style={{
            marginBottom: "20px",
            // marginLeft: "20px",
            // marginRight: "20px",
            padding: "20px",
          }}
        >
          <h5 style={{ margin: "15px" }}>Total Project Spent Hours</h5>
          {projectSpentHoursLoading ? (
            <CustomLoader />
          ) : (
            <>
              {isEmpty(projectSpentHours) ? (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <NoRecordFound />
                </div>
              ) : (
                <div
                  className="card-body pt-0 pb-3"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <div className="table-responsive">
                    <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                      <thead>
                        <tr className="text-muted fw-500">
                          <th style={{ cursor: "pointer" }}>Name</th>
                          <th>Total Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {projectSpentHours &&
                          projectSpentHours.map((data) => (
                            <tr key={data.id}>
                              <td>{isEmpty(data.name) ? "" : data.name}</td>
                              <td>
                                {isEmpty(data.total_hours)
                                  ? "-"
                                  : data.total_hours}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div
          className="card w-100"
          style={{
            marginLeft: "20px",
            //marginRight: "10px",
            marginBottom: "20px",
            padding: "20px",
          }}
        >
          <h5 style={{ margin: "15px" }}>Most Used Activity Type</h5>
          {mostUsedActivityLoading ? (
            <CustomLoader />
          ) : (
            <>
              {isEmpty(mostUsedActivity) ? (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <NoRecordFound />
                </div>
              ) : (
                <div
                  className="card-body pt-0 pb-3"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <div className="table-responsive">
                    <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                      <thead>
                        <tr className="text-muted fw-500">
                          <th style={{ cursor: "pointer" }}>Name</th>
                          <th style={{ cursor: "pointer" }}>Activity Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mostUsedActivity &&
                          mostUsedActivity.map((data) => (
                            <tr key={data.id}>
                              <td>{isEmpty(data.name) ? "" : data.name}</td>
                              <td>
                                {isEmpty(data.activity_count)
                                  ? ""
                                  : data.activity_count}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="card"
          style={{
            // marginLeft: "20px",
            // marginRight: "20px",
            marginBottom: "20px",
            padding: "20px",
            width: "100%",
          }}
        >
          <div className="d-flex flex-lg-nowrap flex-wrap align-items-center justify-content-between">
            <h5 style={{ margin: "15px" }}>
              Top 5 Employees Maximum Spent Hours
            </h5>

            <button
              type="button"
              className="btn me-3 ms-lg-0 ms-3"
              onClick={() => {
                navigate("/employees-hours", {
                  state: {
                    adminDashboardOrdering: "-total_duration",
                  },
                });
              }}
            >
              View All
            </button>
          </div>
          {topEmpMaxHoursLoading ? (
            <CustomLoader />
          ) : (
            <>
              {isEmpty(topEmpMaxHours) ? (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <NoRecordFound />
                </div>
              ) : (
                <div
                  className="card-body pt-0 pb-3"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <div className="table-responsive">
                    <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                      <thead>
                        <tr className="text-muted fw-500">
                          <th style={{ cursor: "pointer" }}>Name</th>
                          <th style={{ cursor: "pointer" }}>Duration</th>
                          <th style={{ cursor: "pointer" }}>Total Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topEmpMaxHours &&
                          topEmpMaxHours.map((data) => (
                            <tr key={data.id}>
                              <td>
                                {isEmpty(data.full_name) ? "-" : data.full_name}
                              </td>
                              <td>
                                {isEmpty(data.total_duration)
                                  ? "-"
                                  : data.total_duration}
                              </td>
                              <td>
                                {isEmpty(data.total_amount)
                                  ? "-"
                                  : data.total_amount}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div
          className="card"
          style={{
            marginLeft: "20px",
            paddingLeft: "20px",
            marginBottom: "20px",
            width: "100%",
            padding: "20px",
          }}
        >
          <div className="d-flex flex-lg-nowrap flex-wrap align-items-center justify-content-between">
            <h5 style={{ margin: "15px" }}>
              Top 5 Employees Minimum Spent Hours
            </h5>

            <button
              type="button"
              className="btn me-3 ms-lg-0 ms-3"
              onClick={() => {
                navigate("/employees-hours", {
                  state: {
                    adminDashboardOrdering: "total_duration",
                  },
                });
              }}
            >
              View All
            </button>
          </div>

          {topEmpMinHoursLoading ? (
            <CustomLoader />
          ) : (
            <>
              {isEmpty(topEmpMinHours) ? (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <NoRecordFound />
                </div>
              ) : (
                <div
                  className="card-body pt-0 pb-3"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  <div className="table-responsive">
                    <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                      <thead>
                        <tr className="text-muted fw-500">
                          <th style={{ cursor: "pointer" }}>Name</th>
                          <th style={{ cursor: "pointer" }}>Duration</th>
                          <th style={{ cursor: "pointer" }}>Total Cost</th>
                        </tr>
                      </thead>
                      <tbody>
                        {topEmpMinHours &&
                          topEmpMinHours.map((data) => (
                            <tr key={data.id}>
                              <td>
                                {isEmpty(data.full_name) ? "-" : data.full_name}
                              </td>
                              <td>
                                {isEmpty(data.total_duration)
                                  ? "-"
                                  : data.total_duration}
                              </td>
                              <td>
                                {isEmpty(data.total_amount)
                                  ? "-"
                                  : data.total_amount}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {/* <div className="text-center">
        <h1>Admin Dashboard is coming soon</h1>
      </div> */}
    </SideBarWrapper>
  );
};

export default AdminDashboard;
