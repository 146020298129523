import * as yup from "yup";

const activitiesValidationSchema = yup.object().shape({
  project_id: yup
    .object()
    .shape({
      label: yup.string().trim().required("Project Is Required"),
      value: yup.string().trim().required("Project Is Required"),
    })
    .test("object-keys-length", "Project Is Required", (value) => {
      const numberOfKeys = Object.keys(value).length;
      return numberOfKeys === 2; // Ensure the object has exactly 2 keys
    }),
  activity_type_id: yup
    .object()
    .shape({
      label: yup.string().trim().required("Activity Type Label Is Required"),
      value: yup.string().trim().required("Activity Type Value Is Required"),
    })
    .test("object-keys-length", "Activity Is Required", (value) => {
      const numberOfKeys = Object.keys(value).length;
      return numberOfKeys === 2; // Ensure the object has exactly 2 keys
    }),
  start_date_time: yup
    .string()
    .trim()
    .required("Start Time Is Required")
    .test(
      "start-time",
      "Start time cannot be greater than current time",
      function (value) {
        if (!value) return true; // Handle empty value
        const startTime = new Date(value);
        const currentTime = new Date();
        return startTime <= currentTime; // Modified comparison here
      }
    ),
  end_date_time: yup
    .string()
    .trim()
    .test(
      "end-time",
      "End time cannot be less than start time",
      function (value) {
        if (!value) return true; // Handle empty value
        const endTime = new Date(value);
        const startTime = new Date(this.parent.start_date_time); // Reference to start_date_time
        return endTime >= startTime;
      }
    ),
  description: yup.string().trim().required("Description Is Required"),
});

export { activitiesValidationSchema };
