import * as yup from "yup";

const validationSchema = yup.object().shape({
  full_name: yup
    .string()
    .trim()
    .required("Full Name is required")
    .test("word-count", "Invalid Full Name", (value) => {
      if (!value) return false;
      const words = value.trim().split(/\s+/);
      return words.length === 2;
    }),
  phone_no: yup
    .string()
    .trim()
    .required("Phone Number is required")
    .matches(/^\d{10}$/, "Phone Number must be 10 digits"),
});

export { validationSchema };
