import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../../components/alerts/alerts";
import { auth } from "../../../config/apiUrls/apiUrl";
import { getRequest, patchRequest } from "../../../config/axois/apiRequest";
import { OK } from "../../../config/axois/apiStatusCode";
import key from "../../../config/key";
import {
  clearStorage,
  errorHandler,
  getValueFromLocalStorage,
  isEmpty,
} from "../../../helper/helper";
import { RootState } from "../../store";

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_no: string;
  profile_pic: string;
  profile_pic_thumb: string;
}

interface UserState {
  user: User | null;
  loading: boolean;
  isProfileUpdateLoading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: null,
  loading: false,
  isProfileUpdateLoading: false,
  error: null,
};

export const updateUserProfile = createAsyncThunk(
  "users/updateUser",
  async (formData: any) => {
    try {
      const response = await patchRequest(
        auth.updateUsers,
        formData,
        true,
        true
      );

      return response;
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        errorHandler(error?.response?.data?.errors);
        return;
      } else {
        showError("Something Went Wrong");
      }

      return error;
    }
  }
);

export const getUserProfile = createAsyncThunk(
  "users/getUserById",
  async () => {
    try {
      const userId = getValueFromLocalStorage(key.userId) || "";
      if (isEmpty(userId)) {
        clearStorage();
        window.location.href = "/";
      } else {
        const response = await getRequest(`${auth.getUser}/${userId}`, true);

        return response;
      }
    } catch (error: any) {
      return error;
    }
  }
);

const usersSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.isProfileUpdateLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.isProfileUpdateLoading = false;
        if ([200, 201].includes(action?.payload?.status)) {
          showSuccess("Profile Updated Successfully");
        }
      })
      .addCase(updateUserProfile.rejected, (state) => {
        state.isProfileUpdateLoading = false;
      })

      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action?.payload?.data?.data ?? null;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch client";
      });
  },
});

export const userSelector = (state: RootState) => state.users;

const userReducer = usersSlice.reducer;

export default userReducer;
