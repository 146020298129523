import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import { showError } from "../../components/alerts/alerts";
import useDebounce from "../../components/hooks/useDebounce";
import CustomLoader from "../../components/loader/loader";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import CustomPaginate from "../../components/pagination/CustomPaginate";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import SortingIndicator from "../../components/sorting/SortingIndicator";
import { employeesApi } from "../../config/apiUrls/apiUrl";
import config from "../../config/config";
import { fetchAsyncDropdown, isEmpty } from "../../helper/helper";
import {
  consultantNotFound,
  contractorNotFound,
  employeeNotFound,
  vendorNotFound,
} from "../../messages/NotFoundMessages";
import { loginSelector } from "../../redux/features/auth/loginSlice";
import {
  employeeSelectors,
  fetchEmployees,
} from "../../redux/features/employee/employeeSlice";
import {
  assignEmployees,
  assignVendor,
  getProjectById,
  projectsSelectors,
  unAssignEmployees,
  unAssignVendor,
  updateMinutesOfMeetings,
  updateProject,
} from "../../redux/features/projects/projectSlice";
import {
  Vendor,
  clearVendors,
  createVendor,
  fetchVendors,
  vendorsSelectors,
} from "../../redux/features/vendor/vendorSlice";
import { AppDispatch } from "../../redux/store";
import VendorModal from "../vendors/VendorModal";
import { VendorType } from "../vendors/type";
import PhoneComponent from "../../components/email-and-phone/PhoneComponent";
import { heading } from "../../redux/features/header/headerSlice";
import CustomSunEditor from "../../components/customSunEditor/customSunEditor";
import {
  clearContractor,
  contractorsSelectors,
  createContractor,
  fetchContractors,
} from "../../redux/features/contractor/contractorSlice";
import ContractorModal from "../contractor/contractorModal";
import {
  clearConsultant,
  consultantsSelectors,
  createConsultant,
  fetchConsultants,
} from "../../redux/features/consultant/consultantSlice";
import ConsultantModal from "../consultant/consultantModal";

const ProjectDetails = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { type } = useSelector(loginSelector);

  const projectDetails = useSelector(projectsSelectors.details);
  const detailsLoading = useSelector(projectsSelectors.detailsLoading);
  const vendors = useSelector(vendorsSelectors.vendors);
  const vendorsList = useSelector(vendorsSelectors.vendorsList);
  const vendorsLoading = useSelector(vendorsSelectors.loading);
  const vendorListLoading = useSelector(vendorsSelectors.vendorListLoading);

  const {
    contractorsListLoading,
    contractorsList,
    contractors,
    loading: contractorLoading,
  } = useSelector(contractorsSelectors);

  const {
    consultantsListLoading,
    consultantsList,
    consultants,
    loading: consultantsLoading,
  } = useSelector(consultantsSelectors);

  const location = useLocation();
  const { projectId } = location.state;
  const [selected, setSelected] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [limit, setLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [count, setCount] = useState<number>(0);
  const [showVendorsModal, setShowVendorsModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [show, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedVendorIds, setSelectedVendorIds] = useState<number[]>([]);
  const [note, setNote] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const searchDebounce = useDebounce(search, 500);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(true);
  const employees = useSelector(employeeSelectors.employees);
  const employeesLoading = useSelector(employeeSelectors.loading);
  const [empSelected, setEmpSelected] = useState<number>(0);
  const [empCurrentOffset, setEmpCurrentOffset] = useState<number>(0);
  const [empLimit, setEmpLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [empCount, setEmpCount] = useState<number>(0);
  const [empSearch, setEmpSearch] = useState<string>("");
  const [ordering, setOrdering] = useState<string>("full_name");
  const empSearchDebounce = useDebounce(empSearch, 500);

  const [selectedEmployeesIds, setSelectedEmployeesIds] = useState<any[]>([]);
  const [empAsyncOffset, setEmpAsyncOffset] = useState("");
  const [empAsyncList, setEmpAsyncList] = useState([]);
  const [empAsyncSearch, setEmpAsyncSearch] = useState("");
  const [assignModal, setAssignModal] = useState(false);
  const [unassignModal, setUnAssignModal] = useState(false);
  const [empId, setEmpId] = useState(0);
  const [allEmployees, setAllEmployees] = useState(false);

  const [vendorsSelected, setVendorsSelected] = useState<number>(0);
  const [vendorCurrentOffset, setVendorCurrentOffset] = useState<number>(0);
  const [vendorCount, setVendorCount] = useState<number>(0);
  const [vendorSearch, setVendorSearch] = useState<string>("");
  const vendorSearchDebounce = useDebounce(vendorSearch, 500);
  const [vendorOrdering, setVendorOrdering] = useState<string>("name");
  const [unassignVendorModal, setUnAssignVendorModal] = useState(false);
  const [vendorId, setVendorId] = useState(0);

  const [contractorsSelected, setContractorsSelected] = useState<number>(0);
  const [contractorCurrentOffset, setContractorCurrentOffset] =
    useState<number>(0);
  const [contractorCount, setContractorCount] = useState<number>(0);
  const [contractorSearch, setContractorSearch] = useState<string>("");
  const contractorSearchDebounce = useDebounce(contractorSearch, 500);
  const [contractorOrdering, setContractorOrdering] = useState<string>("name");

  const [consultantsSelected, setConsultantsSelected] = useState<number>(0);
  const [consultantsCurrentOffset, setConsultantsCurrentOffset] =
    useState<number>(0);
  const [consultantsCount, setConsultantsCount] = useState<number>(0);
  const [consultantsSearch, setConsultantsSearch] = useState<string>("");
  const consultantsSearchDebounce = useDebounce(consultantsSearch, 500);
  const [consultantsOrdering, setConsultantsOrdering] =
    useState<string>("name");

  const [description, setDescription] = useState("");

  const isAdmin = type === "admin";

  // Function to handle loading more vendors when "Load More" button is clicked
  const handleLoadMore = () => {
    setCurrentOffset((value) => value + parseInt(limit)); // Update offset state
  };

  const openModal = () => {
    setShowModal(true);
    setShowVendorsModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowVendorsModal(true);
  };

  const handleSearch = (e: React.SyntheticEvent) => {
    setCurrentOffset(0);
    setSelected(0);
    setSearch((e.target as HTMLInputElement).value);
  };

  const handleEmployeeSearch = (e: React.SyntheticEvent) => {
    setEmpCurrentOffset(0);
    setEmpSelected(0);
    setEmpSearch((e.target as HTMLInputElement).value);
  };

  const handleVendorSearch = (e: React.SyntheticEvent) => {
    setVendorCurrentOffset(0);
    setVendorsSelected(0);
    setVendorSearch((e.target as HTMLInputElement).value);
  };

  const handleContractorSearch = (e: React.SyntheticEvent) => {
    setContractorCurrentOffset(0);
    setContractorsSelected(0);
    setContractorSearch((e.target as HTMLInputElement).value);
  };

  const handleConsultantSearch = (e: React.SyntheticEvent) => {
    setConsultantsCurrentOffset(0);
    setConsultantsSelected(0);
    setConsultantsSearch((e.target as HTMLInputElement).value);
  };

  const handleSort = (value: string) => {
    setOrdering(ordering === value ? `-${value}` : value);
    setEmpCurrentOffset(0);
    setEmpSelected(0);
  };

  const handleVendorSort = (value: string) => {
    setVendorOrdering(vendorOrdering === value ? `-${value}` : value);
    setVendorCurrentOffset(0);
    setVendorsSelected(0);
  };

  const handleContractorSort = (value: string) => {
    setContractorOrdering(contractorOrdering === value ? `-${value}` : value);
    setContractorCurrentOffset(0);
    setContractorsSelected(0);
  };

  const handleConsultantSort = (value: string) => {
    setConsultantsOrdering(consultantsOrdering === value ? `-${value}` : value);
    setConsultantsCurrentOffset(0);
    setConsultantsSelected(0);
  };

  const handleCheckboxChange = (vendorId: number) => {
    if (!isEmpty(vendorId)) {
      setSelectedVendorIds((prevSelectedVendorIds) => {
        if (prevSelectedVendorIds.includes(vendorId)) {
          // If vendor ID is already in the array, remove it
          return prevSelectedVendorIds.filter((id) => id !== vendorId);
        } else {
          // If vendor ID is not in the array, add it
          return [...prevSelectedVendorIds, vendorId];
        }
      });
    }
  };

  const fetchProjectDetails = async () => {
    const res = await dispatch(getProjectById(projectId));

    setDescription(res?.payload?.data?.data?.minutes_of_meeting ?? "");
  };

  useEffect(() => {
    if (!isEmpty(projectId)) {
      fetchProjectDetails();
    }
  }, [projectId]);

  useEffect(() => {
    setNote(projectDetails?.note ?? "");
  }, [projectDetails]);

  useEffect(() => {
    if (showVendorsModal) {
      if (modalType === "vendor") {
        fetchVendorsRecords();
      }

      if (modalType === "contractor") {
        fetchContractorRecords();
      }

      if (modalType === "consultant") {
        fetchConsultantsRecords();
      }
    }
  }, [
    currentOffset,
    selected,
    searchDebounce,
    projectId,
    showVendorsModal,
    modalType,
  ]);

  useEffect(() => {
    if (modalType === "vendor") {
      dispatch(clearVendors(true));
    }

    if (modalType === "contractor") {
      dispatch(clearContractor(true));
    }

    if (modalType === "consultant") {
      dispatch(clearConsultant(true));
    }
  }, [searchDebounce, modalType]);

  const fetchVendorsRecords = async () => {
    const response = await dispatch(
      fetchVendors({
        currentOffset,
        limit,
        search,
        projectId,
        isModal: true,
      })
    );
    setCount(response?.payload?.data?.meta?.count ?? 0);

    if (response?.payload?.data?.meta?.next === null) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
  };

  const fetchContractorRecords = async () => {
    const response = await dispatch(
      fetchContractors({
        currentOffset,
        limit,
        search,
        projectId,
        isModal: true,
      })
    );
    setCount(response?.payload?.data?.meta?.count ?? 0);

    if (response?.payload?.data?.meta?.next === null) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
  };

  const fetchConsultantsRecords = async () => {
    const response = await dispatch(
      fetchConsultants({
        currentOffset,
        limit,
        search,
        projectId,
        isModal: true,
      })
    );
    setCount(response?.payload?.data?.meta?.count ?? 0);

    if (response?.payload?.data?.meta?.next === null) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
  };

  useEffect(() => {
    fetchEmployeeRecords();
  }, [
    empCurrentOffset,
    empSelected,
    projectId,
    empSearchDebounce,
    ordering,
    allEmployees,
  ]);

  const fetchEmployeeRecords = async () => {
    const response = await dispatch(
      fetchEmployees({
        currentOffset: empCurrentOffset,
        limit: empLimit,
        search: empSearch,
        projectId,
        ordering,
        include_all_employees: allEmployees,
        isDeleted: allEmployees,
        is_active: !allEmployees,
      })
    );
    setEmpCount(response?.payload?.data?.meta?.count ?? 0);
  };

  const onSubmit = async (data: VendorType) => {
    setSubmitting(true);

    const body: Omit<any, "id"> = {
      name: data.name,
      phone_no: data.phone_no,
      type: data.type,
      email: data.email,
      note: data.note
    };

    if (modalType === "vendor") {
      const response = await dispatch(createVendor(body));
      const newVendorId = response?.payload?.data?.data?.id; // Get the newly created vendor ID
      if (!isEmpty(newVendorId)) {
        setSelectedVendorIds((prevSelectedVendorIds) => [
          ...prevSelectedVendorIds,
          newVendorId,
        ]); // Append the new vendor ID to the selected vendor IDs
      }

      dispatch(clearVendors(true));
    }

    if (modalType === "contractor") {
      const response = await dispatch(createContractor(body));
      const newVendorId = response?.payload?.data?.data?.id; // Get the newly created vendor ID
      if (!isEmpty(newVendorId)) {
        setSelectedVendorIds((prevSelectedVendorIds) => [
          ...prevSelectedVendorIds,
          newVendorId,
        ]); // Append the new vendor ID to the selected vendor IDs
      }
      dispatch(clearContractor(true));
    }

    if (modalType === "consultant") {
      const response = await dispatch(createConsultant(body));
      const newVendorId = response?.payload?.data?.data?.id; // Get the newly created vendor ID
      if (!isEmpty(newVendorId)) {
        setSelectedVendorIds((prevSelectedVendorIds) => [
          ...prevSelectedVendorIds,
          newVendorId,
        ]); // Append the new vendor ID to the selected vendor IDs
      }
      dispatch(clearConsultant(true));
    }

    setCurrentOffset(0);

    closeModal();
    setSubmitting(false);
  };

  const assignVendorToProject = async (
    event?: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (isEmpty(selectedVendorIds)) {
      showError(`Please Select At Least One ${modalType}`);
    } else {
      await dispatch(
        assignVendor({
          id: projectId,
          vendor_id: selectedVendorIds,
          modalType: modalType,
        })
      );
      setShowVendorsModal(false);
      setSearch("");
      setSelectedVendorIds([]);
      await dispatch(getProjectById(projectId));
      if (modalType === "vendor") {
        await fetchVendorsRecordsListing();
      }
      if (modalType === "contractor") {
        await fetchContractorRecordsListing();
      }
      if (modalType === "consultant") {
        await fetchConsultantRecordsListing();
      }
    }
  };

  const updateProjectNote = async () => {
    await dispatch(
      updateProject({
        id: projectId,
        name: projectDetails?.name ?? "",
        client: projectDetails?.client ?? 0,
        address: projectDetails?.address ?? "",
        note: note,
        budget: projectDetails?.budget ?? 0,
        description: projectDetails?.description ?? null,
      })
    );
    setShowVendorsModal(false);
    await dispatch(getProjectById(projectId));
  };

  const fetchAsyncEmployeesRecords = (search: any) => {
    return fetchAsyncDropdown(
      empAsyncOffset,
      setEmpAsyncOffset,
      empAsyncList,
      setEmpAsyncList,
      `${employeesApi.getEmployees}?employee_without_project_id=${projectId}`,
      search,
      setEmpAsyncSearch,
      false,
      false
    );
  };

  const closeAssignEmpModal = () => {
    setAssignModal(false);
    setSelectedEmployeesIds([]);
    setEmpAsyncList([]);
    setEmpAsyncOffset("");
  };

  const assignEmployeeProject = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);

    if (isEmpty(selectedEmployeesIds)) {
      showError("Please select at least one employee");
      setSubmitting(false);
    } else {
      const allEmployeeIds = selectedEmployeesIds.map((data) => data.value);

      const response = await dispatch(
        assignEmployees({
          id: projectId,
          employee_ids: allEmployeeIds,
        })
      );

      if ([200, 201].includes(response?.payload?.status)) {
        await fetchEmployeeRecords();
        closeAssignEmpModal();
      }
      setSubmitting(false);
    }
  };

  const unAssignEmployeeFromProject = async () => {
    if (!isEmpty(projectId)) {
      setSubmitting(true);

      const employeeIds = [empId];

      const response = await dispatch(
        unAssignEmployees({
          id: projectId,
          employee_ids: employeeIds,
        })
      );

      if ([200, 201].includes(response?.payload?.status)) {
        setUnAssignModal(false);
        await fetchEmployeeRecords();
      }

      setSubmitting(false);
    }
  };

  const openConfirmUnassign = (empId: number) => {
    setUnAssignModal(true);
    setEmpId(empId);
  };

  const closeConfirmUnassign = () => {
    setUnAssignModal(false);
    setEmpId(0);
  };

  const unAssignVendorFromProject = async () => {
    if (!isEmpty(projectId)) {
      setSubmitting(true);

      const vendorIds = [vendorId];

      const response = await dispatch(
        unAssignVendor({
          id: projectId,
          vendor_id: vendorIds,
          modalType: modalType,
        })
      );

      if ([200, 201].includes(response?.payload?.status)) {
        setUnAssignVendorModal(false);

        if (modalType === "vendor") {
          await fetchVendorsRecordsListing();
        }

        if (modalType === "contractor") {
          await fetchContractorRecordsListing();
        }

        if (modalType === "consultant") {
          await fetchConsultantRecordsListing();
        }
      }

      setSubmitting(false);
    }
  };

  const openConfirmUnassignVendor = (vendorId: number) => {
    setUnAssignVendorModal(true);
    setVendorId(vendorId);
  };

  const closeConfirmUnassignVendor = () => {
    setUnAssignVendorModal(false);
    setVendorId(0);
  };

  useEffect(() => {
    fetchVendorsRecordsListing();
  }, [
    vendorCurrentOffset,
    vendorsSelected,
    vendorSearchDebounce,
    vendorOrdering,
  ]);

  useEffect(() => {
    fetchContractorRecordsListing();
  }, [
    contractorCurrentOffset,
    contractorsSelected,
    contractorSearchDebounce,
    contractorOrdering,
  ]);

  useEffect(() => {
    fetchConsultantRecordsListing();
  }, [
    consultantsCurrentOffset,
    consultantsSelected,
    consultantsSearchDebounce,
    consultantsOrdering,
  ]);

  const fetchVendorsRecordsListing = async () => {
    const response = await dispatch(
      fetchVendors({
        currentOffset: vendorCurrentOffset,
        limit,
        search: vendorSearch,
        ordering: vendorOrdering,
        projectIdList: projectId,
      })
    );
    setVendorCount(response?.payload?.data?.meta?.count ?? 0);
  };

  const fetchContractorRecordsListing = async () => {
    const response = await dispatch(
      fetchContractors({
        currentOffset: contractorCurrentOffset,
        limit,
        search: contractorSearch,
        ordering: contractorOrdering,
        projectIdList: projectId,
      })
    );

    setContractorCount(response?.payload?.data?.meta?.count ?? 0);
  };

  const fetchConsultantRecordsListing = async () => {
    const response = await dispatch(
      fetchConsultants({
        currentOffset: consultantsCurrentOffset,
        limit,
        search: consultantsSearch,
        ordering: consultantsOrdering,
        projectIdList: projectId,
      })
    );

    setConsultantsCount(response?.payload?.data?.meta?.count ?? 0);
  };

  useEffect(() => {
    dispatch(heading("Project Details"));
  }, []);

  return (
    <SideBarWrapper>
      {detailsLoading ? (
        <CustomLoader />
      ) : (
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {/* Project Information */}
                <div className="mb-30">
                  <div className="d-flex flex-wrap align-items-center justify-content-between total-time-tracking">
                    <p className="fs-md fw-bold">Project Information</p>
                    {isEmpty(projectDetails?.deleted_at) && (
                      <a
                        className="btn btn-light d-inline-flex align-items-center fw-bold"
                        type="button"
                        onClick={updateProjectNote}
                      >
                        <span>Save</span>
                      </a>
                    )}
                  </div>
                  <div className="row mx-0 flex-wrap fs-md bg-white time-tracking align-items-start">
                    <div className="col-md-2 pt-2 fw-500 ps-2">
                      <p>Project Name</p>
                    </div>
                    <div className="col-md-10 pt-2 ps-2">
                      {isEmpty(projectDetails?.name)
                        ? "-"
                        : projectDetails?.name}
                    </div>
                    <div className="col-md-2 pt-3 fw-500 ps-2">
                      <p>Client Name</p>
                    </div>
                    <div className="col-md-10 pt-3 ps-2">
                      {" "}
                      {isEmpty(projectDetails?.client_name)
                        ? "-"
                        : projectDetails?.client_name}
                    </div>
                    <div className="col-md-2 pt-3 fw-500 ps-2">
                      <p>Project Address</p>
                    </div>
                    <div className="col-md-10 pt-3 ps-2">
                      {" "}
                      {isEmpty(projectDetails?.address)
                        ? "-"
                        : projectDetails?.address}
                    </div>
                    <div className="col-md-2 pt-3 fw-500 ps-2 pb-2">
                      <p>Description</p>
                    </div>
                    <div className="col-md-10 pt-3 ps-2">
                      {" "}
                      {isEmpty(projectDetails?.description)
                        ? "-"
                        : projectDetails?.description}
                    </div>
                    <div className="col-md-12 pt-2 fw-500 ps-2 pb-3">
                      <p className="mb-2">Note</p>
                      <p>
                        {" "}
                        <textarea
                          className="form-control textarea-height"
                          id="noteFormControlTextarea"
                          rows={3}
                          defaultValue={""}
                          placeholder="Type here..."
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                        />
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="d-flex flex-wrap align-items-center justify-content-between total-time-tracking">
                    <p className="fs-md fw-bold">Minutes of Meeting</p>
                    {isEmpty(projectDetails?.deleted_at) && (
                      <a
                        className="btn btn-light d-inline-flex align-items-center fw-bold"
                        type="button"
                        onClick={() => {
                          dispatch(
                            updateMinutesOfMeetings({
                              id: projectId,
                              minutes_of_meeting: description,
                            })
                          );
                          dispatch(getProjectById(projectId));
                        }}
                      >
                        <span>Save</span>
                      </a>
                    )}
                  </div>
                  <div>
                    <CustomSunEditor
                      data={description}
                      handleChange={(e: any) => {
                        setDescription(e);
                      }}
                      fieldName="text_editor"
                      validationTriggered={""}
                      message=""
                    />
                  </div>
                </div>

                {/* Consultants */}
                <div>
                  <div className="card-body pt-0 pb-3">
                    <div className="d-flex flex-wrap align-items-center justify-content-between total-time-tracking">
                      <p className="fs-md fw-bold">Consultants</p>
                    </div>
                    <div
                      className="card-header border-0 py-2 px-7 align-items-center"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          marginLeft: "20px",
                          marginRight: "20px",
                        }}
                      >
                        <div className="mb-res" style={{ marginTop: "15px" }}>
                          <input
                            autoFocus
                            name="text"
                            type="text"
                            autoComplete="text"
                            placeholder="Search Here..."
                            value={consultantsSearch}
                            onChange={(e) => handleConsultantSearch(e)}
                            className="form-control form-search"
                            disabled={consultantsLoading}
                          />
                        </div>
                        <div
                          className="ms-lg-auto"
                          style={{
                            float: "right",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {isEmpty(projectDetails?.deleted_at) && (
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                dispatch(clearConsultant(true));
                                setShowVendorsModal(true);
                                setSearch("");
                                setCurrentOffset(0);
                                setModalType("consultant");
                              }}
                            >
                              Add Consultant
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {consultantsLoading ? (
                      <>
                        <CustomLoader />
                      </>
                    ) : (
                      <>
                        {isEmpty(consultants) ? (
                          <>
                            <div
                              style={{
                                backgroundColor: "white",
                                padding: "5px",
                              }}
                            >
                              <NoRecordFound message={consultantNotFound} />
                            </div>
                          </>
                        ) : (
                          <div className="table-responsive  vendor-table">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr className="text-muted fw-500">
                                  <SortingIndicator
                                    field="Name"
                                    onClick={handleConsultantSort}
                                    column="name"
                                    sortingColumn={consultantsOrdering}
                                  />
                                  <SortingIndicator
                                    field="Type"
                                    onClick={handleConsultantSort}
                                    column="type"
                                    sortingColumn={consultantsOrdering}
                                  />
                                  <SortingIndicator
                                    field="Phone Number"
                                    onClick={handleConsultantSort}
                                    column="phone_no"
                                    sortingColumn={consultantsOrdering}
                                  />
                                  <SortingIndicator
                                    field="Created By"
                                    onClick={handleConsultantSort}
                                    column="created_by"
                                    sortingColumn={consultantsOrdering}
                                  />
                                  {isEmpty(projectDetails?.deleted_at) && (
                                    <th>Action</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {consultants &&
                                  consultants.map((item) => (
                                    <tr
                                      key={item.id}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <td>
                                        {isEmpty(item.name) ? "-" : item.name}
                                      </td>
                                      <td>
                                        {isEmpty(item.type) ? "-" : item.type}
                                      </td>
                                      <td>
                                        <PhoneComponent phone={item.phone_no} />
                                      </td>
                                      <td>
                                        {isEmpty(item.created_by)
                                          ? "-"
                                          : item.created_by}
                                      </td>
                                      {isEmpty(projectDetails?.deleted_at) && (
                                        <td className="w-10px">
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <img
                                              src="../img/svg/ic-unassign.svg"
                                              alt="dashboard"
                                              title="dashboard"
                                              width={24}
                                              height={24}
                                              loading="eager"
                                              className="me-2"
                                              style={{ marginBottom: "10px" }}
                                              onClick={() => {
                                                setModalType("consultant");
                                                openConfirmUnassignVendor(
                                                  Number(item.id)
                                                );
                                              }}
                                            />
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!isEmpty(consultants) && !consultantsLoading && (
                    <div
                      style={{
                        marginTop: "-60px",
                        marginBottom: "-20px",
                      }}
                    >
                      <CustomPaginate
                        data={consultants}
                        selected={consultantsSelected}
                        setSelected={setConsultantsSelected}
                        setCurrentOffset={setConsultantsCurrentOffset}
                        totalRecords={consultantsCount}
                        limit={limit}
                        setLimit={setLimit}
                        isDisable={consultantsLoading}
                      />
                    </div>
                  )}
                </div>

                {/* Contractors */}
                <div>
                  <div className="card-body pt-0 pb-3">
                    <div className="d-flex flex-wrap align-items-center justify-content-between total-time-tracking">
                      <p className="fs-md fw-bold">Contractors</p>
                    </div>
                    <div
                      className="card-header border-0 py-2 px-7 align-items-center"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          marginLeft: "20px",
                          marginRight: "20px",
                        }}
                      >
                        <div className="mb-res" style={{ marginTop: "15px" }}>
                          <input
                            autoFocus
                            name="text"
                            type="text"
                            autoComplete="text"
                            placeholder="Search Here..."
                            value={contractorSearch}
                            onChange={(e) => handleContractorSearch(e)}
                            className="form-control form-search"
                            disabled={contractorLoading}
                          />
                        </div>
                        <div
                          className="ms-lg-auto"
                          style={{
                            float: "right",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {isEmpty(projectDetails?.deleted_at) && (
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                dispatch(clearContractor(true));
                                setShowVendorsModal(true);
                                setSearch("");
                                setCurrentOffset(0);
                                setModalType("contractor");
                              }}
                            >
                              Add Contractor
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {contractorLoading ? (
                      <>
                        <CustomLoader />
                      </>
                    ) : (
                      <>
                        {isEmpty(contractors) ? (
                          <>
                            <div
                              style={{
                                backgroundColor: "white",
                                padding: "5px",
                              }}
                            >
                              <NoRecordFound message={contractorNotFound} />
                            </div>
                          </>
                        ) : (
                          <div className="table-responsive  vendor-table">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr className="text-muted fw-500">
                                  <SortingIndicator
                                    field="Name"
                                    onClick={handleContractorSort}
                                    column="name"
                                    sortingColumn={contractorOrdering}
                                  />
                                  <SortingIndicator
                                    field="Type"
                                    onClick={handleContractorSort}
                                    column="type"
                                    sortingColumn={contractorOrdering}
                                  />
                                  <SortingIndicator
                                    field="Phone Number"
                                    onClick={handleContractorSort}
                                    column="phone_no"
                                    sortingColumn={contractorOrdering}
                                  />
                                  <SortingIndicator
                                    field="Created By"
                                    onClick={handleContractorSort}
                                    column="created_by"
                                    sortingColumn={contractorOrdering}
                                  />
                                  {isEmpty(projectDetails?.deleted_at) && (
                                    <th>Action</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {contractors &&
                                  contractors.map((item) => (
                                    <tr
                                      key={item.id}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <td>
                                        {isEmpty(item.name) ? "-" : item.name}
                                      </td>
                                      <td>
                                        {isEmpty(item.type) ? "-" : item.type}
                                      </td>
                                      <td>
                                        <PhoneComponent phone={item.phone_no} />
                                      </td>
                                      <td>
                                        {isEmpty(item.created_by)
                                          ? "-"
                                          : item.created_by}
                                      </td>
                                      {isEmpty(projectDetails?.deleted_at) && (
                                        <td className="w-10px">
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <img
                                              src="../img/svg/ic-unassign.svg"
                                              alt="dashboard"
                                              title="dashboard"
                                              width={24}
                                              height={24}
                                              loading="eager"
                                              className="me-2"
                                              style={{ marginBottom: "10px" }}
                                              onClick={() => {
                                                setModalType("contractor");
                                                openConfirmUnassignVendor(
                                                  Number(item.id)
                                                );
                                              }}
                                            />
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!isEmpty(contractors) && !contractorLoading && (
                    <div
                      style={{
                        marginTop: "-60px",
                        marginBottom: "-20px",
                      }}
                    >
                      <CustomPaginate
                        data={contractors}
                        selected={contractorsSelected}
                        setSelected={setContractorsSelected}
                        setCurrentOffset={setContractorCurrentOffset}
                        totalRecords={contractorCount}
                        limit={limit}
                        setLimit={setLimit}
                        isDisable={contractorLoading}
                      />
                    </div>
                  )}
                </div>

                {/* vendor list */}
                <div>
                  <div className="card-body pt-0 pb-3">
                    <div className="d-flex flex-wrap align-items-center justify-content-between total-time-tracking">
                      <p className="fs-md fw-bold">Vendors</p>
                    </div>
                    <div
                      className="card-header border-0 py-2 px-7 align-items-center"
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          marginLeft: "20px",
                          marginRight: "20px",
                        }}
                      >
                        <div className="mb-res" style={{ marginTop: "15px" }}>
                          <input
                            autoFocus
                            name="text"
                            type="text"
                            autoComplete="text"
                            placeholder="Search Here..."
                            value={vendorSearch}
                            onChange={(e) => handleVendorSearch(e)}
                            className="form-control form-search"
                            disabled={vendorsLoading}
                          />
                        </div>
                        <div
                          className="ms-lg-auto"
                          style={{
                            float: "right",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {isEmpty(projectDetails?.deleted_at) && (
                            <button
                              type="button"
                              className="btn"
                              onClick={() => {
                                dispatch(clearVendors(true));
                                setShowVendorsModal(true);
                                setSearch("");
                                setCurrentOffset(0);
                                setModalType("vendor");
                              }}
                            >
                              Add Vendors
                            </button>
                          )}
                        </div>
                      </div>
                    </div>

                    {vendorsLoading ? (
                      <>
                        <CustomLoader />
                      </>
                    ) : (
                      <>
                        {isEmpty(vendors) ? (
                          <>
                            <div
                              style={{
                                backgroundColor: "white",
                                padding: "5px",
                              }}
                            >
                              <NoRecordFound message={vendorNotFound} />
                            </div>
                          </>
                        ) : (
                          <div className="table-responsive  vendor-table">
                            <table className="table table-hover mb-0">
                              <thead>
                                <tr className="text-muted fw-500">
                                  <SortingIndicator
                                    field="Name"
                                    onClick={handleVendorSort}
                                    column="name"
                                    sortingColumn={ordering}
                                  />
                                  <SortingIndicator
                                    field="Type"
                                    onClick={handleVendorSort}
                                    column="type"
                                    sortingColumn={ordering}
                                  />
                                  <SortingIndicator
                                    field="Phone Number"
                                    onClick={handleVendorSort}
                                    column="phone_no"
                                    sortingColumn={ordering}
                                  />
                                  <SortingIndicator
                                    field="Created By"
                                    onClick={handleVendorSort}
                                    column="created_by"
                                    sortingColumn={ordering}
                                  />
                                  {isEmpty(projectDetails?.deleted_at) && (
                                    <th>Action</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {vendors &&
                                  vendors.map((vendor) => (
                                    <tr
                                      key={vendor.id}
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <td>
                                        {isEmpty(vendor.name)
                                          ? "-"
                                          : vendor.name}
                                      </td>
                                      <td>
                                        {isEmpty(vendor.type)
                                          ? "-"
                                          : vendor.type}
                                      </td>
                                      <td>
                                        <PhoneComponent
                                          phone={vendor.phone_no}
                                        />
                                      </td>
                                      <td>
                                        {isEmpty(vendor.created_by)
                                          ? "-"
                                          : vendor.created_by}
                                      </td>
                                      {isEmpty(projectDetails?.deleted_at) && (
                                        <td className="w-10px">
                                          <div
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <img
                                              src="../img/svg/ic-unassign.svg"
                                              alt="dashboard"
                                              title="dashboard"
                                              width={24}
                                              height={24}
                                              loading="eager"
                                              className="me-2"
                                              style={{ marginBottom: "10px" }}
                                              onClick={() => {
                                                setModalType("vendor");
                                                openConfirmUnassignVendor(
                                                  Number(vendor.id)
                                                );
                                              }}
                                            />
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {!isEmpty(vendors) && !vendorsLoading && (
                    <div
                      style={{
                        marginTop: "-60px",
                        marginBottom: "-20px",
                      }}
                    >
                      <CustomPaginate
                        data={vendors}
                        selected={vendorsSelected}
                        setSelected={setVendorsSelected}
                        setCurrentOffset={setVendorCurrentOffset}
                        totalRecords={vendorCount}
                        limit={limit}
                        setLimit={setLimit}
                        isDisable={vendorsLoading}
                      />
                    </div>
                  )}
                </div>

                {true && ( // allowed admin and user both to see the employees
                  <div>
                    <div className="card-body pt-0 pb-3">
                      <div
                        className="d-flex flex-wrap align-items-center total-time-tracking"
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className={`btn fs-md employee-width ${
                            allEmployees
                              ? "fw-bold cancel-button-background"
                              : ""
                          }`}
                          style={{
                            textAlign: "center",
                            marginRight: 5,
                            verticalAlign: "middle",
                          }}
                          onClick={() => {
                            setEmpCurrentOffset(0);
                            setEmpSelected(0);
                            setAllEmployees(false);
                          }}
                        >
                          Assigned Employees
                        </span>
                        <span
                          style={{
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setEmpCurrentOffset(0);
                            setEmpSelected(0);

                            setAllEmployees(true);
                          }}
                          className={`btn fs-md employee-width ${
                            allEmployees
                              ? "fw-bold"
                              : "cancel-button-background"
                          }`}
                        >
                          All Employees
                        </span>
                      </div>

                      <div
                        className="card-header border-0 py-2 px-7 align-items-center"
                        style={{ backgroundColor: "white" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <div className="mb-res" style={{ marginTop: "15px" }}>
                            <input
                              autoFocus
                              name="text"
                              type="text"
                              autoComplete="text"
                              placeholder="Search Here..."
                              value={empSearch}
                              onChange={(e) => handleEmployeeSearch(e)}
                              className="form-control form-search"
                              disabled={employeesLoading}
                            />
                          </div>
                          <div
                            className="ms-lg-auto"
                            style={{
                              float: "right",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            <div className="card-toolbar d-flex my-0 mt-1 mb-1">
                              {
                                isEmpty(projectDetails?.deleted_at) && (
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => setAssignModal(true)}
                                  >
                                    Assign Employees
                                  </button>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {employeesLoading ? (
                        <CustomLoader />
                      ) : (
                        <>
                          {isEmpty(employees) ? (
                            <div
                              style={{
                                backgroundColor: "white",
                                padding: "5px",
                              }}
                            >
                              <NoRecordFound message={employeeNotFound} />
                            </div>
                          ) : (
                            <>
                              <div className="table-responsive  vendor-table">
                                {/* <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-1"> */}
                                <table className="table table-hover mb-0">
                                  <thead>
                                    <tr className="text-muted fw-500">
                                      <SortingIndicator
                                        field="Name"
                                        onClick={handleSort}
                                        column="full_name"
                                        sortingColumn={ordering}
                                      />

                                      <SortingIndicator
                                        field="Email"
                                        onClick={handleSort}
                                        column="email"
                                        sortingColumn={ordering}
                                      />

                                      <SortingIndicator
                                        field="Phone Number"
                                        onClick={handleSort}
                                        column="phone_no"
                                        sortingColumn={ordering}
                                      />

                                      <SortingIndicator
                                        field="Spent Hours"
                                        onClick={handleSort}
                                        column="total_working_duration"
                                        sortingColumn={ordering}
                                      />

                                      {
                                        isAdmin && <SortingIndicator
                                        field="Total Cost"
                                        onClick={handleSort}
                                        column="total_amount"
                                        sortingColumn={ordering}
                                      />
                                      }

                                      {!allEmployees &&
                                        isEmpty(projectDetails?.deleted_at) && (
                                          <th>Action</th>
                                        )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {employees &&
                                      employees.map((employee) => (
                                        <tr key={employee.id}>
                                          <td>
                                            {isEmpty(employee.full_name)
                                              ? "-"
                                              : employee.full_name}
                                          </td>
                                          <td>
                                            {isEmpty(employee.email)
                                              ? "-"
                                              : employee.email}
                                          </td>
                                          <td>
                                            {isEmpty(employee.phone_no)
                                              ? "-"
                                              : employee.phone_no}
                                          </td>
                                          <td>
                                            {isEmpty(
                                              employee.total_working_duration
                                            )
                                              ? "-"
                                              : employee.total_working_duration}
                                          </td>
                                         {
                                          isAdmin &&  <td>
                                          {isEmpty(employee.total_amount)
                                            ? "-"
                                            : employee.total_amount}
                                        </td>
                                         }
                                          {!allEmployees &&
                                            isEmpty(
                                              projectDetails?.deleted_at
                                            ) && (
                                              <td className="w-10px">
                                                <div
                                                  style={{ cursor: "pointer" }}
                                                  onClick={(e) =>
                                                    e.stopPropagation()
                                                  }
                                                >
                                                  <img
                                                    src="../img/svg/ic-unassign.svg"
                                                    alt="dashboard"
                                                    title="dashboard"
                                                    width={24}
                                                    height={24}
                                                    loading="eager"
                                                    className="me-2"
                                                    style={{
                                                      marginBottom: "10px",
                                                    }}
                                                    onClick={() =>
                                                      openConfirmUnassign(
                                                        Number(employee.id)
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </td>
                                            )}
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                              {!isEmpty(employees) && !employeesLoading && (
                                <div
                                  style={{
                                    marginTop: "-40px",
                                  }}
                                >
                                  <CustomPaginate
                                    data={employees}
                                    selected={empSelected}
                                    setSelected={setEmpSelected}
                                    setCurrentOffset={setEmpCurrentOffset}
                                    totalRecords={empCount}
                                    limit={empLimit}
                                    setLimit={setEmpLimit}
                                    isDisable={employeesLoading}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* listing modal */}
          <Modal
            show={showVendorsModal}
            onHide={() => {
              setShowVendorsModal(false);
              setSelectedVendorIds([]);
            }}
            backdrop="static"
            animation={false}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{`
              ${modalType === "vendor" ? "Vendors" : ""}
              ${modalType === "contractor" ? "Contractors" : ""}
              ${modalType === "consultant" ? "Consultants" : ""}
              `}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="d-flex flex-wrap justify-content-between mb-3">
                  <div>
                    <input
                      autoFocus
                      name="text"
                      type="text"
                      autoComplete="text"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => handleSearch(e)}
                      className="form-control form-search mb-lg-0 mb-2"
                    />
                  </div>
                  <button
                    className="me-4 btn fw-bold"
                    type="submit"
                    onClick={() => openModal()}
                  >
                    <span>
                      Add{" "}
                      {`${modalType === "vendor" ? "Vendor" : ""}
                      ${modalType === "contractor" ? "Contractor" : ""}
                      ${modalType === "consultant" ? "Consultants" : ""}
                      `}
                    </span>
                  </button>
                </div>

                {(modalType === "vendor"
                  ? !vendorListLoading
                  : modalType === "contractor"
                  ? !contractorsListLoading
                  : modalType === "consultant" && !consultantsListLoading) &&
                isEmpty(
                  modalType === "vendor"
                    ? vendorsList
                    : modalType === "contractor"
                    ? contractorsList
                    : modalType === "consultant" && consultantsList
                ) ? (
                  <NoRecordFound
                    message={`${
                      modalType === "vendor"
                        ? vendorNotFound
                        : modalType === "contractor"
                        ? contractorNotFound
                        : modalType === "consultant" && consultantNotFound
                    }`}
                  />
                ) : (
                  <div className="card-body pt-0 pb-3">
                    <div className="table-responsive">
                      <table className="table table-hover mb-0">
                        <thead>
                          <tr className="text-muted fw-500">
                            <th></th>
                            <th className="min-w-100px">
                              {" "}
                              {`${modalType === "vendor" ? "Vendor" : ""}
                              ${modalType === "contractor" ? "Contractor" : ""}
                              ${modalType === "consultant" ? "Consultant" : ""}
                              `}{" "}
                              Name
                            </th>
                            <th>Phone Number</th>
                            <th>
                              {" "}
                              {`${modalType === "vendor" ? "Vendor" : ""}
                              ${modalType === "contractor" ? "Contractor" : ""}
                              ${modalType === "consultant" ? "Consultant" : ""}
                              `}{" "}
                              Type
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(modalType === "vendor"
                            ? vendorsList
                            : modalType === "contractor"
                            ? contractorsList
                            : modalType === "consultant"
                            ? consultantsList
                            : []
                          ).map((item) => (
                            <tr
                              key={item.id}
                              onClick={() =>
                                handleCheckboxChange(Number(item.id))
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  onChange={() =>
                                    handleCheckboxChange(Number(item.id))
                                  }
                                  checked={selectedVendorIds.includes(
                                    Number(item.id)
                                  )}
                                  onClick={(event) => event.stopPropagation()}
                                />
                              </td>
                              <td
                                style={{
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  paddingRight: "8px",
                                }}
                                className="min-w-100px"
                              >
                                {isEmpty(item.name) ? "-" : item.name}
                              </td>
                              <td>
                                {isEmpty(item.phone_no) ? "-" : item.phone_no}
                              </td>
                              <td>{isEmpty(item.type) ? "-" : item.type}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                      {(modalType === "vendor"
                        ? vendorsLoading
                        : modalType === "contractor"
                        ? contractorLoading
                        : modalType === "consultant" && consultantsLoading) && (
                        <div className="text-center mt-4 font-bold">
                          Please Wait...
                        </div>
                      )}
                      {(modalType === "vendor"
                        ? !vendorsLoading
                        : modalType === "contractor"
                        ? !contractorLoading
                        : modalType === "consultant" && !consultantsLoading) &&
                        showLoadMore && (
                          <div className="w-100 mt-4 mb-1 text-center">
                            <button
                              className="btn cancel-button-background"
                              type="submit"
                              onClick={handleLoadMore}
                            >
                              Load More
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </>
            </Modal.Body>
            <Modal.Footer>
              <div className="w-100 d-flex justify-content-center">
                <button
                  type="button"
                  className="cancel-button-background me-4 btn"
                  onClick={() => {
                    setShowVendorsModal(false);
                    setSelectedVendorIds([]);
                    setSearch("");
                  }}
                  disabled={
                    modalType === "vendor"
                      ? vendorsLoading
                      : modalType === "contractor"
                      ? contractorLoading
                      : modalType === "consultant" && consultantsLoading
                  }
                >
                  Cancel
                </button>

                <button
                  className="me-4 btn"
                  type="submit"
                  onClick={assignVendorToProject}
                  disabled={
                    modalType === "vendor"
                      ? vendorsLoading
                      : modalType === "contractor"
                      ? contractorLoading
                      : modalType === "consultant" && consultantsLoading
                  }
                >
                  Submit
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* vendor add/edit modal */}
          <VendorModal
            show={show && modalType === "vendor"}
            closeModal={closeModal}
            selectedId={null}
            submitForm={onSubmit}
            submitting={submitting}
          />

          {/* contractor add/edit modal */}
          <ContractorModal
            show={show && modalType === "contractor"}
            closeModal={closeModal}
            selectedId={null}
            submitForm={onSubmit}
            submitting={submitting}
          />

          {/* consultant add/edit modal */}
          <ConsultantModal
            show={show && modalType === "consultant"}
            closeModal={closeModal}
            selectedId={null}
            submitForm={onSubmit}
            submitting={submitting}
          />

          {/* employee assign modal */}
          <Modal
            show={assignModal}
            onHide={closeAssignEmpModal}
            backdrop="static"
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Assign Employees</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="client" style={{ margin: "10px" }}>
                    <Form.Label>Select Employees</Form.Label>
                    <span className="text-danger">*</span>
                    <div
                      style={{
                        color: "#111111",
                        boxShadow: "none",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        borderRadius: "0px",
                      }}
                    >
                      <AsyncPaginate
                        loadOptions={fetchAsyncEmployeesRecords}
                        onChange={(e: any) => {
                          setSelectedEmployeesIds(e);
                        }}
                        isMulti={true}
                        isSearchable={true}
                        value={selectedEmployeesIds}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: "#0c78a4",
                          },
                        })}
                      />
                    </div>
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <div className="w-100 d-flex justify-content-center">
                  <button
                    type="button"
                    onClick={closeAssignEmpModal}
                    disabled={submitting}
                    className="cancel-button-background me-4 btn"
                  >
                    Cancel
                  </button>

                  <button
                    className="btn"
                    type="submit"
                    onClick={assignEmployeeProject}
                    disabled={submitting}
                  >
                    {submitting ? "Please Wait" : "Submit"}
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            show={unassignModal}
            onHide={closeConfirmUnassign}
            backdrop="static"
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Unassign?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>
                Are you sure you want to unassign this employee from this
                project?
              </p>
            </Modal.Body>

            <Modal.Footer>
              <div className="w-100 d-flex justify-content-evenly">
                <button
                  className="btn cancel-button-background"
                  type="button"
                  onClick={closeConfirmUnassign}
                  disabled={submitting}
                >
                  Cancel
                </button>
                <button
                  className="btn me-4"
                  type="button"
                  onClick={unAssignEmployeeFromProject}
                  disabled={submitting}
                >
                  Unassign
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          <Modal
            show={unassignVendorModal}
            onHide={closeConfirmUnassignVendor}
            backdrop="static"
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Unassign?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>
                Are you sure you want to unassign this {modalType} from this
                project?
              </p>
            </Modal.Body>

            <Modal.Footer>
              <div className="w-100 d-flex justify-content-evenly">
                <button
                  className="btn cancel-button-background"
                  type="button"
                  onClick={closeConfirmUnassignVendor}
                  disabled={submitting}
                >
                  Cancel
                </button>
                <button
                  className="btn me-4"
                  type="button"
                  onClick={unAssignVendorFromProject}
                  disabled={submitting}
                >
                  Unassign
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </section>
      )}
    </SideBarWrapper>
  );
};

export default ProjectDetails;
