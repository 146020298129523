//  change file name

import axios from "axios";
import config from "../config";
import { UNAUTHORIZED } from "./apiStatusCode";
import { clearStorage } from "../../helper/helper";

const instance = axios.create({
  baseURL: config.API_END_POINT,
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // for session expired OR unauthenticated and redirect to login
    const { response } = error;

    if (response && response.status && response.status === UNAUTHORIZED) {
      clearStorage();
      window.location.href = "/";
    }

    throw error;
  }
);

export default instance;
