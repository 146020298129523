import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../../../components/alerts/alerts";
import { dashboardApi } from "../../../config/apiUrls/apiUrl";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../config/axois/apiRequest";
import { OK } from "../../../config/axois/apiStatusCode";
import {
  errorHandler,
  getValueFromLocalStorage,
  isEmpty,
  setValueInLocalStorage,
} from "../../../helper/helper";
import {
  ActivityStarted,
  ActivityStopped,
  ActivityUpdated,
  clockInSuccessful,
  clockOutSuccessful,
} from "../../../messages/successMessage";
import { ActivityStop, ActivityType } from "../../../pages/dashboard/type";
import { RootState } from "../../store";
import key from "../../../config/key";

const initialState = {
  isClockInLoading: false,
  isClockIn: getValueFromLocalStorage(key.clockIn) || "",
  dashboard: {
    activities: [],
    total_duration: "",
  },
  currentActivities: {
    current_activity: {
      id: "",
      project_id: 0,
      project_name: "",
      activity_type_id: "",
      activity_type: "",
      start_date_time: null,
      end_date_time: null,
      total_duration: null,
      description: null,
    },
    clock_in_time: null,
    clock_out_time: null,
  },
  isDashboardLoading: true,
  isCurrentActivityLoading: true,
  isStartActivityLoading: false,
  isStopActivityLoading: false,
  isModalShow: false,
  id: "",
  isSingleActivityLoading: false,
  singleActivity: {
    id: "",
    project: "",
    project_name: "",
    activity_type: "",
    activity_type_name: "",
    start_date_time: "",
    end_date_time: "",
    description: "",
  },
};

export const onClockIn = createAsyncThunk("dashboard/clockIn", async () => {
  try {
    const response = await postRequest(dashboardApi.clockIn, {});

    return response;
  } catch (error: any) {
    if (error?.response?.data?.errors) {
      errorHandler(error?.response?.data?.errors);
      return;
    } else {
      showError("Something Went Wrong");
    }

    return error;
  }
});

export const onClockOut = createAsyncThunk("dashboard/clockOut", async () => {
  try {
    const response = await postRequest(dashboardApi.clockOut, {});

    return response;
  } catch (error: any) {
    if (error?.response?.data?.errors) {
      errorHandler(error?.response?.data?.errors);
      return;
    } else {
      showError("Something Went Wrong");
    }

    return error;
  }
});

export const fetchDashboard = createAsyncThunk(
  "dashboard/fetchDashboard",
  async () => {
    try {
      const response = await getRequest(dashboardApi.fetchDashboard);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchSingleActivity = createAsyncThunk(
  "dashboard/fetchSingleActivity",
  async (id: string) => {
    try {
      const response = await getRequest(dashboardApi.fetchSingleActivity(id));

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchCurrentActivity = createAsyncThunk(
  "dashboard/fetchCurrentActivity",
  async () => {
    try {
      const response = await getRequest(dashboardApi.fetchCurrentActivity);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const startActivity = createAsyncThunk(
  "dashboard/startActivity",
  async (body: ActivityType) => {
    try {
      const response = await postRequest(dashboardApi.startActivity, body);

      return response;
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        errorHandler(error?.response?.data?.errors);
        return;
      } else {
        showError("Something Went Wrong");
      }

      return error;
    }
  }
);

export const updateSingleActivity = createAsyncThunk(
  "dashboard/updateSingleActivity",
  async (param: { id: string; body: any }) => {
    try {
      const response = await patchRequest(
        dashboardApi.updateActivity(param.id),
        param.body
      );

      return response;
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        errorHandler(error?.response?.data?.errors);
        return;
      } else {
        showError("Something Went Wrong");
      }

      return error;
    }
  }
);

export const handleModal = createAsyncThunk(
  "dashboard/handleModal",
  async (value: boolean) => {
    return value;
  }
);

export const setId = createAsyncThunk(
  "dashboard/setId",
  async (value: string) => {
    return value;
  }
);

export const stopActivity = createAsyncThunk(
  "dashboard/stopActivity",
  async (body: ActivityStop) => {
    try {
      const response = await patchRequest(
        dashboardApi.stopActivity(body?.id || ""),
        body
      );

      return response;
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        errorHandler(error?.response?.data?.errors);
        return;
      } else {
        showError("Something Went Wrong");
      }

      return error;
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // clock in cases
      .addCase(onClockIn.pending, (state) => {
        state.isClockInLoading = true;
      })
      .addCase(onClockIn.fulfilled, (state, action) => {
        state.isClockInLoading = false;

        if ([200, 201].includes(action?.payload?.status)) {
          showSuccess(clockInSuccessful);
        }
      })
      .addCase(onClockIn.rejected, (state) => {
        state.isClockInLoading = false;
        state.isClockIn = "";
      })

      // clock out cases
      .addCase(onClockOut.pending, (state) => {
        state.isClockInLoading = true;
      })
      .addCase(onClockOut.fulfilled, (state, action) => {
        state.isClockInLoading = false;

        if ([200, 201].includes(action?.payload?.status)) {
          showSuccess(clockOutSuccessful);
        }
      })
      .addCase(onClockOut.rejected, (state) => {
        state.isClockInLoading = false;
      })

      // start Activity cases
      .addCase(startActivity.pending, (state) => {
        state.isStartActivityLoading = true;
      })
      .addCase(startActivity.fulfilled, (state, action) => {
        state.isStartActivityLoading = false;

        if ([200, 201].includes(action?.payload?.status)) {
          // showSuccess(ActivityStarted);
        }
      })
      .addCase(startActivity.rejected, (state) => {
        state.isStartActivityLoading = false;
      })

      // start Activity cases
      .addCase(updateSingleActivity.pending, (state) => {
        state.isStartActivityLoading = true;
      })
      .addCase(updateSingleActivity.fulfilled, (state, action) => {
        state.isStartActivityLoading = false;

        if ([200, 201].includes(action?.payload?.status)) {
          showSuccess(ActivityUpdated);
        }
      })
      .addCase(updateSingleActivity.rejected, (state) => {
        state.isStartActivityLoading = false;
      })

      // stop Activity cases
      .addCase(stopActivity.pending, (state) => {
        state.isStopActivityLoading = true;
      })
      .addCase(stopActivity.fulfilled, (state, action) => {
        state.isStopActivityLoading = false;

        if ([200, 201].includes(action?.payload?.status)) {
          // showSuccess(ActivityStopped);
        }
      })
      .addCase(stopActivity.rejected, (state) => {
        state.isStopActivityLoading = false;
      })

      // fetch Dashboard cases
      .addCase(fetchDashboard.pending, (state) => {
        state.isDashboardLoading = true;
      })
      .addCase(fetchDashboard.fulfilled, (state, action) => {
        state.isDashboardLoading = false;
        state.dashboard = action?.payload?.data?.data ?? {};
      })
      .addCase(fetchDashboard.rejected, (state) => {
        state.isDashboardLoading = false;
        state.dashboard = state.dashboard;
      })

      // fetch single Activity cases
      .addCase(fetchSingleActivity.pending, (state) => {
        state.isSingleActivityLoading = true;
      })
      .addCase(fetchSingleActivity.fulfilled, (state, action) => {
        state.isSingleActivityLoading = false;
        state.singleActivity =
          action?.payload?.data?.data ?? state.singleActivity;
      })
      .addCase(fetchSingleActivity.rejected, (state) => {
        state.isSingleActivityLoading = false;
        state.singleActivity = state.singleActivity;
      })

      // fetch Current Activity cases
      .addCase(fetchCurrentActivity.pending, (state) => {
        state.isCurrentActivityLoading = true;
        state.currentActivities = state.currentActivities;
      })
      .addCase(fetchCurrentActivity.fulfilled, (state, action) => {
        state.currentActivities =
          action?.payload?.data?.data ?? state.currentActivities;

        if ([200, 201].includes(action?.payload?.status)) {
          if (
            !isEmpty(action?.payload?.data?.data?.clock_in_time) &&
            isEmpty(action?.payload?.data?.data?.clock_out_time)
          ) {
            setValueInLocalStorage(key.clockIn, "1");
            state.isClockIn = "1";
          } else {
            setValueInLocalStorage(key.clockIn, "");
            state.isClockIn = "";
          }
        }

        state.isCurrentActivityLoading = false;
      })
      .addCase(fetchCurrentActivity.rejected, (state) => {
        state.currentActivities = state.currentActivities;
        state.isCurrentActivityLoading = false;
      })

      .addCase(handleModal.fulfilled, (state, action) => {
        state.isModalShow = action?.payload;
      })

      .addCase(setId.fulfilled, (state, action) => {
        state.id = action?.payload;
      });
  },
});

export const dashboardSelector = (state: RootState) => state.dashboard;

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;
