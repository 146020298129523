// define here keys like local storage keys
// change fille name and remove defauluserr

const key = {
  permission: "permission",
  type: "type",
  clockIn: "hes",
  userId: "userId",
  defaultUserImage: `https://w7.pngwing.com/pngs/841/727/png-transparent-computer-icons-user-profile-synonyms-and-antonyms-android-android-computer-wallpaper-monochrome-sphere.png`,
};

export default key;
