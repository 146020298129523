import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import CustomLoader from "../../components/loader/loader";
import { AsyncPaginate } from "react-select-async-paginate";
import { projectsSelectors } from "../../redux/features/projects/projectSlice";
import { useSelector } from "react-redux";
import { fetchAsyncDropdown } from "../../helper/helper";
import { projectsApi } from "../../config/apiUrls/apiUrl";

interface AssignProjectsModalProps {
  show: boolean;
  onHide: () => void;
  selectedProjectsIds: any[];
  setSelectedProjectsIds: (projects: any[]) => void;
  submitting: boolean;
  assignEmployeeProject?: (e: React.SyntheticEvent) => Promise<void> | void;
  employeeId?: number;
}

const AssignProjectsModal: React.FC<AssignProjectsModalProps> = ({
  show,
  onHide,
  selectedProjectsIds,
  setSelectedProjectsIds,
  submitting,
  assignEmployeeProject,
  employeeId,
}) => {
  const [projectOffset, setProjectOffset] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [projectSearch, setProjectSearch] = useState("");

  useEffect(() => {
    if (show) {
      setProjectOffset("");
      setProjectList([]);
    }
  }, [show]);

  const fetchProjectsRecords = (search: any) => {
    return fetchAsyncDropdown(
      projectOffset,
      setProjectOffset,
      projectList,
      setProjectList,
      `${projectsApi.getProjects}?project_without_employee_id=${employeeId}`,
      search,
      setProjectSearch,
      false,
      false
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Projects</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Form>
            <Form.Group controlId="client" style={{ margin: "10px" }}>
              <Form.Label>Select Projects</Form.Label>
              <span className="text-danger">*</span>
              <div
                style={{
                  color: "#111111",
                  boxShadow: "none",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  borderRadius: "0px",
                }}
              >
                <AsyncPaginate
                  loadOptions={fetchProjectsRecords}
                  onChange={(e: any) => {
                    setSelectedProjectsIds(e);
                  }}
                  isMulti={true}
                  isSearchable={true}
                  value={selectedProjectsIds}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "#0c78a4",
                    },
                  })}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 d-flex justify-content-center">
            <button
              type="button"
              onClick={onHide}
              disabled={submitting}
              className="cancel-button-background me-4 btn"
            >
              Cancel
            </button>

            <button
              className="btn"
              type="submit"
              onClick={assignEmployeeProject}
              disabled={submitting}
            >
              {submitting ? "Please Wait" : "Submit"}
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AssignProjectsModal;
