import ClockInOut from "./clockInOut";

const ClockIn = () => {
  return (
    <>
      <section className="dashboard-clock">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center dashboard-clockin">
            <div className="col-md-6 text-center">
              <img
                src="../img/svg/icon-no-activity-grey.svg"
                alt="activity"
                title="activity"
                width={96}
                height={96}
                loading="eager"
                className="mb-10"
              />
              <p className="mb-20 fs-mdlg fw-600">
                Please clock-in to start activity
              </p>

              <ClockInOut />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClockIn;
