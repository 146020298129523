import { showError } from "../components/alerts/alerts";
import DeleteText from "../components/delete/deleteText";
import { getRequest } from "../config/axois/apiRequest";
import { OK } from "../config/axois/apiStatusCode";
import config from "../config/config";

const isEmpty = (data: any) => {
  if (data === undefined || data === null || data === "null") {
    return true;
  }

  if (typeof data === "object") {
    if (Object.keys(data).length === 0) {
      return true;
    }
  }

  if (typeof data === "string") {
    return [""].includes(data.trim());
  }
};

const getValueFromLocalStorage = (key: string) => {
  return localStorage.getItem(key) || "";
};

const setValueInLocalStorage = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

const clearStorage = () => {
  localStorage.clear();
};

const getAuthToken = () => {
  const token = getValueFromLocalStorage(config.LOCAL_STORAGE_TOKEN_KEY);

  return `Bearer ${token}`;
};

const fetchAsyncDropdown = async (
  offset: any,
  setOffset: any,
  dropDownList: any,
  setDropDownList: any,
  url: any,
  search: any,
  setSearch: any,
  customLabel?: any,
  isDropdownTitle?: boolean,
  dropdownTitle?: string
) => {
  // for search in AsyncDropdown
  if (search != "") {
    setSearch(search);
    setOffset("");
  }

  let limit = 10; // default limit

  let options: any = [];

  let concatUrl = `${url}&limit=${limit}&offset=${offset}&search=${
    isEmpty(search) ? "" : search
  }`;

  const response: any = await getRequest(concatUrl);

  let hasMore: boolean = false;

  if (response?.status === (200 || 201) && response?.data?.data) {
    const { meta, data } = response?.data;

    if (meta && meta.next !== null) {
      const queryParams = new URLSearchParams(meta.next);
      let newOffset: any = queryParams.get("offset");

      setOffset(newOffset);
      hasMore = true;
    }

    if (dropDownList.length === 0) {
      if (isDropdownTitle) {
        // for drop-down title - EX : All Brands ::: NOTE : if you pass isDropdownTitle = true then don't forget to pass dropdownTitle param.
        options.push({
          label: dropdownTitle, // these
          value: "",
        });
      }
    }

    if (data.length > 0) {
      data.map((option: any) => {
        options.push({
          label: customLabel
            ? option[customLabel]
            : option.name || option.full_name,
          value: option.id,
        });
      });
    }

    if (dropDownList.length > 0) {
      setDropDownList(dropDownList.concat(options));
    } else {
      setDropDownList(options);
    }
  }

  return {
    options: options,
    hasMore: hasMore,
  };
};

const errorHandler = (errors: any) => {
  let mergedMessages = "-";

  if (!isEmpty(errors)) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const errorCode = errors[key].code;
        const formateKey = key ? key.replace(/_/g, " ") : "";
        const message = errors[key].message ? errors[key].message : "";

        if (errorCode === "invalid" || errorCode === "invalid_image") {
          message &&
            showError(message.charAt(0).toUpperCase() + message.slice(1));
          break;
        }

        if (
          errorCode === "blank" ||
          errorCode === "required" ||
          errorCode === "null"
        ) {
          showError(
            `${
              formateKey.charAt(0).toUpperCase() + formateKey.slice(1)
            } can not be blank.`
          );
          break;
        }

        if (errorCode === "unique") {
          showError(
            `An account with this ${formateKey} already exists. Please choose another ${formateKey}.`
          );
          break;
        }
      }
    }
  }

  return mergedMessages;
};

const isValidNumber = (value: any) => {
  const re = /^[0-9\b]+$/;
  if (value === "" || re.test(value)) {
    if (value !== "0") {
      return true;
    }
  }

  // return false;
};

const isDeleted = (
  key: string | null | undefined,
  children: React.ReactNode
) => {
  if (isEmpty(key)) {
    return children;
  }

  return DeleteText();
};

const twoDigitsFormate = (value: any) => {
  let fixedValue = value;

  if (!isEmpty(value)) {
    let floatValue = parseFloat(value);

    fixedValue = floatValue.toFixed(2); // Specify the number of decimal places you want
  }

  return fixedValue;
};

export {
  isEmpty,
  getValueFromLocalStorage,
  setValueInLocalStorage,
  getAuthToken,
  clearStorage,
  fetchAsyncDropdown,
  errorHandler,
  isValidNumber,
  isDeleted,
  twoDigitsFormate,
};
