import React from "react";
import { isEmpty } from "../../helper/helper";

interface EmailProps {
  email: string | null;
}

const EmailComponent: React.FC<EmailProps> = ({ email }) => {
  const handleEmailClick = () => {
    if (!isEmpty(email)) {
      window.location.href = `mailto:${email}`;
    }
  };

  return (
    <span style={{ textDecoration: "underline" }} onClick={handleEmailClick}>
      {isEmpty(email) ? "-" : email}
    </span>
  );
};

export default EmailComponent;
