import Pagination from "@mui/material/Pagination";
import ShowingCount from "./ShowingCount";
import { isEmpty } from "../../helper/helper";
const pageLimit = process.env.NEXT_PUBLIC_PAGE_LIMIT;

const CustomPaginate = ({
  data,
  selected,
  pageState,
  setPageState,
  setSelected,
  setCurrentOffset,
  totalRecords,
  limit,
  setLimit,
  isDisable,
  isLimitDropDown,
  hideEntries,
}: any) => {
  const limitPerPage = limit ? limit : pageLimit;
  let count: any = Math.ceil(totalRecords / limitPerPage);

  const handleChange = (e: any, p: any) => {
    if (pageState === undefined) {
      setSelected(p - 1);
      setCurrentOffset((p - 1) * limitPerPage);
    } else {
      setPageState((preValue: any) => ({
        ...preValue,
        selected: p - 1,
        currentOffset: (p - 1) * limitPerPage,
      }));
    }
  };

  return (
    <>
      <div className="table-info py-5">
        <div
          className="row g-0"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!isEmpty(data) ? (
            <>
              <div className="col">
                {!hideEntries && (
                  <ShowingCount
                    data={data}
                    selected={selected}
                    count={totalRecords}
                    limit={limitPerPage}
                  />
                )}
              </div>
              <div
                className="col pagination justify-content-end"
                style={{ marginTop: "10px" }}
              >
                {isLimitDropDown && (
                  <select
                    value={limit}
                    onChange={(e) => {
                      setLimit(e.target.value);
                      setSelected(0);
                      setCurrentOffset(0);
                    }}
                    className="form-select"
                    style={{
                      width: 70,
                    }}
                  >
                    <option selected value={"10"}>
                      10
                    </option>
                    <option value={"25"}>25</option>
                    <option value={"50"}>50</option>
                    <option value={"100"}>100</option>
                  </select>
                )}
                <Pagination
                  className="test"
                  count={count}
                  color="standard"
                  onChange={handleChange}
                  page={selected + 1}
                  disabled={isDisable}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default CustomPaginate;
