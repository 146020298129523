import React, { useEffect, useState } from "react";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import { clearStorage, isEmpty } from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfile,
  updateUserProfile,
  userSelector,
} from "../../redux/features/auth/userSlice";
import { AppDispatch } from "../../redux/store";
import CustomLoader from "../../components/loader/loader";
import { ProfileType } from "./type";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./schema";
import key from "../../config/key";
import { Modal } from "react-bootstrap";
import { showError } from "../../components/alerts/alerts";
import LogoutModal from "../../components/logout/LogoutModal";
import { heading } from "../../redux/features/header/headerSlice";

const Profile = () => {
  const { user, loading, isProfileUpdateLoading } = useSelector(userSelector);
  const dispatch = useDispatch<AppDispatch>();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [imagePreview, setImagePreview] = useState<any>(null);
  const [show, setShow] = useState<boolean>(false);

  const logout = () => {
    setShow(false);
    clearStorage();
    window.location.href = "/";
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        setSelectedFile(file);

        // Generate image preview
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else {
        showError("Please select an image file.");
        setSelectedFile(null);
        setImagePreview(null);
      }
    } else {
      // Handle case where no file is selected
      setSelectedFile(null);
      setImagePreview(null);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ProfileType>({
    resolver: yupResolver(validationSchema) as any,
  });

  useEffect(() => {
    if (user) {
      setValue(
        "full_name",
        `${isEmpty(user.first_name) ? "" : user.first_name} ${
          isEmpty(user.last_name) ? "" : user.last_name
        }`
      );
      setValue("phone_no", isEmpty(user.phone_no) ? "" : user.phone_no);
    }
  }, [user, setValue]);

  const onSubmit = async (data: ProfileType) => {
    const { full_name, ...restData } = data;
    const [first_name, last_name] = full_name.split(" ");

    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    // formData.append("phone_no", restData.phone_no);

    if (user?.email) {
      formData.append("email", user.email);
    }

    if (user?.id) {
      formData.append("id", user.id.toString());
    }

    if (selectedFile) {
      formData.append("profile_pic", selectedFile);
    }

    const response = await dispatch(updateUserProfile(formData));

    if ([200, 201].includes(response?.payload?.status)) {
      await dispatch(getUserProfile());
    }
  };

  useEffect(() => {
    dispatch(heading("Profile"));
  }, []);

  return (
    <SideBarWrapper>
      {loading ? (
        <CustomLoader />
      ) : (
        <section className="bg-white mx-2 mb-30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <div className="profile-spacing">
                  <div className="position-relative mb-3">
                    <div className="image-container">
                      <img
                        src={imagePreview ?? user?.profile_pic}
                        alt="profile"
                        title="profile"
                        width={111}
                        height={111}
                        loading="eager"
                        className=""
                        style={{ borderRadius: "50%" }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = key.defaultUserImage;
                        }}
                      />
                      <div>
                        <div className="baneeroverlay"></div>
                        <div className="profile-drag">
                          <img
                            src="../img/svg/ic-camera.svg"
                            alt="camera"
                            title="camera"
                            width={24}
                            height={24}
                            loading="eager"
                          />
                          <div className="upload-img">
                            <label
                              htmlFor="file-upload"
                              className="custom-file-upload fs-sm text-white"
                            >
                              Select Photo
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={handleFileChange}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="name">
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="full_name"
                        className={`form-control ${
                          errors.full_name ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Full Name"
                        {...register("full_name")}
                        disabled={isProfileUpdateLoading}
                      />
                      <div className="invalid-feedback">
                        {errors.full_name?.message}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="name">
                        Phone Number
                      </label>
                      <input
                        id="phone_no"
                        type="text"
                        placeholder="-"
                        className={`form-control ${
                          errors.phone_no ? "is-invalid" : ""
                        }`}
                        {...register("phone_no")}
                        disabled={true}
                      />
                      <div className="invalid-feedback">
                        {errors.phone_no?.message}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="name">
                        Email
                      </label>
                      <input
                        id="phone_no"
                        type="text"
                        placeholder="-"
                        className={`form-control mb-4`}
                        disabled={true}
                        value={isEmpty(user?.email) ? "-" : user?.email}
                      />
                    </div>
                    <button
                      className="btn"
                      type="submit"
                      disabled={isProfileUpdateLoading}
                    >
                      {isProfileUpdateLoading ? "Please Wait" : "Update"}
                    </button>

                    {/* <hr></hr>
                  <p className="fw-600 mb-1">Reset Password</p>
                  <p className="fs-sm mb-3">
                    You can reset your password here!
                  </p>
                  <a className="btn" type="button">
                    Reset
                  </a>
                  <hr></hr> */}
                  </form>
                  {/* <button
                    disabled={isProfileUpdateLoading}
                    onClick={() => {
                      setShow(true);
                    }}
                    className="btn"
                    type="button"
                  >
                    Logout
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <LogoutModal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      />
    </SideBarWrapper>
  );
};

export default Profile;
