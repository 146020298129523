import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showError } from "../../../components/alerts/alerts";
import { adminDashboardApi } from "../../../config/apiUrls/apiUrl";
import { getRequest } from "../../../config/axois/apiRequest";
import { RootState } from "../../store";
import { isEmpty } from "../../../helper/helper";

interface ActiveUsers {
  id: number;
  full_name: string;
  total_working_duration: string;
}

interface MostUsedActivityType {
  id: number;
  name: string;
  activity_count: number;
}

interface ProjectSpentHours {
  id: number;
  name: string;
  total_hours: string;
}

interface TopEmpHours {
  id: number;
  full_name: string;
  total_duration: string;
  total_amount: string;
}

interface Totals {
  total_employees: number;
  total_projects: number;
  total_activity_types: number;
  total_vendors: number;
  total_consultants: number;
  total_contractor: number;
}

interface AdminDashboardState {
  activeUsers: ActiveUsers[];
  mostUsedActivity: MostUsedActivityType[];
  projectSpentHours: ProjectSpentHours[];
  topEmpMaxHours: TopEmpHours[];
  topEmpMinHours: TopEmpHours[];
  adminDashboardTotal: Totals | null;
  activeUsersLoading: boolean;
  mostUsedActivityLoading: boolean;
  projectSpentHoursLoading: boolean;
  topEmpMaxHoursLoading: boolean;
  topEmpMinHoursLoading: boolean;
  adminDashboardTotalLoading: boolean;
  allMaxMinHours: any;
  allMaxMinHoursLoading: boolean;
}

const initialState: AdminDashboardState = {
  activeUsers: [],
  mostUsedActivity: [],
  projectSpentHours: [],
  topEmpMaxHours: [],
  topEmpMinHours: [],
  allMaxMinHours: [],
  adminDashboardTotal: null,
  activeUsersLoading: false,
  mostUsedActivityLoading: false,
  projectSpentHoursLoading: false,
  topEmpMaxHoursLoading: false,
  topEmpMinHoursLoading: false,
  adminDashboardTotalLoading: false,
  allMaxMinHoursLoading: true,
};

export const fetchMostActiveUsers: any = createAsyncThunk(
  "adminDashboard/fetchMostActiveUsers",
  async () => {
    try {
      const response = await getRequest(
        `${adminDashboardApi.activeUsers}?with_trashed=true`,
        true
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchMostUsedActivity: any = createAsyncThunk(
  "adminDashboard/fetchMostUsedActivity",
  async () => {
    try {
      const response = await getRequest(
        `${adminDashboardApi.mostUsedActivity}`,
        true
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchProjectsSpentHours: any = createAsyncThunk(
  "adminDashboard/fetchProjectsSpentHours",
  async () => {
    try {
      const response = await getRequest(
        `${adminDashboardApi.projectSpentHours}`,
        true
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchTopEmpMaxHours: any = createAsyncThunk(
  "adminDashboard/fetchTopEmpMaxHours",
  async () => {
    try {
      const response = await getRequest(
        `${adminDashboardApi.topEmpMaxHours}`,
        true
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchTopEmpMinHours: any = createAsyncThunk(
  "adminDashboard/fetchTopEmpMinHours",
  async () => {
    try {
      const response = await getRequest(
        `${adminDashboardApi.topEmpMinHours}`,
        true
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchAllMaxMinEmployees: any = createAsyncThunk(
  "adminDashboard/fetchAllMaxMinEmployees",
  async ({ limit, currentOffset, search, ordering }: any) => {
    try {
      const response = await getRequest(
        `${
          adminDashboardApi.allEmployeesMaxMinHours
        }?limit=${limit}&offset=${currentOffset}&search=${
          isEmpty(search) ? "" : search
        }&ordering=${isEmpty(ordering) ? "" : ordering}`,
        true
      );

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

export const fetchAdminDashboardTotal: any = createAsyncThunk(
  "adminDashboard/fetchAdminDashboardTotal",
  async () => {
    try {
      const response = await getRequest(`${adminDashboardApi.totals}`, true);

      return response;
    } catch (error: any) {
      return error;
    }
  }
);

const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Most active users case
      .addCase(fetchMostActiveUsers.pending, (state) => {
        state.activeUsersLoading = true;
      })
      .addCase(fetchMostActiveUsers.fulfilled, (state, action) => {
        state.activeUsersLoading = false;
        state.activeUsers = action?.payload?.data?.data ?? [];
      })
      .addCase(fetchMostActiveUsers.rejected, (state, action) => {
        state.activeUsersLoading = false;
      })

      //Most used activity case
      .addCase(fetchMostUsedActivity.pending, (state) => {
        state.mostUsedActivityLoading = true;
      })
      .addCase(fetchMostUsedActivity.fulfilled, (state, action) => {
        state.mostUsedActivityLoading = false;
        state.mostUsedActivity = action?.payload?.data?.data ?? [];
      })
      .addCase(fetchMostUsedActivity.rejected, (state, action) => {
        state.mostUsedActivityLoading = false;
      })

      //Project spent hours case
      .addCase(fetchProjectsSpentHours.pending, (state) => {
        state.projectSpentHoursLoading = true;
      })
      .addCase(fetchProjectsSpentHours.fulfilled, (state, action) => {
        state.projectSpentHoursLoading = false;
        state.projectSpentHours = action?.payload?.data?.data ?? [];
      })
      .addCase(fetchProjectsSpentHours.rejected, (state, action) => {
        state.projectSpentHoursLoading = false;
      })

      //Top employee max hours case
      .addCase(fetchTopEmpMaxHours.pending, (state) => {
        state.topEmpMaxHoursLoading = true;
      })
      .addCase(fetchTopEmpMaxHours.fulfilled, (state, action) => {
        state.topEmpMaxHoursLoading = false;
        state.topEmpMaxHours = action?.payload?.data?.data ?? [];
      })
      .addCase(fetchTopEmpMaxHours.rejected, (state, action) => {
        state.topEmpMaxHoursLoading = false;
      })

      //Top employee min hours case
      .addCase(fetchTopEmpMinHours.pending, (state) => {
        state.topEmpMinHoursLoading = true;
      })
      .addCase(fetchTopEmpMinHours.fulfilled, (state, action) => {
        state.topEmpMinHoursLoading = false;
        state.topEmpMinHours = action?.payload?.data?.data ?? [];
      })
      .addCase(fetchTopEmpMinHours.rejected, (state, action) => {
        state.topEmpMinHoursLoading = false;
      })

      //Admin dashboard total case
      .addCase(fetchAdminDashboardTotal.pending, (state) => {
        state.adminDashboardTotalLoading = true;
      })
      .addCase(fetchAdminDashboardTotal.fulfilled, (state, action) => {
        state.adminDashboardTotalLoading = false;
        state.adminDashboardTotal = action?.payload?.data?.data ?? {};
      })
      .addCase(fetchAdminDashboardTotal.rejected, (state, action) => {
        state.adminDashboardTotalLoading = false;
      })

      // all max min hours
      .addCase(fetchAllMaxMinEmployees.pending, (state) => {
        state.allMaxMinHoursLoading = true;
      })
      .addCase(fetchAllMaxMinEmployees.fulfilled, (state, action) => {
        state.allMaxMinHoursLoading = false;
        state.allMaxMinHours = action?.payload?.data?.data ?? {};
      })
      .addCase(fetchAllMaxMinEmployees.rejected, (state, action) => {
        state.allMaxMinHoursLoading = false;
      });
  },
});

// Selectors
export const adminDashboardSelector = (state: RootState) =>
  state.adminDashboard;

const adminDashboardReducer = adminDashboardSlice.reducer;

export default adminDashboardReducer;
