import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./features/auth/loginSlice";
import permissionReducer from "./features/permissions/fetchPermissions";
import clientReducer from "./features/clients/clientSlice";
import projectReducer from "./features/projects/projectSlice";
import activityReducer from "./features/activity/activitySlice";
import dashboardReducer from "./features/dashboard/dashboardSlice";
import vendorReducer from "./features/vendor/vendorSlice";
import employeeReducer from "./features/employee/employeeSlice";
import summaryReducer from "./features/summary/summarySlice";
import userReducer from "./features/auth/userSlice";
import adminDashboardReducer from "./features/adminDashboard/adminDashboardSlice";
import deleteModalReducer from "./features/deleteModal/deleteModalSlice";
import headerReducer from "./features/header/headerSlice";
import consultantsReducer from "./features/consultant/consultantSlice";
import contractorsReducer from "./features/contractor/contractorSlice";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    login: loginReducer,
    permission: permissionReducer,
    clients: clientReducer,
    projects: projectReducer,
    activities: activityReducer,
    vendors: vendorReducer,
    consultants: consultantsReducer,
    contractors: contractorsReducer,
    employees: employeeReducer,
    summary: summaryReducer,
    users: userReducer,
    adminDashboard: adminDashboardReducer,
    deleteModal: deleteModalReducer,
    header: headerReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
