import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import {
  dashboardSelector,
  fetchCurrentActivity,
  fetchDashboard,
  handleModal,
  setId,
  startActivity,
  stopActivity,
} from "../../redux/features/dashboard/dashboardSlice";
import moment from "moment";
import CustomLoader from "../../components/loader/loader";
import NoActivity from "./noActivity";
import { ActivitiesType } from "./dashboardType";
import { isEmpty } from "../../helper/helper";
import { ActivityStop, ActivityType } from "./type";
import { OK } from "../../config/axois/apiStatusCode";
import config from "../../config/config";
import ClockIn from "./clockIn";
import DeleteIcon from "../../components/delete/deleteIcon";
import { employeesApi } from "../../config/apiUrls/apiUrl";
import { deleteModalSelector } from "../../redux/features/deleteModal/deleteModalSlice";

const Activity = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    dashboard: { activities, total_duration },
    isDashboardLoading,
    isCurrentActivityLoading,
    currentActivities: { current_activity },
    isStartActivityLoading,
    isStopActivityLoading,
    isClockIn,
  } = useSelector(dashboardSelector);

  const { reCallApi } = useSelector(deleteModalSelector);

  useEffect(() => {
    dispatch(fetchDashboard());
  }, []);

  useEffect(() => {
    if (reCallApi) dispatch(fetchDashboard());
  }, [reCallApi]);

  const startStopActivity = async (
    isCurrentActivity: boolean,
    item: ActivitiesType
  ) => {
    if (isCurrentActivity) {
      // stop activity logic
      const body: ActivityStop = {
        latitude: "-",
        longitude: "-",
        end_date_time: moment().format(config.API_DATE_TIME_FORMATE),
        id: current_activity?.id ?? "",
      };

      const response = await dispatch(stopActivity(body));

      if ([200, 201].includes(response?.payload?.status)) {
        dispatch(fetchCurrentActivity());
      }
      return;
    }

    // start activity logic
    const body: ActivityType = {
      latitude: "-",
      longitude: "-",
      project_id: item.project_id,
      activity_type_id: item.activity_type_id,
      start_date_time: moment().format(config.API_DATE_TIME_FORMATE),
      end_date_time: null,
      description: item.description,
    };

    const response = await dispatch(startActivity(body));

    if ([200, 201].includes(response?.payload?.status)) {
      await dispatch(fetchCurrentActivity());
      dispatch(fetchDashboard());
    }
  };

  const openModal = (id: string, isCurrentActivity: boolean) => {
    if (!isCurrentActivity) {
      dispatch(handleModal(true));
      dispatch(setId(id));
    }
  };

  return (
    <>
      {isDashboardLoading || isCurrentActivityLoading ? (
        <CustomLoader />
      ) : !activities || activities.length === 0 ? (
        isEmpty(isClockIn) ? (
          <ClockIn />
        ) : (
          <NoActivity />
        )
      ) : (
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="mb-30">
                <div className="d-flex flex-wrap align-items-center justify-content-between py-13">
                  <p className="fs-md">{moment().format("MMMM DD, YYYY")}</p>
                  <p className="fs-md">
                    Total Duration :{" "}
                    {isEmpty(total_duration) ? "-" : total_duration}{" "}
                  </p>
                </div>

                <div className="table-responsive dashboard-table">
                  <table className="table table-hover table-bordered table-row-gray-100 align-baseline gs-0 gy-2">
                    <thead>
                      <tr className="text-muted fw-500">
                        <th>Project</th>
                        <th>Activity Type</th>
                        <th>Description</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Total</th>
                        {!isEmpty(isClockIn) && <th>Actions</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {activities &&
                        activities.length > 0 &&
                        activities.map((item: ActivitiesType) => {
                          const isCurrentActivity =
                            current_activity?.id === item.id;
                          return (
                            <tr>
                              <td
                                onClick={() =>
                                  openModal(item.id, isCurrentActivity)
                                }
                                role={!isCurrentActivity ? "button" : ""}
                                className={isCurrentActivity ? "fw-bold" : ""}
                              >
                                {isEmpty(item.project_name)
                                  ? "-"
                                  : item.project_name}
                              </td>
                              <td
                                onClick={() =>
                                  openModal(item.id, isCurrentActivity)
                                }
                                role={!isCurrentActivity ? "button" : ""}
                                className={isCurrentActivity ? "fw-bold" : ""}
                              >
                                {isEmpty(item.activity_type)
                                  ? "-"
                                  : item.activity_type}
                              </td>
                              <td
                                onClick={() =>
                                  openModal(item.id, isCurrentActivity)
                                }
                                role={!isCurrentActivity ? "button" : ""}
                                className={isCurrentActivity ? "fw-bold" : ""}
                              >
                                {isEmpty(item.description)
                                  ? "-"
                                  : item.description}
                              </td>
                              <td
                                onClick={() =>
                                  openModal(item.id, isCurrentActivity)
                                }
                                role={!isCurrentActivity ? "button" : ""}
                                className={
                                  isCurrentActivity
                                    ? "fw-bold text-center"
                                    : "text-center"
                                }
                              >
                                {isEmpty(item.start_date_time)
                                  ? "-"
                                  : moment(item.start_date_time).format("LT")}
                              </td>
                              <td
                                onClick={() =>
                                  openModal(item.id, isCurrentActivity)
                                }
                                role={!isCurrentActivity ? "button" : ""}
                                className={
                                  isCurrentActivity
                                    ? "fw-bold text-center"
                                    : "text-center"
                                }
                              >
                                {isEmpty(item.end_date_time)
                                  ? "-"
                                  : moment(item.end_date_time).format("LT")}
                              </td>
                              <td
                                onClick={() =>
                                  openModal(item.id, isCurrentActivity)
                                }
                                role={!isCurrentActivity ? "button" : ""}
                                className={
                                  isCurrentActivity
                                    ? "fw-bold text-center"
                                    : "text-center"
                                }
                              >
                                {isEmpty(item.total_duration)
                                  ? "-"
                                  : item.total_duration}
                              </td>
                              {!isEmpty(isClockIn) && (
                                <td>
                                  <div className="text-center">
                                    <img
                                      onClick={() => {
                                        let isLoading =
                                          isStartActivityLoading ||
                                          isStopActivityLoading ||
                                          isCurrentActivityLoading;

                                        !isLoading &&
                                          startStopActivity(
                                            isCurrentActivity,
                                            item
                                          );
                                      }}
                                      role="button"
                                      src={`../img/svg/${
                                        isCurrentActivity
                                          ? "icon-pause-filled-red.svg"
                                          : "Play-filled.svg"
                                      }`}
                                      alt="play"
                                      title={
                                        isCurrentActivity
                                          ? "Stop Activity"
                                          : "Start Activity"
                                      }
                                      width={24}
                                      height={24}
                                      loading="eager"
                                      className=""
                                    />

                                    {!isCurrentActivity && (
                                      <DeleteIcon
                                        url={employeesApi.deleteEmployee.replace(
                                          ":id",
                                          item.id
                                        )}
                                      />
                                    )}
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Activity;
