import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { isDeleted, isEmpty } from "../../helper/helper";
import { useForm } from "react-hook-form";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import { AppDispatch } from "../../redux/store";
import CustomLoader from "../../components/loader/loader";
import CustomPaginate from "../../components/pagination/CustomPaginate";
import {
  Activity,
  activitiesSelectors,
  createActivity,
  fetchActivities,
  getActivityById,
  updateActivity,
} from "../../redux/features/activity/activitySlice";
import config from "../../config/config";
import { ActivityType } from "./type";
import { validationSchema } from "./schema";
import NoRecordFound from "../../components/no-record-found/NoRecordFound";
import { activityNotFound } from "../../messages/NotFoundMessages";
import { OK } from "../../config/axois/apiStatusCode";
import useDebounce from "../../components/hooks/useDebounce";
import SortingIndicator from "../../components/sorting/SortingIndicator";
import { deleteModalSelector } from "../../redux/features/deleteModal/deleteModalSlice";
import DeleteIcon from "../../components/delete/deleteIcon";
import { heading } from "../../redux/features/header/headerSlice";
import { loginSelector } from "../../redux/features/auth/loginSlice";

const ActivityPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { reCallApi } = useSelector(deleteModalSelector);

  const activities = useSelector(activitiesSelectors.activities);
  const details = useSelector(activitiesSelectors.details);
  const loading = useSelector(activitiesSelectors.loading);
  const detailsLoading = useSelector(activitiesSelectors.detailsLoading);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [show, setShowModal] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState<any>(null);
  const [selected, setSelected] = useState<number>(0);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [user, setUser] = useState<string>("");
  const [limit, setLimit] = useState<string>(config.LIMIT_PER_PAGE);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");
  const [ordering, setOrdering] = useState<string>("name");
  const searchDebounce = useDebounce(search, 500);
  const { type } = useSelector(loginSelector);

  const isAdmin = type === "admin";

  const openModal = (clientId: any) => {
    setShowModal(true);
    setSelectedActivityId(clientId);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedActivityId(null);
    reset();
  };

  const handleSearch = (e: React.SyntheticEvent) => {
    setCurrentOffset(0);
    setSelected(0);
    setSearch((e.target as HTMLInputElement).value);
  };

  const handleSort = (value: string) => {
    setOrdering(ordering === value ? `-${value}` : value);
    setCurrentOffset(0);
    setSelected(0);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ActivityType>({
    resolver: yupResolver(validationSchema) as any,
  });

  const onSubmit = async (data: ActivityType) => {
    const newActivity: Omit<Activity, "id"> = {
      name: data.name,
      is_location_required: data.is_location_required ? true : false,
      is_active: data.is_active ? true : false,
    };

    if (isEmpty(selectedActivityId)) {
      setSubmitting(true);

      const response = await dispatch(createActivity(newActivity));

      if (response?.payload?.status === 201) {
        await fetchActivityRecords();
        closeModal();
      }

      setSubmitting(false);
    } else {
      setSubmitting(true);

      const response = await dispatch(
        updateActivity({
          id: selectedActivityId,
          ...newActivity,
        })
      );

      if ([200, 201].includes(response?.payload?.status)) {
        await fetchActivityRecords();
        closeModal();
      }

      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchActivityRecords();
  }, [currentOffset, user, selected, searchDebounce, ordering]);

  useEffect(() => {
    if (reCallApi) fetchActivityRecords();
  }, [reCallApi]);

  useEffect(() => {
    dispatch(heading("Activity Types"));
  }, []);

  const fetchActivityRecords = async () => {
    const response = await dispatch(
      fetchActivities({
        currentOffset,
        limit,
        is_active: user,
        search,
        ordering,
        isDeleted: true,
      })
    );
    setCount(response?.payload?.data?.meta?.count ?? 0);
  };

  useEffect(() => {
    if (!isEmpty(selectedActivityId)) {
      dispatch(getActivityById(selectedActivityId));
    }
  }, [selectedActivityId]);

  useEffect(() => {
    if (!isEmpty(selectedActivityId) && details) {
      setValue("name", details?.name ?? "");
      setValue("is_location_required", details?.is_location_required ?? "");
      setValue("is_active", details?.is_active ?? "");
    } else {
      setValue("is_active", false);
      setValue("is_location_required", false);
    }
  }, [details]);

  return (
    <SideBarWrapper>
      <div className="card" style={{ marginBottom: "-40px" }}>
        <div className="card-header border-0 py-2 px-7 align-items-center">
          <div className="d-flex flex-wrap">
            <div className="mb-res" style={{ marginTop: "15px" }}>
              <input
                autoFocus
                name="text"
                type="text"
                autoComplete="text"
                placeholder="Search"
                value={search}
                onChange={(e) => handleSearch(e)}
                className="form-control form-search"
                disabled={loading}
              />
            </div>
            <div
              className="ms-md-auto"
              style={{
                float: "right",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div className="card-toolbar d-flex my-0 mt-1 mb-1">
                <select
                  className="form-select me-3 border"
                  style={{
                    width: 160,
                    height: 38,
                  }}
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                >
                  <option value="">All Activities</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>

                <button
                  type="button"
                  className="btn"
                  onClick={() => openModal(null)}
                  disabled={loading}
                >
                  Add Activity
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <CustomLoader />
        ) : (
          <>
            {isEmpty(activities) ? (
              <NoRecordFound message={activityNotFound} />
            ) : (
              <div className="card-body pt-0 pb-3">
                <div className="table-responsive">
                  <table className="table table-hover table-row-bordered table-row-gray-100 align-baseline gs-0 gy-2 mt-3">
                    <thead>
                      <tr className="text-muted fw-500">
                        <SortingIndicator
                          field="Name"
                          onClick={handleSort}
                          column="name"
                          sortingColumn={ordering}
                        />
                        <SortingIndicator
                          field="Location Is Required"
                          onClick={handleSort}
                          column="is_location_required"
                          sortingColumn={ordering}
                        />
                        <th className="w-10px" style={{ textAlign: "right" }}>
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activities &&
                        activities.map((activity) => (
                          <tr key={activity.id}>
                            <td>
                              {isEmpty(activity.name) ? "-" : activity.name}
                            </td>

                            <td>
                              {isEmpty(activity.is_location_required)
                                ? "-"
                                : activity.is_location_required === true
                                ? "Yes"
                                : "No"}
                            </td>

                            <td
                              className="w-10px"
                              style={{ textAlign: "right" }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "right",
                                }}
                              >
                                {isDeleted(
                                  activity.deleted_at,
                                  <>
                                    <div
                                      style={{ paddingRight: "10px" }}
                                      onClick={() => openModal(activity.id)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-pencil-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                                      </svg>
                                    </div>

                                    {
                                      isAdmin &&
                                      <div>
                                        <DeleteIcon
                                          url={`/employees/activity-types/delete/${activity.id}`}
                                          tag="activity"
                                        />
                                      </div>
                                    }
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {!isEmpty(activities) && !loading && (
        <CustomPaginate
          data={activities}
          selected={selected}
          setSelected={setSelected}
          setCurrentOffset={setCurrentOffset}
          totalRecords={count}
          limit={limit}
          setLimit={setLimit}
          isDisable={loading}
        />
      )}

      <Modal
        show={show}
        onHide={() => {
          closeModal();
        }}
        backdrop="static"
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEmpty(selectedActivityId) ? "Add" : "Update"} Activity
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {detailsLoading ? (
              <CustomLoader />
            ) : (
              <>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <span className="text-danger">*</span>
                  <Form.Control
                    disabled={submitting}
                    type="text"
                    {...register("name")}
                    isInvalid={!!errors.name?.message}
                    placeholder="Enter Name"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name?.message ?? ""}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="is_active">
                  <Form.Check
                    style={{
                      marginTop: "20px",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "22px",
                      color: "#6b6b6b",
                    }}
                    type="checkbox"
                    label={"Is Active?"}
                    defaultChecked={true}
                    {...register("is_active")}
                  />
                </Form.Group>

                <Form.Group controlId="is_location_required">
                  <Form.Check
                    style={{
                      marginTop: "10px",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "22px",
                      color: "#6b6b6b",
                    }}
                    type="checkbox"
                    label="Location Required"
                    defaultChecked={false}
                    {...register("is_location_required")}
                  />
                </Form.Group>
              </>
            )}
          </Modal.Body>
          {!detailsLoading && (
            <Modal.Footer>
              <div className="w-100 d-flex justify-content-center">
                <button
                  type="button"
                  onClick={() => closeModal()}
                  disabled={submitting}
                  className="cancel-button-background me-4 btn"
                >
                  Cancel
                </button>

                <button className="btn" type="submit" disabled={submitting}>
                  {submitting
                    ? "Please Wait"
                    : isEmpty(selectedActivityId)
                    ? "Submit"
                    : "Update"}
                </button>
              </div>
            </Modal.Footer>
          )}
        </Form>
      </Modal>
    </SideBarWrapper>
  );
};

export default ActivityPage;
