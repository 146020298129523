import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomLoader from "../../components/loader/loader";
import SideBarWrapper from "../../components/sidebarWrapper/sideBarWrapper";
import { isEmpty } from "../../helper/helper";
import {
  getClientById,
  selectDetails,
  selectDetailsLoading,
} from "../../redux/features/clients/clientSlice";
import { AppDispatch } from "../../redux/store";
import ProjectsAdmin from "../project-admin/project-admin";
import { heading } from "../../redux/features/header/headerSlice";

const ClientDetails = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const details = useSelector(selectDetails);
  const detailsLoading = useSelector(selectDetailsLoading);

  const { clientId } = location.state;

  useEffect(() => {
    if (!isEmpty(clientId)) {
      dispatch(getClientById(clientId));
    }
  }, [clientId]);

  useEffect(() => {
    dispatch(heading("Client Details"));
  }, []);

  return (
    <>
      <SideBarWrapper>
        {detailsLoading ? (
          <CustomLoader />
        ) : (
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  {/* Project Information */}
                  <div className="mb-30">
                    <div className="total-time-tracking">
                      <p className="fs-md fw-bold">Client Details</p>
                    </div>
                    <div className="row mx-0 flex-wrap fs-md bg-white time-tracking align-items-start">
                      <div className="col-md-2 pt-2 fw-500 ps-2">
                        <p>Client Name</p>
                      </div>
                      <div className="col-md-10 pt-2 ps-2">
                        {isEmpty(details?.name) ? "-" : details?.name}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2">
                        <p>Phone No.</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.phone_no) ? "-" : details?.phone_no}
                      </div>
                      <div className="col-md-2 pt-3 fw-500 ps-2 pb-2">
                        <p>Address</p>
                      </div>
                      <div className="col-md-10 pt-3 ps-2">
                        {" "}
                        {isEmpty(details?.address) ? "-" : details?.address}
                      </div>
                    </div>
                  </div>
                  {/* vendor list */}

                  <ProjectsAdmin isDetailsPage={true} />
                </div>
              </div>
            </div>
          </section>
        )}
      </SideBarWrapper>
    </>
  );
};

export default ClientDetails;
