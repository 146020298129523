import React from "react";
import { isEmpty } from "../../helper/helper";

interface PhoneProps {
  phone: string | null;
}

const PhoneComponent: React.FC<PhoneProps> = ({ phone }) => {
  const handlePhoneClick = () => {
    if (!isEmpty(phone)) {
      window.location.href = `tel:${phone}`;
    }
  };

  return (
    <span style={{ textDecoration: "underline" }} onClick={handlePhoneClick}>
      {isEmpty(phone) ? "-" : phone}
    </span>
  );
};

export default PhoneComponent;
