import React from "react";

const CustomLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
      className="py-2"
    >
      <img src="/Loader.gif" alt="loader.." width={120} />
    </div>
  );
};

export default CustomLoader;
